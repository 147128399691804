


.right-login.animat-rate .bg_circle>div,
.rightchat.animat-rate .bg_circle>div {
    width: 20px;
    height: 20px;
    border: 3px solid #d0eafb;
    border-radius: 50%;
    position: absolute;
    z-index: 9
}


.right-login.animat-rate .bg_circle div:first-of-type,
.rightchat.animat-rate .bg_circle div:first-of-type {
    right: 50%;
    animation: animationFramesOne 25s linear infinite
}

.right-login.animat-rate .bg_circle div:nth-of-type(2),
.rightchat.animat-rate .bg_circle div:nth-of-type(2) {
    right: 5%;
    top: 5%;
    animation: animationFramesOne 35s linear infinite
}
.right-login.animat-rate .bg_circle div:nth-of-type(3),
.rightchat.animat-rate .bg_circle div:nth-of-type(3) {
    bottom: 35%;
    left: 20%;
    animation: animationFramesOne 65s linear infinite
}


.right-login.animat-rate .bg_circle div:nth-of-type(4),
.rightchat.animat-rate .bg_circle div:nth-of-type(4) {
    bottom: 26%;
    right: 35%;
    animation: animationFramesOne 100s linear infinite
}

.right-login.animat-rate .bg_circle div:nth-of-type(5),
.rightchat.animat-rate .bg_circle div:nth-of-type(5) {
    bottom: 35%;
    right: 5%;
    animation: animationFramesOne 45s linear infinite
}


.right-login.animat-rate .bg_circle div:nth-of-type(6),
.rightchat.animat-rate .bg_circle div:nth-of-type(6) {
    bottom: 60%;
    left: 10%;
    animation: animationFramesOne 90s linear infinite
}

.right-login.animat-rate .bg_circle div:nth-of-type(7),
.rightchat.animat-rate .bg_circle div:nth-of-type(7) {
    top: 40%;
    right: 40%;
    animation: animationFramesOne 25s linear infinite
}

.right-login.animat-rate .bg_circle div:nth-of-type(8),
.rightchat.animat-rate .bg_circle div:nth-of-type(8) {
    bottom: 30%;
    left: 3%;
    animation: animationFramesOne 80s linear infinite
}

.right-login.animat-rate .bg_circle div:nth-of-type(9),
.rightchat.animat-rate .bg_circle div:nth-of-type(9) {
    top: 25%;
    right: 35%;
    animation: animationFramesOne 35s linear infinite
}

.right-login.animat-rate .bg_circle div:nth-of-type(10),
.rightchat.animat-rate .bg_circle div:nth-of-type(10) {
    top: 40%;
    right: 25%;
    animation: animationFramesOne 55s linear infinite
}


.right-login.animat-rate .bg_circle div:nth-of-type(11),
.rightchat.animat-rate .bg_circle div:nth-of-type(11) {
    bottom: 20%;
    right: 14%;
    animation: animationFramesOne 10s linear infinite
}


.right-login.animat-rate .cross,
.rightchat.animat-rate .cross {
    position: absolute;
    background: #d0eafb;
    height: 15px;
    width: 2px;
    top: 175px;
    right: 50px;
    animation: rotatedTwo 22s linear infinite
}

.right-login.animat-rate .cross:after,
.rightchat.animat-rate .cross:after {
    position: absolute;
    background: #d0eafb;
    content: "";
    height: 2px;
    left: -6px;
    top: 7px;
    width: 15px;
    animation: rotated 25s linear infinite
}


.right-login.animat-rate .cross1,
.rightchat.animat-rate .cross1 {
    position: absolute;
    background: #d0eafb;
    bottom: 10%;
    height: 15px;
    left: 50%;
    width: 2px;
    animation: rotatedTwo 50s linear infinite
}

.right-login.animat-rate .cross1:after,
.rightchat.animat-rate .cross1:after {
    position: absolute;
    background: #d0eafb;
    content: "";
    height: 2px;
    left: -6px;
    top: 7px;
    width: 15px;
    animation: rotated 50s linear infinite
}


.right-login.animat-rate .cross2,
.rightchat.animat-rate .cross2 {
    position: absolute;
    background: #d0eafb;
    top: 65%;
    height: 15px;
    left: 200px;
    width: 2px;
    animation: rotatedTwo 50s linear infinite
}


.right-login.animat-rate .cross2:after,
.rightchat.animat-rate .cross2:after {
    position: absolute;
    background: #d0eafb;
    content: "";
    height: 2px;
    left: -6px;
    top: 7px;
    width: 15px;
    animation: rotated 50s linear infinite
}

.right-login.animat-rate .dot,
.rightchat.animat-rate .dot {
    position: absolute;
    height: 5px;
    width: 5px;
    background-color: #d0eafb;
    border-radius: 50%;
    display: inline-block;
    animation: animationFramesOne 50s linear infinite;
    top: 10%
}


.right-login.animat-rate .dot1,
.rightchat.animat-rate .dot1 {
    left: 15%;
    position: absolute;
    height: 5px;
    width: 5px;
    background-color: #d0eafb;
    animation: animationFramesOne 90s linear infinite;
    border-radius: 50%
}

.dark {
    background-color: #191b1f
}

.dark,
.dark .clfooter #basicUsage3 {
    color: hsla(0, 0%, 100%, .8)
}

.dark .rightchat.animat-rate .bg_circle>div {
    border: 3px solid #42434a
}

.dark .rightchat.animat-rate .cross,
.dark .rightchat.animat-rate .cross1,
.dark .rightchat.animat-rate .cross1:after,
.dark .rightchat.animat-rate .cross2,
.dark .rightchat.animat-rate .cross2:after,
.dark .rightchat.animat-rate .cross:after {
    background: #42434a
}

.dark .rightchat.animat-rate .dot1 {
    background-color: #42434a
}



.dark .login-page1 .left-page {
    background-color: #191b1f
}

.dark .login-page1 .left-page h3,
.dark .login-page1 .left-page h4 {
    color: #fff
}

.dark .login-content {
    background-color: #191b1f;
    color: #fff
}

.dark .login-content .form1 .form-group .col-form-label,
.dark .login-content h3,
.dark .login-content h4 {
    color: #fff
}

.dark .login-content .form1 .form-group .form-control {
    background-color: #25262c;
    border: 1px solid #414248
}

.dark .login-content .form1 .form-group .form-control::placeholder {
    color: #fff
}

.dark .login-content .form1 .form-group .form-check h6,
.dark .login-content .form2 .form-group .col-form-label {
    color: #fff
}

.dark .login-content .form2 .form-group .form-control {
    background-color: #25262c;
    border: 1px solid #414248
}

.dark .login-content .form2 .form-group .form-control::placeholder {
    color: #fff
}

.dark .login-content .form2 .form-group .form-check h6 {
    color: #fff
}

.dark .login-content .login-content-header img {
    z-index: 140
}

.dark .login-content .line h6 {
    color: #fff
}

.dark .right-login {
    background-color: #25262c
}

.dark .right-login.animat-rate .bg_circle>div {
    border: 3px solid #42434a
}

.dark .right-login.animat-rate .cross,
.dark .right-login.animat-rate .cross1,
.dark .right-login.animat-rate .cross1:after,
.dark .right-login.animat-rate .cross2,
.dark .right-login.animat-rate .cross2:after,
.dark .right-login.animat-rate .cross:after {
    background: #42434a
}

.dark .right-login.animat-rate .dot,
.dark .right-login.animat-rate .dot1 {
    background-color: #42434a
}

.dark .right-login .maincircle {
    background-image: linear-gradient(#191d1f, rgba(25, 29, 31, .702), rgba(25, 29, 31, .702))
}

.dark .right-login .bottom-circle,
.dark .right-login .bottom-circle1,
.dark .right-login .center-circle,
.dark .right-login .right-circle,
.dark .right-login .right-circle1,
.dark .right-login .top-circle {
    background-image: linear-gradient(#191d1f, rgba(25, 29, 31, .451), rgba(25, 29, 31, .149))
}












.login-content {
    width: 450px;
    margin: 0 auto
}

.login-content .login-content-header img {
    height: 50px;
    width: auto
}

.login-content h3 {
    font-size: calc(18px + 8*(100vw - 320px)/1600);
    font-family: Rubik, sans-serif;
    font-weight: 500;
    padding-bottom: 16px
}

.login-content h4 {
    margin-bottom: 35px;
    line-height: 1.4;
    font-family: Roboto, sans-serif;
    font-weight: 400
}

.login-content .form1 .form-group,
.login-content .form2 .form-group {
    margin-bottom: 30px
}

.login-content .form1 .form-group .col-form-label,
.login-content .form2 .form-group .col-form-label {
    font-size: calc(14px + 2*(100vw - 320px)/1600);
    color: #223645;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 15px;
    line-height: 1
}

.login-content .form1 .form-group .buttons,
.login-content .form2 .form-group .buttons {
    text-align: center
}

.login-content .form1 .form-group .buttons .btn,
.login-content .form2 .form-group .buttons .btn {
    padding: 14px 65px;
    color: #fff;
    justify-content: center
}

.login-content .form1 .form-group .buttons .btn-signup,
.login-content .form2 .form-group .buttons .btn-signup {
    background-color: #223645;
    margin-left: 25px
}

.login-content .form1 .form-group .form-check,
.login-content .form2 .form-group .form-check {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.login-content .form1 .form-group .form-check h6,
.login-content .form2 .form-group .form-check h6 {
    color: #223645;
    font-family: Montserrat, sans-serif;
    font-weight: 500
}

.login-content .form1 .form-group .form-check-input,
.login-content .form2 .form-group .form-check-input {
    margin-right: -1.25rem;
    margin-left: unset
}

.login-content .form1 .form-group .form-control,
.login-content .form2 .form-group .form-control {
    padding: 13px 20px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    height: auto
}

.login-content .form1 .form-group .form-control::placeholder,
.login-content .form2 .form-group .form-control::placeholder {
    font-size: 15px;
    color: #223645;
    font-family: Roboto, sans-serif;
    font-weight: 400
}

.login-content .form1 .form-group .form-check-label,
.login-content .form2 .form-group .form-check-label {
    font-size: 12px;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1
}

.login-content .form2 .form-group .form-check-input {
    margin-right: 0;
    margin-left: -1.25rem
}

.login-content .line {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 50px
}

.login-content .line h6 {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    color: #223645
}

.login-content .line h6:after,
.login-content .line h6:before {
    display: inline-block;
    content: "";
    border-top: 1px solid #ededed;
    width: 4.5rem;
    margin: 0 1rem
}

.login-content .medialogo {
    margin-bottom: 30px
}

.login-content .medialogo ul {
    display: flex;
    justify-content: center
}

.login-content .medialogo ul li+li {
    margin-left: 20px
}

.login-content .medialogo .btn-facebook {
    background-color: #2c67ce;
    color: #fff
}

.login-content .termscondition {
    text-align: center
}

.login-content .termscondition h4 span {
    color: #ff4e2b;
    margin-right: 8px
}

.login-content .termscondition h4 b {
    margin-left: 8px;
    margin-right: 8px
}

.right-login {
    justify-content: center;
    display: flex;
    max-height: 100%
}


.right-login {
    background-color: #eff7fe;
    width: auto;
    height: 100%;
    position: relative
}


.right-login .login-img {
    position: absolute;
    top: 30%
}


.right-login .boy-logo {
    position: absolute;
    left: 36%;
    top: 52%
}

.right-login .girl-logo {
    top: 27%;
    right: 27%;
    position: absolute
}

.login-page2 .heart-logo,
.right-login .heart-logo {
    position: absolute;
    animation: heartbeat 5s infinite;
    top: 15%;
    right: 35%
}


.right-login .has-logo {
    position: absolute;
    top: 15%;
    right: 27%
}


.right-login .has-logo1 {
    position: absolute;
    top: 60%;
    left: 25%;
    transform: scalex(-1)
}


.right-login .cloud-logo {
    position: absolute;
    top: 15%;
    left: 0;
    animation: homecloud 90s linear infinite;
    transform: scale(.15)
}


.right-login .cloud-logo1 {
    position: absolute;
    bottom: 10%;
    animation: animateCloud 25s linear infinite;
    height: 25px
}


.right-login .cloud-logo2 {
    position: absolute;
    top: 2%;
    animation: homecloud 60s linear infinite;
    transform: scale(.5)
}


.right-login .cloud-logo3 {
    position: absolute;
    top: 31%;
    animation: animateCloud 45s linear infinite;
    transform: scaleX(-1);
    height: 30px
}


.right-login .cloud-logo4 {
    position: absolute;
    animation: animateCloud 80s linear infinite;
    transform: scale(.55)
}


.right-login .maincircle {
    background-image: linear-gradient(#cde7fa, #f1f8fe, #f1f8fe);
    padding: 1px;
    width: calc(100px + 580*(100vw - 320px)/1600);
    height: calc(100px + 580*(100vw - 320px)/1600);
    border-color: transparent;
    border-radius: 50%;
    border-width: 1px;
    top: 15%;
    position: absolute
}


.right-login .top-circle {
    background-image: linear-gradient(#f1f8fe, #f1f8fe, #cde7fa);
    top: 2%;
    left: 20%
}


.right-login .center-circle,
.right-login .top-circle {
    padding: 1px;
    height: calc(50px + 50*(100vw - 320px)/1600);
    width: calc(50px + 50*(100vw - 320px)/1600);
    border-color: transparent;
    border-radius: 50%;
    border-width: 1px;
    position: absolute
}

.right-login .center-circle {
    background-image: linear-gradient(90deg, #cde7fa, #f1f8fe, #f1f8fe);
    top: 45%;
    left: 6%
}


.right-login .bottom-circle {
    width: calc(50px + 50*(100vw - 320px)/1600);
    height: calc(50px + 50*(100vw - 320px)/1600);
    bottom: 0;
    left: 13%
}

.right-login .bottom-circle,
.right-login .bottom-circle1 {
    background-image: linear-gradient(#cde7fa, #f1f8fe, #f1f8fe);
    padding: 1px;
    border-color: transparent;
    border-radius: 50%;
    border-width: 1px;
    position: absolute
}

.right-login .bottom-circle1 {
    width: calc(40px + 80*(100vw - 320px)/1600);
    height: calc(40px + 80*(100vw - 320px)/1600);
    bottom: 2%;
    right: 22%
}


.right-login .right-circle {
    background-image: linear-gradient(270deg, #f1f8fe, #f1f8fe, #cde7fa);
    padding: 1px;
    width: calc(90px + 170*(100vw - 320px)/1600);
    height: calc(90px + 170*(100vw - 320px)/1600);
    border-color: transparent;
    border-radius: 50%;
    border-width: 1px;
    bottom: 18%;
    right: 0;
    position: absolute
}


.right-login .right-circle1 {
    background-image: linear-gradient(90deg, #cde7fa, #f1f8fe, #f1f8fe, #f1f8fe);
    padding: 1px;
    width: calc(75px + 65*(100vw - 320px)/1600);
    height: calc(75px + 65*(100vw - 320px)/1600);
    border-color: transparent;
    border-radius: 50%;
    border-width: 1px;
    top: 1%;
    right: 9%;
    position: absolute
}

.login-page1 .login-content {
    width: 100%
}

.login-page1 .login-content h3 {
    padding-bottom: 5px
}

.login-page1 .login-content-header img {
    margin-bottom: 20px
}

.login-page1 .left-page {
    position: relative;
    z-index: 10;
    width: 34%;
    height: 100%;
    padding: 50px;
    display: flex;
    align-items: center
}

.login-page1 .right-page {
    height: 100vh;
    position: fixed;
    right: 0;
    width: 66%
}

.login-page1 .right-page .animation-block {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.login-page1 .login-contain-main {
    display: flex
}

@media(max-width:1470px) {
    .login-page1 .right-page {
        width: 60%
    }


}

@media(max-width:1434px) {
    .login-content {
        width: 430px
    }

    .login-content .form1 .form-group .buttons .btn,
    .login-content .form2 .form-group .buttons .btn {
        padding: 14px 50px
    }
}



@media(min-width:992px)and (max-width:1200px) {

    .login-content .line h6:after,
    .login-content .line h6:before {
        width: 3.5rem
    }
}

@media(max-width:1200px) {
    .login-page1 .left-page {
        padding: 30px
    }

    .right-login .boy-logo {
        left: 29%;
        top: 50%
    }

    .right-login .girl-logo {
        right: 14%;
        top: 27%
    }

    .right-login .has-logo1 {
        left: 12%
    }

    .right-login .login-img {
        width: 65%
    }

    .login-content .login-content-header {
        padding-bottom: 27px
    }

    .login-content h4 {
        margin-bottom: 24px
    }

    .login-content .form1 .form-group .form-control,
    .login-content .form2 .form-group .form-control {
        padding: 15px
    }

    .login-content .form1 .form-group .col-form-label,
    .login-content .form2 .form-group .col-form-label {
        padding-bottom: 16px
    }

    .login-content .form1 .form-group .buttons,
    .login-content .form1 .form-group .form-check,
    .login-content .form2 .form-group .buttons,
    .login-content .form2 .form-group .form-check,
    .login-content .line,
    .login-content .medialogo {
        margin-bottom: 30px
    }
}

@media(max-width:1199px) {
    .login-page1 .left-page {
        width: 40%
    }
}

@media(max-width:1024px) {
    .login-content {
        width: 350px
    }
}

@media(max-width:991px) {
    .right-login .login-img {
        width: 80%
    }

    .right-login .girl-logo {
        width: 35%;
        top: 23%;
        right: 3%
    }

    .right-login .boy-logo {
        left: 21%;
        top: 45%;
        width: 27%
    }

    .right-login .has-logo1 {
        top: 69%;
        left: 2%
    }

    .login-page1 .left-page,
    .login-page1 .right-page {
        width: 50%
    }
}

@media(max-width:768px) {
    .login-content {
        width: 340px
    }

    .login-content .line h6:after,
    .login-content .line h6:before {
        width: 3.5rem
    }

    .right-login .boy-logo {
        left: 23%;
        top: 43%;
        width: 24%
    }

    .girl-logo {
        right: 11%;
        top: 24%
    }
}

@media(max-width:767px) {
    .right-page {
        position: absolute;
        opacity: .05
    }

    .login-content {
        width: auto;
        padding: 50px
    }

    .login-content .login-content-header {
        padding-bottom: 17px
    }

    .login-content .login-content-header img {
        height: 40px
    }

    .login-content h4 {
        margin-bottom: 17px
    }

    .login-content .form1 .form-group,
    .login-content .form2 .form-group {
        margin-bottom: 20px
    }

    .login-content .form1 .form-group .col-form-label,
    .login-content .form2 .form-group .col-form-label {
        padding-bottom: 10px
    }

    .login-content .form1 .form-group .form-check,
    .login-content .form2 .form-group .form-check {
        margin-bottom: 20px
    }

    .login-content .form2 .form-group .buttons .btn {
        padding: 12px 35px
    }

    .login-page1 .left-page,
    .login-page1 .right-page {
        width: 100%
    }

    /* .login-page2 .login-content-main .login-content {
        width: 530px;
        padding: 30px
    }

    .login-page2 .login-content-main .medialogo {
        position: unset;
        display: flex;
        justify-content: center;
        margin-bottom: 20px
    }

    .login-page2 .login-content-main .medialogo li+li {
        margin-top: unset;
        margin-left: 15px
    }

    .login-page2 .login-content-main .login-content2 .theme-tab .nav-tabs .nav-item .nav-link {
        min-width: auto;
        padding: 10px 35px
    } */

    .login-content h3 {
        padding-bottom: 10px
    }
}



@media(max-width:575px) {
    .login-content {
        padding: 30px
    }

    .login-content .form1 .form-group .buttons .btn,
    .login-content .form2 .form-group .buttons .btn {
        padding: 12px 25px
    }

    .login-content .form1 .form-group .form-control,
    .login-content .form2 .form-group .form-control {
        padding: 10px;
        line-height: 1
    }

    .login-content .form1 .form-group .form-control::placeholder,
    .login-content .form2 .form-group .form-control::placeholder {
        font-size: 13px
    }

    .login-content .login-content-header img {
        height: 30px
    }

    .login-page1 .left-page {
        padding: 0
    }

 
}

@media(max-width:320px) {

    .login-content .line h6:after,
    .login-content .line h6:before {
        width: 2.5rem
    }
}

   
@media(max-width:992px) {


    .login-content .line h6:after,
    .login-content .line h6:before {
        width: 3.5rem
    }

    .mr-3 {
        margin-right: 10px !important
    }

}



@media(max-width:320px) {
   

    .login-content .line h6:after,
    .login-content .line h6:before {
        width: 2.5rem
    }

}

