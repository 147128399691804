/** Css Added By Developer: Start */
.disable {
    pointer-events: none;
}

.show {
    display: block !important;
}

.hide {
    display: none !important;
}

.display-show {
    display: block !important;
}

.display-none {
    display: none !important;
}

.action-disabled {
    pointer-events: none;
    opacity: 0.7;
    color: #808080 !important;
}

.dial-disabled {
    pointer-events: none;
    opacity: 0.7;
}

.user-registered {
    background-color: #2de466 !important;
}

.user-unregistered {
    background-color: #E6404E !important;
}

.cursor-pointer {
    cursor: pointer;
}

/**Loader Css */
#loading {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: rgba(192, 192, 192, 0.5);
    /* background-image: url("../../public/app-icon/icon/loader.png"); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 5%;
}

.error {
    color: red;
    font-size: 14px;
}

.back-button {
    margin-bottom: 40px;
}

.back-button img {
    width: 20px;
    height: auto;
}

.blurme {
    -webkit-filter: blur(2px) grayscale(.5) opacity(0.8);
}

.blur-image {
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

/** Css Added By Developer: End */
.css-13cymwt-control {
    box-shadow: none !important;
}

.image-select__control {
    border: none !important;
    box-shadow: none !important;
}

.resend_otp_btn {
    background: white !important;
    color: var(--primary-color);
    font-weight: 500;
    border: none;
}

.header .nav-item.active {
    background-color: var(--primary-color) !important;
}

.sidebar_icon {
    width: 28px;
    height: 28px;
}

.scale_icon {
    scale: 1.3;
}

.page-title {
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--white-color);
    position: sticky;
    top: 0;
    z-index: 99 !important;
    border-bottom: 1px solid var(--divider-border);
}

.back_button {
    background: white;
    border: none;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: rotateY(-50%);
    transform: translateY(-50%)
}

.custom-modal .modal-title {
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.custom-modal .modal-header {
    background: var(--main-gradient-color);
    border-radius: 25px 25px 0px 0px !important;
    color: var(--white-color) !important;
    display: block;
    padding-bottom: 15px;
}

.call-time {
    padding: 2px;
}

.call-time span {
    color: white !important;
    padding: 5px
}

.callingScreen {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: red; */
    width: 100%;
    z-index: 9999;
}

.application-settings {
    overflow-y: scroll !important;
    height: 100% !important;
}

.application-settings h4 {
    text-transform: capitalize;
}

.application-settings li {
    margin-bottom: 10px !important;
    border-bottom: 1px solid var(--divider-border) !important;
}

.application-settings li:last-child {
    margin-bottom: 0;
    border-bottom: none !important;
}

.setting_open_icon {
    width: 27px;
    height: 27px;
    color: rgba(0, 0, 0, 0.5);
}

.custom-modal .modal-content {
    padding: 0 !important;
}

.selected_item {
    width: 35px !important;
    height: 35px !important;
    border: 4px solid var(--primary-color) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50% !important;
    color: var(--primary-color);
    position: relative;
}

.value {
    font-weight: bold !important;
}

.setting_text_input.form-control {
    border-bottom: 3px solid var(--primary-color) !important;
}

.mobile_menu {
    position: fixed;
    bottom: 0 !important;
    background: var(--main-gradient-color) !important;
    width: 100%;
    padding-bottom: 10px;
}

.mobile_menu .navbar-nav {
    flex-direction: row;
    justify-content: space-around;
    overflow-x: scroll;
}

.mobile_menu .nav-item {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.menu.navbar-nav .nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile_menu .nav-item.active {
    background: transparent !important;
    color: var(--main-gradient-color) !important;
    position: relative;
    z-index: 9999;
}

.mobile_menu .nav-item svg {
    font-size: 22px;
}

.mobile_menu .nav-item.active::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    height: 92%;
    z-index: -1;
    border-radius: 0 0 50% 50%;

}

.mobile_menu .nav-item.active .nav-link svg {
    color: var(--primary-color) !important;
}

/* .header .navbar-nav:last-child{
    margin-left: auto !important;
} */
/* 767 */
.custom_toast {
    background: var(--main-gradient-color);
    color: white;
    font-size: 15px;
    border: none;
    bottom: 20px !important;
    right: 20px !important;
}

.toast-container {
    bottom: 20px !important;
    right: 20px !important;
}

.button-outline {
    color: var(--cancel-btn-color);
    background-color: var(--white-color);
    border: 1px solid var(--cancel-btn-color);
}

.button-outline:hover {
    background: var(--main-gradient-color);
    font-weight: 700;
    color: white;
}

.button-gradient {
    background: var(--main-gradient-color);
    font-weight: 700;
    color: white;
    border: 1px solid var(--main-gradient-color);
}

.button-gradient svg {
    color: white;
}

.button-gradient:hover {
    color: var(--cancel-btn-color);
    background-color: var(--white-color);
    border: 1px solid var(--cancel-btn-color);
}

.react-draggable {
    position: absolute;
    z-index: 9999999;
    top: 0;
    left: 0;
    width: 100%;
}