@keyframes progress-bar-stripes {
    0% {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

@keyframes spinner-border {
    to {
        transform: rotate(1turn)
    }
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(359deg)
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0)
    }

    20% {
        transform: translateY(0)
    }

    50% {
        transform: translateY(0)
    }

    80% {
        transform: translateY(0)
    }

    to {
        transform: translateY(0)
    }

    40% {
        transform: translateY(-30px)
    }

    60% {
        transform: translateY(-15px)
    }
}

@keyframes flash {
    0% {
        opacity: 1
    }

    50% {
        opacity: 1
    }

    to {
        opacity: 1
    }

    25% {
        opacity: 0
    }

    75% {
        opacity: 0
    }
}

@keyframes jello {
    to {
        transform: none
    }

    0.1% {
        transform: none
    }

    1.2% {
        transform: skewX(-12.5deg) skewY(-12.5deg)
    }

    2.3% {
        transform: skewX(6.25deg) skewY(6.25deg)
    }

    3.4% {
        transform: skewX(-3.125deg) skewY(-3.125deg)
    }

    4.5% {
        transform: skewX(1.5625deg) skewY(1.5625deg)
    }

    5.6% {
        transform: skewX(-.78125deg) skewY(-.78125deg)
    }

    6.7% {
        transform: skewX(.39063deg) skewY(.39063deg)
    }

    7.8% {
        transform: skewX(-.19531deg) skewY(-.19531deg)
    }
}

@keyframes pulse {
    0% {
        transform: scale(1)
    }

    50% {
        transform: scale(1.1)
    }

    to {
        transform: scale(1)
    }
}

@keyframes rubberBand {
    0% {
        transform: scaleX(1)
    }

    30% {
        transform: scale3d(1.25, .75, 1)
    }

    40% {
        transform: scale3d(.75, 1.25, 1)
    }

    50% {
        transform: scale3d(1.15, .85, 1)
    }

    65% {
        transform: scale3d(.95, 1.05, 1)
    }

    75% {
        transform: scale3d(1.05, .95, 1)
    }

    to {
        transform: scaleX(1)
    }
}

@keyframes shake {
    0% {
        transform: translateX(0)
    }

    to {
        transform: translateX(0)
    }

    10% {
        transform: translateX(-10px)
    }

    30% {
        transform: translateX(-10px)
    }

    50% {
        transform: translateX(-10px)
    }

    70% {
        transform: translateX(-10px)
    }

    90% {
        transform: translateX(-10px)
    }

    20% {
        transform: translateX(10px)
    }

    40% {
        transform: translateX(10px)
    }

    60% {
        transform: translateX(10px)
    }

    80% {
        transform: translateX(10px)
    }
}

@keyframes swing {
    20% {
        transform-origin: top center;
        transform: rotate(15deg)
    }

    40% {
        transform-origin: top center;
        transform: rotate(-10deg)
    }

    60% {
        transform-origin: top center;
        transform: rotate(5deg)
    }

    80% {
        transform-origin: top center;
        transform: rotate(-5deg)
    }

    to {
        transform-origin: top center;
        transform: rotate(0deg)
    }
}

@keyframes tada {
    0% {
        transform: scale(1)
    }

    10% {
        transform: scale(.9) rotate(-3deg)
    }

    20% {
        transform: scale(.9) rotate(-3deg)
    }

    30% {
        transform: scale(1.1) rotate(3deg)
    }

    50% {
        transform: scale(1.1) rotate(3deg)
    }

    70% {
        transform: scale(1.1) rotate(3deg)
    }

    90% {
        transform: scale(1.1) rotate(3deg)
    }

    40% {
        transform: scale(1.1) rotate(-3deg)
    }

    60% {
        transform: scale(1.1) rotate(-3deg)
    }

    80% {
        transform: scale(1.1) rotate(-3deg)
    }

    to {
        transform: scale(1) rotate(0)
    }
}

@keyframes wobble {
    0% {
        transform: translateX(0)
    }

    15% {
        transform: translateX(-25%) rotate(-5deg)
    }

    30% {
        transform: translateX(20%) rotate(3deg)
    }

    45% {
        transform: translateX(-15%) rotate(-3deg)
    }

    60% {
        transform: translateX(10%) rotate(2deg)
    }

    75% {
        transform: translateX(-5%) rotate(-1deg)
    }

    to {
        transform: translateX(0)
    }
}

@keyframes bounceIn {
    0% {
        opacity: 0;
        transform: scale(.3)
    }

    50% {
        opacity: 1;
        transform: scale(1.05)
    }

    70% {
        transform: scale(.9)
    }

    to {
        transform: scale(1)
    }
}

@keyframes bounceInDown {
    0% {
        opacity: 0;
        transform: translateY(-2000px)
    }

    60% {
        opacity: 1;
        transform: translateY(30px)
    }

    80% {
        transform: translateY(-10px)
    }

    to {
        transform: translateY(0)
    }
}

@keyframes bounceInLeft {
    0% {
        opacity: 0;
        transform: translateX(-2000px)
    }

    60% {
        opacity: 1;
        transform: translateX(30px)
    }

    80% {
        transform: translateX(-10px)
    }

    to {
        transform: translateX(0)
    }
}

@keyframes bounceInRight {
    0% {
        opacity: 0;
        transform: translateX(2000px)
    }

    60% {
        opacity: 1;
        transform: translateX(-30px)
    }

    80% {
        transform: translateX(10px)
    }

    to {
        transform: translateX(0)
    }
}

@keyframes bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(2000px)
    }

    60% {
        opacity: 1;
        transform: translateY(-30px)
    }

    80% {
        transform: translateY(10px)
    }

    to {
        transform: translateY(0)
    }
}

@keyframes bounceOut {
    0% {
        transform: scale(1)
    }

    25% {
        transform: scale(.95)
    }

    50% {
        opacity: 1;
        transform: scale(1.1)
    }

    to {
        opacity: 0;
        transform: scale(.3)
    }
}

@keyframes bounceOutDown {
    0% {
        transform: translateY(0)
    }

    20% {
        opacity: 1;
        transform: translateY(-20px)
    }

    to {
        opacity: 0;
        transform: translateY(2000px)
    }
}

@keyframes bounceOutLeft {
    0% {
        transform: translateX(0)
    }

    20% {
        opacity: 1;
        transform: translateX(20px)
    }

    to {
        opacity: 0;
        transform: translateX(-2000px)
    }
}

@keyframes bounceOutRight {
    0% {
        transform: translateX(0)
    }

    20% {
        opacity: 1;
        transform: translateX(-20px)
    }

    to {
        opacity: 0;
        transform: translateX(2000px)
    }
}

@keyframes bounceOutUp {
    0% {
        transform: translateY(0)
    }

    20% {
        opacity: 1;
        transform: translateY(20px)
    }

    to {
        opacity: 0;
        transform: translateY(-2000px)
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes fadeInDownBig {
    0% {
        opacity: 0;
        transform: translateY(-2000px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px)
    }

    to {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        transform: translateX(-2000px)
    }

    to {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(20px)
    }

    to {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes fadeInRightBig {
    0% {
        opacity: 0;
        transform: translateX(2000px)
    }

    to {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes fadeInUpBig {
    0% {
        opacity: 0;
        transform: translateY(2000px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes fadeOutDown {
    0% {
        opacity: 1;
        transform: translateY(0)
    }

    to {
        opacity: 0;
        transform: translateY(20px)
    }
}

@keyframes fadeOutDownBig {
    0% {
        opacity: 1;
        transform: translateY(0)
    }

    to {
        opacity: 0;
        transform: translateY(2000px)
    }
}

@keyframes fadeOutLeft {
    0% {
        opacity: 1;
        transform: translateX(0)
    }

    to {
        opacity: 0;
        transform: translateX(-20px)
    }
}

@keyframes fadeOutLeftBig {
    0% {
        opacity: 1;
        transform: translateX(0)
    }

    to {
        opacity: 0;
        transform: translateX(-2000px)
    }
}

@keyframes fadeOutRight {
    0% {
        opacity: 1;
        transform: translateX(0)
    }

    to {
        opacity: 0;
        transform: translateX(20px)
    }
}

@keyframes fadeOutRightBig {
    0% {
        opacity: 1;
        transform: translateX(0)
    }

    to {
        opacity: 0;
        transform: translateX(2000px)
    }
}

@keyframes fadeOutUp {
    0% {
        opacity: 1;
        transform: translateY(0)
    }

    to {
        opacity: 0;
        transform: translateY(-20px)
    }
}

@keyframes fadeOutUpBig {
    0% {
        opacity: 1;
        transform: translateY(0)
    }

    to {
        opacity: 0;
        transform: translateY(-2000px)
    }
}

@keyframes flip {
    0% {
        transform: perspective(400px) rotateY(0);
        animation-timing-function: ease-out
    }

    40% {
        transform: perspective(400px) translateZ(150px) rotateY(170deg);
        animation-timing-function: ease-out
    }

    50% {
        transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        animation-timing-function: ease-in
    }

    80% {
        transform: perspective(400px) rotateY(1turn) scale(.95);
        animation-timing-function: ease-in
    }

    to {
        transform: perspective(400px) scale(1);
        animation-timing-function: ease-in
    }
}

@keyframes flipInX {
    0% {
        opacity: 0;
        transform: perspective(400px) rotateX(90deg)
    }

    40% {
        transform: perspective(400px) rotateX(-10deg)
    }

    70% {
        transform: perspective(400px) rotateX(10deg)
    }

    to {
        opacity: 1;
        transform: perspective(400px) rotateX(0deg)
    }
}

@keyframes flipInY {
    0% {
        opacity: 0;
        transform: perspective(400px) rotateY(90deg)
    }

    40% {
        transform: perspective(400px) rotateY(-10deg)
    }

    70% {
        transform: perspective(400px) rotateY(10deg)
    }

    to {
        opacity: 1;
        transform: perspective(400px) rotateY(0deg)
    }
}

@keyframes flipOutX {
    0% {
        opacity: 1;
        transform: perspective(400px) rotateX(0deg)
    }

    to {
        opacity: 0;
        transform: perspective(400px) rotateX(90deg)
    }
}

@keyframes flipOutY {
    0% {
        opacity: 1;
        transform: perspective(400px) rotateY(0deg)
    }

    to {
        opacity: 0;
        transform: perspective(400px) rotateY(90deg)
    }
}

@keyframes lightSpeedIn {
    0% {
        opacity: 0;
        transform: translateX(100%) skewX(-30deg)
    }

    60% {
        opacity: 1;
        transform: translateX(-20%) skewX(30deg)
    }

    80% {
        opacity: 1;
        transform: translateX(0) skewX(-15deg)
    }

    to {
        opacity: 1;
        transform: translateX(0) skewX(0deg)
    }
}

@keyframes lightSpeedOut {
    0% {
        opacity: 1;
        transform: translateX(0) skewX(0deg)
    }

    to {
        opacity: 0;
        transform: translateX(100%) skewX(-30deg)
    }
}

@keyframes rotateIn {
    0% {
        opacity: 0;
        transform: rotate(-200deg);
        transform-origin: center center
    }

    to {
        opacity: 1;
        transform: rotate(0);
        transform-origin: center center
    }
}

@keyframes rotateInDownLeft {
    0% {
        opacity: 0;
        transform: rotate(-90deg);
        transform-origin: left bottom
    }

    to {
        opacity: 1;
        transform: rotate(0);
        transform-origin: left bottom
    }
}

@keyframes rotateInDownRight {
    0% {
        opacity: 0;
        transform: rotate(90deg);
        transform-origin: right bottom
    }

    to {
        opacity: 1;
        transform: rotate(0);
        transform-origin: right bottom
    }
}

@keyframes rotateInUpLeft {
    0% {
        opacity: 0;
        transform: rotate(90deg);
        transform-origin: left bottom
    }

    to {
        opacity: 1;
        transform: rotate(0);
        transform-origin: left bottom
    }
}

@keyframes rotateInUpRight {
    0% {
        opacity: 0;
        transform: rotate(-90deg);
        transform-origin: right bottom
    }

    to {
        opacity: 1;
        transform: rotate(0);
        transform-origin: right bottom
    }
}

@keyframes rotateOut {
    0% {
        opacity: 1;
        transform: rotate(0);
        transform-origin: center center
    }

    to {
        opacity: 0;
        transform: rotate(200deg);
        transform-origin: center center
    }
}

@keyframes rotateOutDownLeft {
    0% {
        opacity: 1;
        transform: rotate(0);
        transform-origin: left bottom
    }

    to {
        opacity: 0;
        transform: rotate(90deg);
        transform-origin: left bottom
    }
}

@keyframes rotateOutDownRight {
    0% {
        opacity: 1;
        transform: rotate(0);
        transform-origin: right bottom
    }

    to {
        opacity: 0;
        transform: rotate(-90deg);
        transform-origin: right bottom
    }
}

@keyframes rotateOutUpLeft {
    0% {
        opacity: 1;
        transform: rotate(0);
        transform-origin: left bottom
    }

    to {
        opacity: 0;
        transform: rotate(-90deg);
        transform-origin: left bottom
    }
}

@keyframes rotateOutUpRight {
    0% {
        opacity: 1;
        transform: rotate(0);
        transform-origin: right bottom
    }

    to {
        opacity: 0;
        transform: rotate(90deg);
        transform-origin: right bottom
    }
}

@keyframes slideInDown {
    0% {
        opacity: 0;
        transform: translateY(-2000px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes slideInLeft {
    0% {
        opacity: 0;
        transform: translateX(-2000px)
    }

    to {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translateX(2000px)
    }

    to {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes slideInUp {
    0% {
        opacity: 0;
        transform: translateY(2000px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes slideOutDown {
    0% {
        transform: translateY(0)
    }

    to {
        opacity: 0;
        transform: translateY(2000px)
    }
}

@keyframes slideOutLeft {
    0% {
        transform: translateX(0)
    }

    to {
        opacity: 0;
        transform: translateX(-2000px)
    }
}

@keyframes slideOutRight {
    0% {
        transform: translateX(0)
    }

    to {
        opacity: 0;
        transform: translateX(2000px)
    }
}

@keyframes slideOutUp {
    0% {
        transform: translateY(0)
    }

    to {
        opacity: 0;
        transform: translateY(-2000px)
    }
}

@keyframes hinge {
    0% {
        animation-timing-function: ease-in-out;
        transform: rotate(0);
        transform-origin: top left
    }

    20% {
        animation-timing-function: ease-in-out;
        transform: rotate(80deg);
        transform-origin: top left
    }

    60% {
        animation-timing-function: ease-in-out;
        transform: rotate(80deg);
        transform-origin: top left
    }

    40% {
        animation-timing-function: ease-in-out;
        transform: rotate(60deg);
        transform-origin: top left
    }

    80% {
        opacity: 1;
        animation-timing-function: ease-in-out;
        transform: rotate(60deg) translateY(0);
        transform-origin: top left
    }

    to {
        opacity: 0;
        transform: translateY(700px)
    }
}

@keyframes rollIn {
    0% {
        opacity: 0;
        transform: translateX(-100%) rotate(-120deg)
    }

    to {
        opacity: 1;
        transform: translateX(0) rotate(0deg)
    }
}

@keyframes rollOut {
    0% {
        opacity: 1;
        transform: translateX(0) rotate(0deg)
    }

    to {
        opacity: 0;
        transform: translateX(100%) rotate(120deg)
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale3d(.3, .3, .3)
    }

    50% {
        opacity: 1
    }
}

@keyframes zoomInDown {
    0% {
        animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        opacity: 0;
        transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0)
    }

    60% {
        animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        opacity: 1;
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0)
    }
}

@keyframes zoomInLeft {
    0% {
        animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        opacity: 0;
        transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0)
    }

    60% {
        animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        opacity: 1;
        transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0)
    }
}

@keyframes zoomInRight {
    0% {
        animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        opacity: 0;
        transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0)
    }

    60% {
        animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        opacity: 1;
        transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0)
    }
}

@keyframes zoomInUp {
    0% {
        animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        opacity: 0;
        transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0)
    }

    60% {
        animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        opacity: 1;
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0)
    }
}

@keyframes zoomOut {
    0% {
        opacity: 1
    }

    50% {
        opacity: 0;
        transform: scale3d(.3, .3, .3)
    }

    to {
        opacity: 0
    }
}

@keyframes zoomOutDown {
    40% {
        animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        opacity: 1;
        transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0)
    }

    to {
        animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        opacity: 0;
        transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
        transform-origin: center bottom
    }
}

@keyframes zoomOutLeft {
    40% {
        opacity: 1;
        transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0)
    }

    to {
        opacity: 0;
        transform: scale(.1) translate3d(-2000px, 0, 0);
        transform-origin: left center
    }
}

@keyframes zoomOutRight {
    40% {
        opacity: 1;
        transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0)
    }

    to {
        opacity: 0;
        transform: scale(.1) translate3d(2000px, 0, 0);
        transform-origin: right center
    }
}

@keyframes zoomOutUp {
    40% {
        animation-timing-function: cubic-bezier(.55, .055, .675, .19);
        opacity: 1;
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0)
    }

    to {
        animation-timing-function: cubic-bezier(.175, .885, .32, 1);
        opacity: 0;
        transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
        transform-origin: center bottom
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes grow {
    0% {
        transform: scale(.1);
        opacity: .1
    }

    33% {
        transform: scale(.5);
        opacity: .3
    }

    66% {
        transform: scale(.8);
        opacity: .5
    }

    to {
        transform: scale(1);
        opacity: 0
    }
}

@keyframes circle {
    0% {
        opacity: 1
    }

    40% {
        opacity: 1
    }

    to {
        width: 200%;
        height: 200%;
        opacity: 0
    }
}

@keyframes phone-outer-success {
    0% {
        transform: translateZ(0) scale(1);
        box-shadow: 0 0 0 0 rgba(63, 204, 53, 0), 0 .05em .1em rgba(0, 0, 0, .2)
    }

    33.3333% {
        transform: translateZ(0) scale(1.1);
        box-shadow: 0 0 0 0 rgba(63, 204, 53, .9), 0 .05em .1em rgba(0, 0, 0, .5)
    }

    66.6666% {
        transform: translateZ(0) scale(1);
        box-shadow: 0 0 0 1.2em rgba(63, 204, 53, 0), 0 .05em .1em rgba(0, 0, 0, .2)
    }

    to {
        transform: translateZ(0) scale(1);
        box-shadow: 0 0 0 0 rgba(63, 204, 53, 0), 0 .05em .1em rgba(0, 0, 0, .2)
    }
}

@keyframes phone-outer-danger {
    0% {
        transform: translateZ(0) scale(1);
        box-shadow: 0 0 0 0 rgba(255, 78, 43, 0), 0 .05em .1em rgba(0, 0, 0, .2)
    }

    33.3333% {
        transform: translateZ(0) scale(1.1);
        box-shadow: 0 0 0 0 rgba(255, 78, 43, .9), 0 .05em .1em rgba(0, 0, 0, .5)
    }

    66.6666% {
        transform: translateZ(0) scale(1);
        box-shadow: 0 0 0 1.2em rgba(255, 78, 43, 0), 0 .05em .1em rgba(0, 0, 0, .2)
    }

    to {
        transform: translateZ(0) scale(1);
        box-shadow: 0 0 0 0 rgba(255, 78, 43, 0), 0 .05em .1em rgba(0, 0, 0, .2)
    }
}

@keyframes phone-inner {
    0% {
        opacity: 1;
        transform: translateZ(0) scale(0)
    }

    33.3333% {
        opacity: 1;
        transform: translateZ(0) scale(.9)
    }

    66.6666% {
        opacity: 0;
        transform: translateZ(0) scale(0)
    }

    to {
        opacity: 0;
        transform: translateZ(0) scale(0)
    }
}

@keyframes phone-icon {
    0% {
        transform: translateZ(0)
    }

    2% {
        transform: translate3d(.6px, 0, 0)
    }

    4% {
        transform: translate3d(-.6px, 0, 0)
    }

    6% {
        transform: translate3d(.6px, 0, 0)
    }

    8% {
        transform: translate3d(-.6px, 0, 0)
    }

    10% {
        transform: translate3d(.6px, 0, 0)
    }

    12% {
        transform: translate3d(-.6px, 0, 0)
    }

    14% {
        transform: translate3d(.6px, 0, 0)
    }

    16% {
        transform: translate3d(-.6px, 0, 0)
    }

    18% {
        transform: translate3d(.6px, 0, 0)
    }

    20% {
        transform: translate3d(-.6px, 0, 0)
    }

    22% {
        transform: translate3d(.6px, 0, 0)
    }

    24% {
        transform: translate3d(-.6px, 0, 0)
    }

    26% {
        transform: translate3d(.6px, 0, 0)
    }

    28% {
        transform: translate3d(-.6px, 0, 0)
    }

    30% {
        transform: translate3d(.6px, 0, 0)
    }

    32% {
        transform: translate3d(-.6px, 0, 0)
    }

    34% {
        transform: translate3d(.6px, 0, 0)
    }

    36% {
        transform: translate3d(-.6px, 0, 0)
    }

    38% {
        transform: translate3d(.6px, 0, 0)
    }

    40% {
        transform: translate3d(-.6px, 0, 0)
    }

    42% {
        transform: translate3d(.6px, 0, 0)
    }

    44% {
        transform: translate3d(-.6px, 0, 0)
    }

    46% {
        transform: translateZ(0)
    }
}

@keyframes imggrow {
    0% {
        transform: scale(1)
    }

    50% {
        transform: scale(1.2)
    }

    to {
        transform: scale(1)
    }
}

@keyframes loadertyping {
    0% {
        box-shadow: 0 40px 0 #fff
    }

    to {
        box-shadow: 0 20px 0 #fff
    }
}

@keyframes animationFramesOne {
    0% {
        transform: translate(0) rotate(0deg)
    }

    20% {
        transform: translate(73px, -1px) rotate(36deg)
    }

    40% {
        transform: translate(141px, 72px) rotate(72deg)
    }

    60% {
        transform: translate(83px, 122px) rotate(108deg)
    }

    80% {
        transform: translate(-40px, 72px) rotate(144deg)
    }

    to {
        transform: translate(0) rotate(0deg)
    }
}

@keyframes rotatedTwo {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

@keyframes ripple1 {
    0% {
        transform: scale(1.5);
        opacity: .3
    }

    to {
        transform: scale(3.5);
        opacity: 0
    }
}

@keyframes ripple2 {
    0% {
        transform: scale(3.5)
    }

    to {
        transform: scale(5.5)
    }
}

@keyframes ripple3 {
    0% {
        transform: scale(1.5)
    }

    to {
        transform: scale(3.5)
    }
}

@keyframes animateCloud {
    0% {
        margin-left: -700px
    }

    to {
        margin-left: 100%
    }
}

@keyframes homecloud {
    0% {
        transform: translateX(0)
    }

    50% {
        transform: translateX(1000px)
    }

    to {
        transform: translateX(400px)
    }
}

@keyframes heartbeat {
    0% {
        transform: scale(.75)
    }

    20% {
        transform: scale(1)
    }

    40% {
        transform: scale(.75)
    }

    60% {
        transform: scale(1)
    }

    80% {
        transform: scale(.75)
    }

    to {
        transform: scale(.75)
    }
}

@keyframes clockwise {
    0% {
        top: -5px;
        left: 0
    }

    12% {
        top: -2px;
        left: 2px
    }

    25% {
        top: 0;
        left: 5px
    }

    37% {
        top: 2px;
        left: 2px
    }

    50% {
        top: 5px;
        left: 0
    }

    62% {
        top: 2px;
        left: -2px
    }

    75% {
        top: 0;
        left: -5px
    }

    87% {
        top: -2px;
        left: -2px
    }

    to {
        top: -5px;
        left: 0
    }
}

@keyframes counterclockwise {
    0% {
        top: -5px;
        right: 0
    }

    12% {
        top: -2px;
        right: 2px
    }

    25% {
        top: 0;
        right: 5px
    }

    37% {
        top: 2px;
        right: 2px
    }

    50% {
        top: 5px;
        right: 0
    }

    62% {
        top: 2px;
        right: -2px
    }

    75% {
        top: 0;
        right: -5px
    }

    87% {
        top: -2px;
        right: -2px
    }

    to {
        top: -5px;
        right: 0
    }
}

@keyframes anim-effect-back {
    0% {
        box-shadow: 0 0 0 15px rgba(28, 157, 234, .2);
        opacity: 0
    }

    50% {
        box-shadow: 0 0 0 30px rgba(28, 157, 234, .6);
        opacity: 1
    }

    to {
        box-shadow: 0 0 0 40px rgba(28, 157, 234, 0);
        opacity: 0
    }
}

@keyframes anim-effect-back {
    0% {
        box-shadow: 0 0 0 15px rgba(28, 157, 234, .2);
        opacity: 0
    }

    50% {
        box-shadow: 0 0 0 25px rgba(28, 157, 234, .6);
        opacity: 1
    }

    to {
        box-shadow: 0 0 0 30px rgba(28, 157, 234, 0);
        opacity: 0
    }
}