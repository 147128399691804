/* FONTS LINK */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "global.css";

/** Following variables are common for all clients if needed in fututr we will move to glabal.css file*/
/** VARIABLE DECLARATION :: STARTS */
:root {
    --white-color: #ffffff;
    --black-color: #000000;
    --grey-color: #BDBDBD;
    --error-background-color: rgba(240, 81, 68, 0.4);
    --input-placeholder: #C5C5C5;
    --active-color: #2DE466;
    --dark-grey: #474747;
    --common-radius: 5px;
    --digit-background: #F6F6F6;
    --active-call-color: #26AB23;
    --maximize-container-color: #F2F2F2;
    --common-btn-color: #E5E5E5;
    --cancel-btn-color: #949494;
    --chat-bubble-background: #EBEFF0;
    --forward-msg-background: #E4F6FF;
    --scroll-color: rgba(87, 161, 215, 0.3);
    --error-text: #F05144;
    --call-end-color: #E6404E;
    --input-border-color: #CBCBCB;
    --modal-box-shadow: 0px 2.7037px 18.9259px -4.50617px rgba(0, 0, 0, 0.1);
    --divider-border: #E4E5E5;
    --yellow-color: #FFDF39;
}

/* VARIABLE :: ENDS */

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

/* COMMON CSS :: STARTS */
* {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    font-family: 'Poppins', sans-serif;

}

body {
    overflow: hidden;
    padding: 0px !important;
}

.main-container {
    height: 100vh;
    overflow-y: hidden !important;
    overflow-x: hidden;
    padding: 0px !important;
}

.logo {
    margin-bottom: 100px;
}

a {
    text-decoration: none;
}

.logo img {
    width: 180px;
    height: 60px;
}

.heading {
    font-size: 35px;
    font-weight: 600;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 10px;
}

.sub-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: var(--cancel-btn-color);
    margin-bottom: 40px;
}

.input-group-text,
.form-control {
    border: unset !important;
}

.input-group {
    border: 1px solid var(--input-border-color) !important;
    border-radius: var(--common-radius) !important;
    height: 68px;
    background: var(--white-color);
    margin-bottom: 20px;
    display: flex;
    align-items: center !important;
}

.input-group-text {
    background-color: transparent !important;
}

.input-group input {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color) !important;
    height: 100%;
}

.form-control:focus {
    box-shadow: unset !important;
}

.input-group input::placeholder {
    color: var(--input-placeholder);
}

input[type=search] {
    height: 45px;
    background: var(--white-color);
    border: 1px solid var(--grey-color);
    border-radius: 30px;
    width: 310px;
    padding: 5px 5px 5px 35px;
    color: var(--black-color);
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;

}

input[type=search]:focus-visible {
    outline: none;
}

input[type=search]::placeholder {
    color: var(--input-placeholder);
}

.yellow-text {
    padding: 2px;
    background-color: var(--yellow-color);
    margin: auto 2px;
}

.error-box {
    background: var(--error-background-color);
    opacity: 0.9;
    border: 0.8px solid var(--error-text);
    padding: 3px 10px;
    width: fit-content !important;
    margin: auto;
    border-radius: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: var(--black-color);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
}

.error svg {
    margin-right: 5px;
    width: 15px;
}

.error-text {
    color: var(--error-text);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: -15px;
}


.SuccessfulBtn {
    background: rgba(38, 171, 35, 0.4);
    opacity: 0.9;
    border: 0.8px solid var(--active-call-color);
    padding: 3px 10px;
    width: fit-content !important;
    margin: auto;
    border-radius: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: var(--black-color);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
}

.SuccessfulBtn-text {
    color: var(--black-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.retry-text {
    color: var(--error-text) !important;
    font-size: 11px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    margin-left: 5px !important;
}

.retry-status {
    color: var(--error-text);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-right: 10px;
}

.retry-status img {
    margin: 5px;
}

.retry-icon {
    width: 64px;
    height: 64px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-error {
    border: 1px solid var(--error-text) !important;
}

.input-error svg path {
    fill: var(--error-text) !important;
}

.input-error input {
    color: var(--error-text) !important;
}

/* STATUS DOT */
.green-dot {
    background-color: var(--active-color);
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 2px solid var(--white-color);
}

.red-dot {
    background-color: var(--call-end-color);
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 2px solid var(--white-color);
}

.orange-dot {
    background-color: #FFA500;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 2px solid var(--white-color);
}

.yellow-dot {
    background-color: var(--yellow-color);
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 2px solid var(--white-color);
}

.grey-dot {
    background-color: var(--grey-color);
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 2px solid var(--white-color);
}

/* CUSTOM RADIO BUTTON */
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position: relative;
    padding: 5px 25px 5px 35px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    border: 4px solid var(--primary-color);
    border-radius: 100%;
    background: var(--white-color);
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: "";
    width: 18px;
    height: 18px;
    background: var(--primary-color);
    position: absolute;
    top: 6px;
    right: 6px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

a {
    cursor: pointer !important;
}

/* CUSTOM RADIO BUTTON */

/* SCROLL BAR CSS FOR RIGHT SIDE :: STARTS */
/* width */
::-webkit-scrollbar,
::-webkit-scrollbar,
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
    background: var(--scroll-color);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover {
    background: var(--scroll-color);
}


/* SCROLL BAR CSS FOR RIGHT SIDE:: ENDS */

header #headerLink .nav-item,
.header-link .nav-item {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-item.active-link {
    background-color: var(--active-navbar) !important;
}

/* CHECK BOX CSS :: STARTS */
.checkbox {
    width: 24px;
    height: 24px;
    position: relative;
}

.checkbox label {
    width: 100%;
    height: 100%;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid var(--cancel-btn-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox label:after {
    content: '';
    width: 10px;
    height: 5px;
    border: 1.5px solid var(--cancel-btn-color);
    border-top: none;
    border-right: none;
    background: transparent;
    transform: rotate(-45deg);
    position: absolute;
    top: 7px;
}

.checkbox input[type=checkbox] {
    visibility: hidden;
}

.checkbox input[type=checkbox]:checked+label {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.checkbox input[type=checkbox]:checked+label:after {
    opacity: 1;
    border-color: var(--white-color);
}

/* CHECKBOX CSS :: ENDS */

.common-btn:focus,
.common-btn:hover,
.common-btn:active,
.common-btn:focus-visible {
    color: var(--white-color) !important;
    outline: none;
    border: none;
}

/* .common-btn {
    height: 58px;
    padding: 14px 20px;
    font-size: 20px;
    line-height: 30px;
    border-radius: var(--common-radius);
    color: var(--white-color);
    border: none;
} */
.common-btn {
    height: 58px;
    padding: 14px 3px;
    font-size: 20px;
    line-height: 30px;
    border-radius: var(--common-radius);
    color: var(--white-color);
    border: none;
    /* font-size: 16px !important; */
    font-size: 17px !important;
}

.gradient-btn {
    background: var(--main-gradient-color);
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
}

.primary-btn {
    background-color: var(--primary-color);
}

.secondary-btn {
    background-color: var(--secondary-color);
}

.login-with-otp,
.login-with-qr,
.login-with-username {
    background-color: var(--auth-button-color);
    font-style: normal;
    font-weight: 600;
    /* margin-bottom: 20px; */
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

}

.signup-button {
    background-color: var(--secondary-color);
    position: absolute;
    bottom: 10px;
}

.outline-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 60px;
    border: 1px solid var(--primary-color);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    color: var(--primary-color);
    padding: 10px;
    background-color: transparent;
}

.common-btn svg {
    margin-right: 5px;
}

.outline-btn svg {
    margin-right: 10px;
}

button.end-button.mx-3.active-button {
    background-color: var(--call-end-color) !important;
}

.active-button {
    background-color: var(--primary-color) !important
}

.active-button svg path {
    fill: var(--white-color) !important;
}

.active-button svg line {
    stroke: var(--white-color) !important;
}

.record-button svg {
    height: 100% !important;
    width: 60% !important;
}

.disabled-button {
    opacity: 0.25;
}

ul#transfer-option svg path {
    fill: var(--primary-color) !important;
}

ul#transfer-option svg line {
    stroke: var(--primary-color) !important;
}

ul,
li {
    margin: 0px;
    padding: 0px !important;
    list-style-type: none;
}

li,
a,
button {
    cursor: pointer;
}

.hide-show-password {
    margin-right: 10px;
    color: var(--primary-color);
}

.bg-light-gery {
    background-color: var(--light-background);
}

.divider {
    width: 100%;
    border-bottom: 1px solid var(--divider-border);
}

header #headerLink .nav-item {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.active-link {
    background-color: var(--active-navbar);
}

/* COMMON CSS :: ENDS */

/* SPINNER CSS :: STARTS */
.spinner-box {
    width: 100vw;
    height: 100vh;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.spinner-box div {
    background: var(--white-color);
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: flex;
    align-items: center;
    display: flex;
    border-radius: 100%;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.loader {
    display: block;
    color: var(--primary-color);
    font-size: 5px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: spin 1.3s infinite linear;
    transform: translateZ(0);
}

@keyframes spin {

    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em,
            2em -2em 0 0em, 3em 0 0 -1em,
            2em 2em 0 -1em, 0 3em 0 -1em,
            -2em 2em 0 -1em, -3em 0 0 -1em,
            -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em,
            3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
            -2em 2em 0 -1em, -3em 0 0 -1em,
            -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em,
            2em -2em 0 0, 3em 0 0 0.2em,
            2em 2em 0 0, 0 3em 0 -1em,
            -2em 2em 0 -1em, -3em 0 0 -1em,
            -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
            3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
            -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
            3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
            -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
            3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
            -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em,
            3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
            -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em,
            3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
            -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

/* SPINNER CSS :: ENDS */

/* MODAL CSS :: STARTS */
.modal-dialog {
    max-width: 550px !important;
}

.modal-content {
    border-radius: 25px !important;
    text-align: center;
    padding: 30px 10px !important;
}

.modal-content svg {
    margin: 0px auto 20px auto;
}

.modal-content p {
    margin-top: 20px;
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
}

.modal-footer {
    border-top: unset !important;
    margin: auto;
    display: block;
}

.modal-footer button {
    padding: 5px 20px;
    border-radius: 60px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    border: unset;
    color: var(--white-color);
}

.modal-footer .cancel-btn {
    color: var(--cancel-btn-color);
    background-color: var(--white-color);
    border: 1px solid var(--cancel-btn-color);
}

/* MODAL CSS :: ENDS */

/* COMMON CSS FOR CALL HISTORY :: STARTS */
.calls-history {
    width: 95%;
    padding: 20px;
}

.calls-history .card {
    border: unset;
    border-bottom: 1px solid var(--common-btn-color);
    border-radius: unset;
    padding: 5px 0px;
}

.calls-history .card:last-child {
    margin-bottom: 80px;
}

.calls-history .card-header .caller-details {
    align-items: center;
    --bs-gutter-x: 0rem;
}

.calls-history .card-header .call {
    display: flex;
    justify-content: start;
    align-items: center;
    text-align: left;
}

.calls-history .card .card-header {
    background-color: transparent;
    border: unset;
}

.calls-history .card .card-body {
    padding: 0px !important;
    margin: 10px auto;
}

.calls-history .card .card-header:hover {
    background-color: var(--hover_states_light);
    border-radius: 8px;
}

.calls-history .card .card-header span.person {
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-color);
    border-radius: 50%;
    color: var(--white-color);
    font-size: 31px;
    font-weight: 500;
    line-height: 47px;
    letter-spacing: 0em;
}


.calls-history .caller-details .call-type {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: var(--black-color);
}

.calls-history .caller-details .call-type img {
    margin: auto 10px;
}

.call-history-container .missed-call {
    color: var(--error-text) !important;
}

.calls-history .card-header .accordion-button {
    background-color: transparent !important;
}

.calls-history .card-header .accordion-button::after {
    display: none !important;
}

.outgoingcall_icon {
    font-size: 18px;
    scale: 1.3;
    color: var(--active-call-color);
}

.incomingcall_icon {
    font-size: 18px;
    color: "#8581d4";
    scale: 1.3
}

.missedcall_icon {
    font-size: 18px;
    color: red;
    scale: 1.3
}

.conference-call span.person {
    width: 45px !important;
    height: 45px !important;
    position: relative;
}

.accordion-header {
    padding: 0 !important;
}

.conference-call span.person-second {
    width: 45px;
    height: 45px;
    position: absolute;
    z-index: 1;
    right: -15px;
    top: 10px;
}

.calls-history .card .card-header .conference-call span.person img {
    width: 100%;
}

.calls-history .card .card-header span.person svg {
    height: 40px;
    width: auto;
}

.calls-history .card .call-duration {
    text-align: right;
}

.calls-history .caller-details h6 {
    font-size: 21px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);

}

.calls-history .caller-details h6 svg {
    vertical-align: baseline;
}

.calls-history .caller-details p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
}

.calls-history .call-day {
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
    margin-top: 10px;
}

.calls-history .call-duration p {
    color: var(--dark-grey);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: right;
}

.calls-history .opened-calls-detail-box {
    background-color: var(--hover_states_light);
    padding: 0px 20px !important;
    border-radius: 8px;
}

.calls-history .opened-calls-detail-box li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px !important;
}

.calls-history .opened-calls-detail-box li:not(:last-child) {
    border-bottom: 1px solid var(--secondary-color);
}

.calls-history .opened-calls-detail-box .call-type {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
}

.calls-history .opened-calls-detail-box .call-type img {
    margin-right: 25px;
}

.calls-history .call-type svg {
    width: 25px;
    margin: auto 10px;
}

.calls-history .opened-calls-detail-box .call-type svg {
    margin-right: 25px;
}

.calls-history .opened-calls-detail-box .call-type svg path {
    fill: var(--primary-color);
}

.calls-history .opened-calls-detail-box .call-type p {
    margin-bottom: 0px;
    color: var(--black-color);
}

.calls-history .opened-calls-detail-box .call-type p span,
.call-duration p {
    display: block;
    color: var(--dark-grey);
}

.calls-history .opened-calls-detail-box .missed-call .call-date {
    color: var(--error-text) !important;
}

.calls-history .opened-calls-detail-box .missed-call svg path {
    fill: var(--error-text);
}

/* CHECKBOX */
.form-check-input {
    border: 1px solid var(--cancel-btn-color);
    width: 20px !important;
    height: 20px !important;
    padding: 0px !important;
    margin: 0px !important;
}

.form-check-input:checked {
    background-color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}

.form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgba(97, 75, 152, 0.3) !important;
}

/* COMMON CSS FOR CALL HISTORY :: ENDS */

/* HEADER CSS :: STARTS */
header .navbar {
    background: var(--reverse-gradient-color);
    box-shadow: 0px 15px 94px -20px rgba(0, 0, 0, 0.1);
    padding: 5px 25px;
    height: 80px;
    position: sticky;
    top: 0;
    z-index: 1070;
}

.navbar-brand {
    display: block;
    padding: 0px !important;
}

header .navbar .navbar-brand svg {
    display: block;
    width: auto;
    height: 50px;
}

header .navbar .navbar-brand .logo-header {
    display: block;
    width: auto;
    height: 50px;
}

header .nav-item {
    border-radius: 10px;
    margin: 0px 30px;
}

header .nav-item:hover {
    background-color: var(--navbar-hover-color);
}

header .profile-info {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

header .green-dot {
    position: absolute;
    left: 40px;
    top: 0px;
}

header .dropdown-menu.show {
    width: 100%;
}

header .dropdown-item {
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px 15px !important;
}

header .dropdown-toggle::after {
    content: unset !important;
}

header .angle-down {
    width: 13px;
    height: auto;
    margin-left: 15px;
}

header .angle-down path {
    fill: var(--white-color);
}

header .profile {
    margin-right: 80px;
}

header .profile-info p {
    color: var(--white-color);
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

header .profile-info img {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: var(--white-color);
}

header .navbar-nav .nav-item a svg {
    height: 30px;
    width: auto;
}

header .profile-info p span {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

/* TOGGLE BUTTON */
.navbar-light .navbar-toggler {
    border: none;
}

.navbar-light .navbar-toggler i {
    color: var(--white-color);
}

/* TOGGLE BUTTON */
/* HEADER CSS :: ENDS */

/* CALLS LEFT MENU CSS :: STARTS */
.left-menu-content {
    height: calc(100vh - 80px);
    background-color: var(--light-background);
}

.left-menu-content .accordion {
    padding: 20px !important;
}

.left-menu-content .accordion li {
    border: none !important;
    background-color: transparent !important;
}

.left-menu-content .accordion li a .count {
    height: 20px;
    width: 20px;
    background: red;
    border-radius: 50%;
    color: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    margin-top: 4px;
    margin-left: 15px;
}

.left-menu-content .accordion .accordion-header {
    padding: 0 !important;
}



.left-menu-content .accordion .accordion-header button {
    font-size: 20px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color) !important;
    text-decoration: none;
    background: var(--light-background) !important;
    padding: 20px 0px;
    box-shadow: none !important;
}

.left-menu-content .accordion .accordion-header button svg {
    width: 25px;
    height: auto;
    margin-right: 15px;
}

.left-menu-content .accordion-body {
    background: var(--light-background) !important;
    padding: 0px !important;
}

.left-menu-content .accordion .recent-calls {
    max-height: 450px;
    overflow-y: scroll;
    padding-right: 10px !important;
}

.left-menu-content .accordion-item {
    border: none !important;
}

.left-menu-content .accordion .accordion-body button {
    width: 100%;
    border-radius: var(--common-radius);
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    background-color: transparent;
}

.left-menu-content .accordion .accordion-item:not(:last-child) {
    border-bottom: 1px solid var(--hover-stack-dark) !important;
}

.left-menu-content .accordion .accordion-item .accordion-body button:hover {
    border: 1px solid var(--primary-color) !important;
}

.left-menu-content .accordion-button::after {
    transform: rotate(270deg);
    /* background-image: url(../img/angle-down.svg) !important; */
    background-image: url(../img/angle-down.svg) !important;
    transform-origin: unset;
    background-size: unset;
    transform-origin: center;
    height: 6px;
    width: 12px;
}

.left-menu-content .accordion-button:not(.collapsed)::after {
    transform: rotate(359deg) !important;
    background-image: url(../img/angle-down.svg) !important;
}

.left-menu-content ul li a {
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color) !important;
    text-decoration: none;
    background-color: transparent !important;
    padding: 20px 0px;
    box-shadow: none !important;
}

.left-menu-content ul li .search {
    right: 25px;
}

.left-menu-content ul li .search svg {
    height: 15px;
    width: 15px;
    display: block;
}

.left-menu-content ul li a svg {
    width: 25px;
    height: auto;
    margin-right: 15px;
}

.left-menu-content .accordion li .accordion-body button {
    width: 100%;
    border-radius: var(--common-radius);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    background-color: transparent;
}

.left-menu-content .accordion li .accordion-body button:hover {
    border: 1px solid var(--primary-color);
}

/* CALLS LEFT MENU CSS :: ENDS */

/* LOGIN PAGE CSS :: STARTS */
.login-content {
    padding: 36px 0px;
    height: 100vh;
}

.back {
    color: var(--dark-grey);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 50px;
    cursor: pointer;
}

.back svg {
    margin-right: 10px;
    height: 20px;
    width: auto;
}

.login-content .login-left-section {
    /* margin-left: 150px; */
    position: relative;
    height: calc(100vh - 50px);
}

.login-content .forget-password {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-grey);
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

.login-content .forget-password a {
    color: var(--primary-color)
}

.login-content .login-right-section {
    height: calc(100vh - 35px);
    border-radius: 160px 0px 0px 0px;
    padding: 92px 110px;
    background-image: url(../img/curves.svg), var(--main-gradient-color);
    background-repeat: no-repeat;
    background-position: 100% 60%;
    background-size: contain;
    position: relative;
}

.login-content .login-right-section h3:first-child {
    font-style: normal;
    font-weight: 300;
    font-size: 43px;
    line-height: 64px;
    color: var(--white-color);
}

.login-content .login-right-section h3:nth-child(2) {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: var(--white-color);
}

.login-content .login-right-section .device-img {
    width: 100%;
}

.login-content .login-right-section .laptop-img {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 85%;
}

.login-content .version-number {
    position: absolute;
    bottom: 20px;
    right: 80px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
    margin-bottom: 0px;
}

/* LOGIN PAGE CSS :: ENDS */

/* LOGIN WITH OTP PAGE :: STARTS */
.login-with-otp-content {
    padding: 36px 0px;
    height: 100vh;
}

.login-with-otp-content .login-left-section {
    margin-left: 150px;
    position: relative;
    height: calc(100vh - 50px);
}

.login-with-otp-content .login-left-section .right-line::before {
    content: "";
    background-image: url(../img/line.svg);
    position: absolute;
    height: 85%;
    width: 100%;
    left: 0;
    background-repeat: no-repeat;
    top: 9%;
    bottom: 5%;
    background-position: 100% 100%;
    background-size: contain;
}

.login-with-otp-content .login-left-section .dropdown {
    position: relative;
}

.login-with-otp-content .login-left-section .dropdown__skeleton {
    position: relative;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-with-otp-content .login-left-section .dropdown__skeleton .dropdown__arrow {
    margin-left: 10px;
    transition: all 0.2s ease-in-out;
}

.login-with-otp-content .login-left-section .dropdown__skeleton .dropdown__arrow svg {
    width: 10px;
}

.login-with-otp-content .login-left-section .dropdown__option img {
    width: 25px;
    margin-right: 10px;
}

.login-with-otp-content .login-left-section .dropdown__option span {
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
}

.login-with-otp-content .login-left-section .dropdown__options {
    position: absolute;
    top: 48px;
    left: 0px;
    right: 0;
    border: 1px solid var(--input-border-color);
    border-radius: 5px;
    background-color: var(--white-color);
    width: 100px;
    opacity: 0;
    z-index: 1;
}

.login-with-otp-content .login-left-section .dropdown__options--visible .dropdown__options {
    opacity: 1 !important;
}

.login-with-otp-content .login-left-section .dropdown__options--visible .dropdown__arrow {
    transform: rotate(-180deg);
}

.login-with-otp-content .login-left-section.dropdown__option {
    display: flex;
    align-items: center;
}

.login-with-otp-content .login-left-section .dropdown__options .dropdown__option {
    padding: 5px 12px;
    text-align: left;
    cursor: pointer;
}

.login-with-otp-content .login-left-section .dropdown__options .dropdown__option:not(:last-child) {
    margin-bottom: 15px;
}

.login-with-otp-content .login-left-section .dropdown__options .dropdown__option:hover {
    background-color: var(--grey-color);
}

.login-with-otp-content .login-right-section {
    height: calc(100vh - 35px);
    border-radius: 160px 0px 0px 0px;
    padding: 92px 110px;
    background-image: url(../img/curves.svg), var(--main-gradient-color);
    background-repeat: no-repeat;
    background-position: 100% 90%;
    background-size: contain;
    position: relative;
}

.login-with-otp-content .login-left-section .country-code li img {
    width: 20px;
    height: auto;
}

.login-with-otp-content .login-right-section h3:first-child {
    font-style: normal;
    font-weight: 300;
    font-size: 43px;
    line-height: 64px;
    color: var(--white-color);
}

.login-with-otp-content .login-right-section h3:nth-child(2) {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: var(--white-color);
}

.login-with-otp-content .login-right-section .sub-text {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 42px;
    color: var(--white-color);
    width: 80%;
    margin-top: 30px;
}

.login-with-otp-content .login-right-section .device-img {
    width: 100%;
}

.login-with-otp-content .login-right-section .laptop-img {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 85%;
}

.login-with-otp-content .version-number {
    position: absolute;
    bottom: 20px;
    right: 80px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
    margin-bottom: 0px;
}

/* LOGIN WITH OTP PAGE :: ENDS */

/* OTP VERIFICATION PAGE :: STARTS */
.otp-verification-content {
    padding: 36px 0px;
    height: 100vh;
}

.otp-verification-content .login-left-section {
    margin-left: 150px;
    position: relative;
    height: 100vh;
}

.otp-verification-content .login-right-section {
    height: calc(100vh - 35px);
    border-radius: 160px 0px 0px 0px;
    padding: 92px 110px;
    background-image: url(../img/curves.svg), var(--main-gradient-color);
    background-repeat: no-repeat;
    background-position: 100% 90%;
    background-size: contain;
    position: relative;
}

.otp-verification-content .time {
    display: block;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: var(--link-color);
    margin-bottom: 0rem;
}

.otp-verification-content .otp-box {
    margin-top: 30px;
    margin-bottom: 30px;
}

.otp-verification-content .otp-box input {
    border-radius: var(--common-radius);
    border: 1px solid var(--input-border-color) !important;
    height: 75px;
    width: 23%;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
}

.otp-verification-content .login-right-section h3:first-child {
    font-style: normal;
    font-weight: 300;
    font-size: 43px;
    line-height: 64px;
    color: var(--white-color);
}

.otp-verification-content .login-right-section h3:nth-child(2) {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: var(--white-color);
}

.otp-verification-content .login-right-section .sub-text {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 42px;
    color: var(--white-color);
    width: 80%;
    margin-top: 30px;
}

.otp-verification-content .login-right-section .device-img {
    width: 100%;
}

.otp-verification-content .login-right-section .laptop-img {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 85%;
}

.otp-verification-content .version-number {
    position: absolute;
    bottom: 20px;
    right: 80px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
    margin-bottom: 0px;
}

.otp-verification-content .resend-otp {
    /* position: absolute; */
    bottom: 10px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: var(--grey-color);
    width: 100%;
}

.otp-verification-content .resend-otp span {
    color: var(--link-color);
}

.otp-verification-content .resend-otp span a {
    color: var(--primary-color);
    text-decoration: none;
}

/* OTP VERIFICATION PAGE :: ENDS */

/* LOGIN WITH QR PAGE :: STARTS */
.login-with-qr-content {
    padding: 36px 0px;
    height: 100vh;
}

.login-with-qr-content .login-left-section {
    margin-left: 150px;
    position: relative;
    height: calc(100vh - 50px);
}

.login-with-qr-content .login-right-section {
    height: calc(100vh - 35px);
    border-radius: 160px 0px 0px 0px;
    padding: 92px 110px;
    background-image: url(../img/curves.svg), var(--main-gradient-color);
    background-repeat: no-repeat;
    background-position: 100% 90%;
    background-size: contain;
    position: relative;
}

.login-with-qr-content .time {
    display: block;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: var(--link-color);
}

.login-with-qr-content .otp-box {
    margin-top: 30px;
    margin-bottom: 40px;
}

.login-with-qr-content .otp-box input {
    border-radius: var(--common-radius);
    border: 1px solid var(--input-border-color) !important;
    height: 75px;
    width: 23%;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
}

.login-with-qr-content .login-right-section h3:first-child {
    font-style: normal;
    font-weight: 300;
    font-size: 43px;
    line-height: 64px;
    color: var(--white-color);
}

.login-with-qr-content .login-right-section h3:nth-child(2) {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: var(--white-color);
}

.login-with-qr-content .login-right-section .sub-text {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 42px;
    color: var(--white-color);
    margin-top: 30px;
}

.login-with-qr-content .login-right-section .device-img {
    width: 100%;
}

.login-with-qr-content .login-right-section .laptop-img {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 83%;
}

.login-with-qr-content .version-number {
    position: absolute;
    bottom: 20px;
    right: 80px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
    margin-bottom: 0px;
}

.login-with-qr-content .resend-otp {
    position: absolute;
    bottom: 10px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: var(--grey-color);
    width: 100%;
}

.login-with-qr-content .resend-otp span {
    color: var(--link-color);
}

#qr-scan-upload,
#profile-pic-upload {
    position: absolute;
    z-index: 0;
    width: 100%;
    left: 0;
    opacity: 0;
}

.custom-file-input {
    width: 100% !important;
    display: inline-block;
    background-color: var(--primary-color);
    color: var(--white-color);
    border-radius: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 58px;
    position: relative;
}

/* LOGIN WITH QR PAGE :: ENDS */

/* QR CODE PAGE CSS :: STARTS */
.qr-code-content {
    padding: 36px 0px;
    height: 100vh;
}

.qr-code-content .login-left-section {
    margin-left: 150px;
    position: relative;
    margin-top: 100px;
}

.qr-code-content .login-left-section .qr-box {
    border: 5px solid var(--link-color);
    display: flex;
    margin: 25px auto;
    position: relative;
    width: 100%;
    border-radius: 45px;
    background-color: var(--white-color);
    height: 400px;
}

div#qr-box {
    display: flex;
    align-items: center;
    height: 100%;
}

.qr-code-content .login-left-section .qr-box img {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    z-index: 5;
    width: 150px;
    height: 150px;
    margin: auto;
    display: block;
}

.qr-code-content .login-left-section .qr-box::before,
.qr-code-content .login-left-section .qr-box::after {
    content: '';
    position: absolute;
    background: var(--white-color);
}

.qr-code-content .login-left-section .qr-box::before {
    width: 80%;
    height: 110%;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.qr-code-content .login-left-section .qr-box::after {
    height: 80%;
    width: 110%;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.qr-code-content .login-right-section {
    height: calc(100vh - 35px);
    border-radius: 160px 0px 0px 0px;
    padding: 92px 110px;
    background-image: url(../img/curves.svg), var(--main-gradient-color);
    background-repeat: no-repeat;
    background-position: 100% 90%;
    background-size: contain;
    position: relative;
}

.qr-code-content .right-side-logo {
    margin-top: 100px;
}

.qr-code-content .right-side-logo img {
    margin: auto;
    display: block;
    width: 240px;
    height: auto;
    margin-bottom: 20px;
}

.qr-code-content .vectors {
    display: block;
    margin: auto;
    margin-top: 80px;
    width: 90%;
    position: absolute;
    bottom: 60px;
}

.qr-code-content .version-number {
    font-style: normal;
    font-weight: 400;
    font-size: 26.9504px;
    line-height: 40px;
    color: var(--white-color);
    margin-top: 15px;
    text-align: center;
}

#video-container {
    width: 100%;
    height: 100%;
    z-index: 99;
}

#video-container video {
    border-radius: 45px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.qr-code-content .right-vector {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.qr-code-content .right-vector img {
    width: 65%;
    bottom: 100px;
    position: absolute;
}

/* QR CODE PAGE CSS :: ENDS */


/* CHECK MAIL PAGE CSS :: STARTS */
.check-mail-content {
    padding: 36px 0px;
    height: 100vh;
}

.check-mail-content .login-left-section {
    margin-left: 150px;
    position: relative;
    /* margin-top: 100px; */
}

.check-mail-content .login-left-section .heading {
    margin-top: 150px
}

.check-mail-content .login-right-section {
    height: calc(100vh - 35px);
    border-radius: 160px 0px 0px 0px;
    padding: 92px 110px;
    background-image: url(../img/curves.svg), var(--main-gradient-color);
    background-repeat: no-repeat;
    background-position: 100% 90%;
    background-size: contain;
    position: relative;
}

.check-mail-content .right-side-logo {
    margin-top: 200px;
}

.check-mail-content .right-side-logo img {
    margin: auto;
    display: block;
}

.check-mail-content .vectors {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check-mail-content .vectors img {
    width: 60%;
    bottom: 100px;
    position: absolute;
}

.check-mail-content .version-number {
    font-style: normal;
    font-weight: 700;
    font-size: 26.9504px;
    line-height: 40px;
    color: var(--white-color);
    margin-top: 15px;
    text-align: center;
}

/* CHECK MAIL PAGE CSS :: ENDS */

/* SIP CONFICURATION PAGE CSS :: STARTS */
.sip-configuration-container {
    padding: 36px 70px !important;
    height: 100vh;
    overflow-y: scroll;
    width: 100vw;
    overflow-x: hidden !important;
}

.sip-configuration-container::before {
    content: '';
    background-image: url(../img/curves-2.svg);
    position: absolute;
    width: 45%;
    height: 100%;
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    right: 0;
    top: 0;
}

.sip-configuration-content .login-left-section {
    position: relative;
}

.sip-configuration-content .login-left-section .toggle-box {
    display: flex;
    align-items: center;
    margin: 30px auto;
}

.sip-configuration-content .login-left-section .toggle-box span:first-child {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    width: 250px;
    color: var(--black-color);
}

/* TOGGLE BUTTON */
.sip-configuration-content .login-left-section .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.sip-configuration-content .login-left-section .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.sip-configuration-content .login-left-section .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--cancel-btn-color);
    -webkit-transition: .4s;
    transition: .4s;
}

.sip-configuration-content .login-left-section .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--white-color);
    -webkit-transition: .4s;
    transition: .4s;
}

.sip-configuration-content .login-left-section input:checked+.slider {
    background: var(--main-gradient-color);
}

.sip-configuration-content .login-left-section input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.sip-configuration-content .login-left-section .slider.round {
    border-radius: 34px;
}

.sip-configuration-content .login-left-section .slider.round:before {
    border-radius: 50%;
}

/* TOGGLE BUTTON */

.sip-configuration-content .login-left-section .radio-box {
    margin: 30px 0px;
    display: flex;
    align-items: center;
}

.sip-configuration-content .login-left-section .radio-box span:first-child {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    width: 250px;
    color: var(--black-color);
}

.sip-configuration-content .login-left-section .radio-box label {
    text-transform: uppercase;
}

.sip-configuration-img img {
    width: 100%;
    margin-top: 250px;
    position: relative;
}

.sip-configuration-footer {
    padding: 30px 0px 10px 0px;
}

.sip-configuration-footer div p {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
    margin-bottom: 0px;
}

.sip-configuration-footer div p:nth-child(2) {
    float: right;
}

/* SIP CONFICURATION PAGE CSS :: ENDS */

/* SPLASH SCREEN PAGE CSS :: STARTS */
.splash-screen {
    height: 100vh;
    width: 100vw;
    padding: 50px 0px;
    margin: 0px;
    background-image: url(../app-icon/icon/background.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.splash-screen .splash-screen-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-image: url(../img/background-Vectors.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.splash-screen .splash-screen-content .logo {
    width: 300px;
    height: auto;
    margin-bottom: 0px;
}

.splash-screen .loader {
    position: absolute;
    bottom: 300px;
    color: var(--white-color);
}

/* SPLASH SCREEN PAGE CSS :: ENDS */

/* SSO LOGIN PAGE CSS :: STARTS */
.sso-login {
    padding: 36px 0px;
    height: 100vh;
}

.sso-login .login-left-section {
    margin-left: 150px;
    position: relative;
    margin-top: 100px;
}

.sso-login .login-right-section {
    height: 100vh;
    border-radius: 160px 0px 0px 0px;
    padding: 92px 110px;
    background-image: url(../img/curves.svg), var(--main-gradient-color);
    background-repeat: no-repeat;
    background-position: 100% 90%;
    background-size: contain;
    position: relative;
}

.sso-login .right-side-logo {
    margin-top: 100px;
}

.sso-login .right-side-logo img {
    margin: auto;
    display: block;
    width: 240px;
    margin-bottom: 20px;
    height: auto;
}

.sso-login .version-number {
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 40px;
    color: var(--white-color);
    margin-top: 15px;
    text-align: center;
}

/* SSO LOGIN PAGE CSS :: ENDS */

/* SSO LOGIN PAGE 2 CSS :: STARTS */
.sso-login-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.sso-login-content .login-with {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.sso-login-content .login-with p:first-child {
    color: var(--black-color);
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    margin-bottom: 40px;
}

.sso-login-content .login-with .logo-text {
    color: var(--black-color);
    font-size: 19px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    margin-top: 5px;
    margin-bottom: 0px;
}

.sso-login-content .login-with img {
    width: 50px;
    height: auto;
}

/* SSO LOGIN PAGE 2 CSS :: ENDS */

/* SSO LOGIN PASSWORD PAGE CSS :: STARTS */
.login-with-password {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

/* SSO LOGIN PASSWORD PAGE CSS :: ENDS */

/* SSO LOGIN WITH OTHER PLATFORMS CSS :: STARTS */
.login-with-other-plattforms {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.login-with-other-plattforms .login-with {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.login-with-other-plattforms .login-with p:first-child {
    color: var(--black-color);
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    margin-bottom: 40px;
}

.login-with-other-plattforms .login-with .media-icons {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    margin: 50px auto;
    width: 100%;
}

.login-with-other-plattforms .login-with .media-icons p {
    font-size: 19px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--black-color);
}

.login-with-other-plattforms .login-with .media-icons img {
    display: block;
    margin: auto;
    margin-bottom: 10px;
}

.login-with-other-plattforms .login-with .logo-text {
    color: var(--black-color);
    font-size: 19px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    margin-top: 5px;
    margin-bottom: 0px;
}

.login-with-other-plattforms .login-with img {
    width: 50px;
    height: auto;

}

/* SSO LOGIN WITH OTHER PLATFORMS CSS :: ENDS */

/* GETTING STARTED PAGE CSS :: STARTS */
.dashboard-container .right-side-content {
    background-color: var(--white-color);
    margin-top: 10px;
    overflow-y: scroll;
    border-radius: 20px 0px 0px 0px;
    height: 100vh;
}

.dashboard-container .left-menu-content .accordion li:not(:last-child) {
    border-bottom: 1px solid var(--hover-stack-dark) !important;
}

.dashboard-container .right-side-content img {
    width: 100%;
    height: auto;
}

.dashboard-container .right-side-content h3 {
    margin-top: 40px;
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--black-color);
}

.dashboard-container .right-side-content p {
    color: var(--black-color);
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
}

.dashboard-container .right-side-content .button-container {
    justify-content: center;
    margin: 30px auto;
}

/* GETTING STARTED PAGE CSS :: ENDS */

/* CALL HISTORY PAGE CSS :: STARTS */
.call-history-container .nav-pills .nav-link {
    color: var(--black-color) !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0px 5px 15px;
}

.call-history-container .nav-pills .nav-link:hover {
    border: unset !important;
}

.call-history-container .nav-pills .nav-link.active {
    background-color: var(--hover-stack-dark) !important;
}

.call-history-container .count {
    position: absolute;
    right: 0px;
    background: red;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    color: var(--white-color);
    text-align: center;
    padding: 0px;
    font-size: 14PX;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-side-content {
    background-color: var(--white-color);
    margin-top: 10px;
    height: 100vh;
    border-radius: 20px 0px 0px 0px;
    overflow-y: scroll;
}

.right-side-content .call-header {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 99 !important;
    background: var(--white-color);
    width: 100%;
    border-bottom: 1px solid var(--divider-border);
}


.right-side-content .call-header h3 {
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
    margin-bottom: 0px;
}

.right-side-content .call-header span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.right-side-content .search-icon {
    position: absolute;
    left: 10px;
}

.right-side-content .dots {
    margin: auto 25px;
}

.right-side-content .dropdown-toggle::after {
    content: unset !important;
}

.right-side-content .clear-history-dropdown {
    padding: 15px 10px !important;
    background: var(--white-color);
    box-shadow: -20px 20px 35px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border: unset !important;
    right: 25px;
}

.right-side-content .clear-history-dropdown li {
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: center;
}

.call-history-container .no-calls {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
}

.call-history-container .no-calls p {
    color: var(--black-color);
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    margin-top: 20px;
}

.no-missed-calls {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
}

.no-missed-calls p {
    color: var(--black-color);
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    margin-top: 20px;
}

.missedcallcounter {
    margin-bottom: 15px;
}


/* CALL HISTORY PAGE CSS :: ENDS */

/* CALL CONNECTING MINIMIZE PAGE CSS :: STARTS */

.call-connecting-minimize-container .left-menu-content .recent-calls div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .pin-chat {
    right: -15px;
    top: -2px;
}

.userCharacterUserInfo {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    color: var(--white-color);
    font-size: 25px;
    font-weight: 600;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.userCharacterUserInfo img {
    height: 70px !important;
    width: 70px !important;
    border-radius: 50%;
}

.call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .userCharacter {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: var(--white-color);
    font-size: 25px;
    font-weight: 600;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info span {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
    display: block;
}

.call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
}


.call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .caller-name {
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--dark-grey);
    position: relative;
    width: 80px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

.call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .green-dot,
.call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .red-dot,
.call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .orange-dot,
.call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .yellow-dot,
.call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .grey-dot {
    position: absolute;
    left: 48px;
    top: 2px;
}

.call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-date {
    color: var(--dark-grey);
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: right;
    display: block;
}

.call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-date .count {
    text-align: right;
    height: 20px;
    width: 20px;
    background: red;
    border-radius: 50%;
    float: right;
    color: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    margin-top: 4px;
}

.call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-date .read-msg {
    text-align: right;
}

.call-connecting-minimize-container .left-menu-content .recent-calls .divider {
    border: 1px solid var(--secondary-color);
    opacity: 0.1;
}

.call-connecting-minimize-container .right-side-content {
    background-color: var(--white-color);
    margin-top: 10px;
    border-radius: 20px 0px 0px 0px;
    height: 100vh;
    overflow-y: scroll;
}

.call-connecting-minimize-container .right-side-content .top-banner img {
    width: 100%;
    border-radius: 20px 0px 0px 0px;
}

.call-connecting-minimize-container .right-side-content .welcome-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
}

.call-connecting-minimize-container .right-side-content .welcome-text div h3 {
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
}

.call-connecting-minimize-container .right-side-content .welcome-text div p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    margin-bottom: 0px;
}

.call-connecting-minimize-container .right-side-content .welcome-text button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 70px;
    background-color: var(--primary-color);
    border-radius: var(--common-radius);
    border: none;
    outline: none;
    color: var(--white-color);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
}

.call-connecting-minimize-container .right-side-content .welcome-text button svg {
    height: 20px;
    margin-top: 2px;
}

.call-connecting-minimize-container .right-side-content .welcome-text button svg path {
    stroke: none;
    fill: var(--white-color);
}

.call-connecting-minimize-container .right-side-content .calls-history {
    width: 100%;
    border-top: 1px solid var(--divider-border);
}

/* MINIMIZE CALL POPUP */
.call-connecting-minimize-container .minmize-call {
    position: absolute;
    height: 100%;
    right: 8%;
    top: 0;
    box-shadow: var(--modal-box-shadow);
    border-radius: 50px;
    background-color: var(--minimize-popup-background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.call-connecting-minimize-container .minmize-call div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.call-connecting-minimize-container .minmize-call .connecting-icon {
    background-color: var(--popup-icon-background);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.call-connecting-minimize-container .minmize-call p {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    color: var(--white-color);
    padding-right: 30px;
}

.call-connecting-minimize-container .minmize-call p span {
    display: block;
}

.call-connecting-minimize-container .minmize-call p span:last-child {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.call-connecting-minimize-container .minmize-call button {
    width: 45px;
    height: 45px;
    background-color: var(--call-end-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
}

.call-connecting-minimize-container .minmize-call button svg {
    width: 30px;
}

/* CALL CONNECTING MINIMIZE PAGE CSS :: ENDS */

/* MAKE A CALL PAGE CSS :: STARTS */
.keypad-div {
    margin: auto;
    display: block;
}

.dialpad-modal .btn-close {
    background: unset !important;
    margin-bottom: 0px !important;
    opacity: 1 !important;
    position: absolute;
    top: 18px;
    right: 20px;
}

.dialpad-modal .btn-close svg {
    position: absolute;
    width: 15px;
    display: block;
}

.dialpad-modal .modal-content {
    padding: 0px !important;
    border-radius: 25px !important;
    margin-top: 50px;
}

.dialpad-modal .modal-body {
    padding: 20px 0px 30px;
}

.dialpad-modal .modal-title {
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.dialpad-modal .modal-header {
    background: var(--main-gradient-color);
    border-radius: 25px 25px 0px 0px !important;
    color: var(--white-color) !important;
    display: block;
    padding-bottom: 15px;
}

.dialpad-modal .modal-header input[type="text"] {
    width: 80%;
    height: 35px;
    background: transparent;
    outline: none;
    border: unset;
    text-align: center;
    font-size: 36px;
    font-weight: 300;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 10px;
    width: 80%;
    color: var(--white-color);
}

.dialpad-modal .add-to-contact {
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0px;
}

.dialpad-modal .digit {
    cursor: pointer;
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    color: var(--dark-grey);
    letter-spacing: 0em;
    background-color: var(--digit-background);
    margin: auto !important;
    color: var(--dark-grey);
    font-size: 35px;
    font-weight: 300;
    letter-spacing: 0em;

}

.dialpad-modal .sub {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.19em;
    color: var(--black-color);
    margin-top: -7px;
}

.dialpad-modal .last_digit {
    font-size: 55px;
    font-weight: 300;
}

.dialpad-modal .last_hash {
    font-size: 40px;
    font-weight: 300;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
}

.dialpad-modal .video-call-button {
    background: var(--secondary-color)
}

.dialpad-modal .call-button,
.dialpad-modal .video-call-button,
.dialpad-modal .back-button {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.dialpad-modal .call-button {
    background-color: var(--active-call-color);
}

.dialpad-modal .back-button {
    background-color: var(--digit-background);
}

.dialpad-modal .call-button svg,
.dialpad-modal .video-call-button svg,
.dialpad-modal .back-button svg {
    margin: unset;
}

/* MAKE A CALL PAGE CSS :: ENDS */

/* FULL SIZE CSS :: STARTS */
.full-size {
    height: 100vh !important;
    width: 100vw !important;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.full-size .modal-dialog.modal-dialog-centered {
    min-width: 100vw !important;
    min-height: 100vh !important;
    max-width: 100vw;
    height: 100vh !important;
    max-height: 100vh !important;
    align-items: start;
    margin: 0px !important;
}

.full-size .modal-dialog.modal-dialog-centered .modal-content {
    height: 100vh;
    border-radius: 0px 0px 0px 0px !important;
}

.full-size .modal-dialog.modal-dialog-centered .modal-content .modal-header {
    border-radius: 0px 0px 0px 0px !important;
}

/* FULL SIZE CSS :: ENDS */

/* CALL CONNECTING MODAL POPUP CSS :: STARTS */
.call-connecting-modal,
.modal {
    z-index: 9999 !important;
}

.fullscreen .modal-dialog {
    width: 100% !important;
    max-width: 100% !important;
}


.fullscreen .modal-content {
    margin-top: -29px !important;
    z-index: 9999 !important;
    height: 100vh;
}

.call-connecting-modal .modal-content {
    padding: 0px !important;
    border-radius: 25px !important;
    margin-top: 50px;
    z-index: 9999;
}

.call-connecting-modal .modal-title {
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.call-connecting-modal .modal-header {
    background: var(--main-gradient-color);
    border-radius: 25px 25px 0px 0px !important;
    color: var(--white-color) !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.call-connecting-modal .modal-header div {
    display: flex;
    align-items: baseline;
}

.call-connecting-modal .modal-header p {
    font-size: 36px;
    font-weight: 300;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0px;
}

.call-connecting-modal .modal-content svg {
    margin-bottom: 0px !important;
}

.call-connecting-modal .modal-body {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 20px;
}

.call-connecting-modal .modal-body h3 {
    color: var(--black-color);
    font-size: 37px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0em;
    margin-bottom: 10px;
}

.call-connecting-modal .modal-body img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
}

.call-connecting-modal .modal-body p {
    color: var(--dark-grey);
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    margin-bottom: 0px;
}

.call-connecting-modal .modal-body span {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    display: block;
    width: 100%;
}

.call-connecting-modal .modal-body button {
    margin-top: 120px;
    background-color: var(--call-end-color);
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: unset;
    outline: unset;
    margin-bottom: 50px;
}

/* CALL CONNECTING MODAL POPUP CSS :: ENDS */

/* CALL CONNECTING MAXIMIZE PAGE CSS :: STARTS */
.call-connecting-maximize-container {
    height: 100vh;
    background-color: var(--maximize-container-color);
}

.call-connecting-maximize-container .maximize-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--main-gradient-color);
    padding: 20px;
}

.call-connecting-maximize-container .maximize-container-header h5 {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
}

.call-connecting-maximize-container .call-connecting-info {
    display: flex;
    justify-self: center;
    align-items: center;
    flex-direction: column;
    margin: 100px;
}

.call-connecting-maximize-container .call-connecting-info h3 {
    color: var(--black-color);
    font-size: 37px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0em;
    margin-bottom: 10px;
}

.call-connecting-maximize-container .call-connecting-info img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
}

.call-connecting-maximize-container .call-connecting-info p {
    color: var(--dark-grey);
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    margin-top: 30px;
}

.call-connecting-maximize-container .call-connecting-info span {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    display: block;
    width: 100%;
    text-align: center;
}

.call-connecting-maximize-container .call-connecting-info button {
    margin-top: 120px;
    background-color: var(--call-end-color);
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: unset;
    outline: unset;
    position: absolute;
    bottom: 30px;
}

.call-connecting-maximize-container .call-connecting-info button svg {
    width: 70%;
}

/* CALL CONNECTING MAXIMIZE PAGE CSS :: ENDS */

/* INCOMING CALL PAGE CSS :: STARTS  */
.incoming-call-modal .modal-content {
    padding: 0px !important;
    border-radius: 25px !important;
    margin-top: 50px;
}

.incoming-call-modal .modal-title {
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.incoming-call-modal .modal-header {
    background: var(--main-gradient-color);
    border-radius: 25px 25px 0px 0px !important;
    color: var(--white-color) !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.incoming-call-modal .modal-header div {
    display: flex;
    align-items: baseline;
}

.incoming-call-modal .modal-header p {
    font-size: 36px;
    font-weight: 300;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0px;
}

.incoming-call-modal .modal-content svg {
    margin-bottom: 0px !important;
}

.incoming-call-modal .modal-body {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 20px;
}

.incoming-call-modal .modal-body h3 {
    color: var(--black-color);
    font-size: 37px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0em;
    margin-bottom: 10px;
}

.incoming-call-modal .modal-body img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
}

.incoming-call-modal .modal-body p {
    color: var(--dark-grey);
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    margin-bottom: 0px;
}

.incoming-call-modal .modal-body span {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    display: block;
    width: 100%;
}

.incoming-call-modal .modal-body button {
    margin-top: 120px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: unset;
    outline: unset;
    margin-bottom: 50px;
}

.incoming-call-modal .modal-body .call-end-btn {
    background-color: var(--call-end-color);
}

.incoming-call-modal .modal-body .accept-call-btn {
    background-color: var(--active-call-color);
}

/* INCOMING CALL PAGE CSS :: ENDS  */

/* INCOMING CALL MINIMIZE PAGE CSS :: STARTS */
.incoming-call-minimize-modal {
    position: absolute;
    height: 100%;
    right: 8%;
    top: 0;
    box-shadow: var(--modal-box-shadow);
    border-radius: 50px;
    background-color: var(--minimize-popup-background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.incoming-call-minimize-modal div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.incoming-call-minimize-modal .connecting-icon {
    background-color: var(--popup-icon-background);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.incoming-call-minimize-modal p {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    color: var(--white-color);
    padding-right: 30px;
}

.incoming-call-minimize-modal p span {
    display: block;
}

.incoming-call-minimize-modal p span:last-child {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.incoming-call-minimize-modal button {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
}

.incoming-call-minimize-modal .end-button {
    background-color: var(--call-end-color);
}

.incoming-call-minimize-modal .accept-button {
    background-color: var(--active-call-color);
}

.incoming-call-minimize-modal .accept-button svg {
    width: 22px;
}

.incoming-call-minimize-modal .end-button svg {
    width: 35px;
}

/* INCOMING CALL MINIMIZE PAGE CSS :: ENDS */

/* INCOMING CALL MAXIMIZE PAGE CSS :: STARTS */
.incoming-call-maximize-container {
    background-color: var(--maximize-container-color);
    height: 100vh;
}

.incoming-call-maximize-container .maximize-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--main-gradient-color);
    padding: 20px;
    height: 69px;
}

.incoming-call-maximize-container .maximize-container-header h5 {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
}

.incoming-call-maximize-container .call-connecting-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 100px;
}

.incoming-call-maximize-container .call-connecting-info h3 {
    color: var(--black-color);
    font-size: 37px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0em;
    margin-bottom: 10px;
}

.incoming-call-maximize-container .call-connecting-info img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
}

.incoming-call-maximize-container .call-connecting-info p {
    color: var(--dark-grey);
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    margin-top: 10px;
}

.incoming-call-maximize-container .call-connecting-info span {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    display: block;
    width: 100%;
    text-align: center;
}

.incoming-call-maximize-container .call-button-container {
    position: absolute;
    bottom: 20px;
}

.incoming-call-maximize-container .call-connecting-info button {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: unset;
    outline: unset;
}

.incoming-call-maximize-container .call-connecting-info .end-button {
    background-color: var(--call-end-color);
}

.incoming-call-maximize-container .call-connecting-info .accept-button {
    background-color: var(--active-call-color);
}

.incoming-call-maximize-container .call-connecting-info button svg {
    width: 70%;
}

/* INCOMING CALL MAXIMIZE PAGE CSS :: ENDS */

/* ACTIVE CALL PAGE CSS :: STARTS */
.active-call-container {
    height: 100vh;
    background-color: var(--maximize-container-color);
}

.active-call-container .call-buttons-option-container {
    position: absolute;
    bottom: 20px;
}

.active-call-transfer .maximize-container-header {
    height: 69px;
}

.active-call-container .call-time {
    width: fit-content !important;
    background: var(--active-call-color);
    padding: 10px !important;
    color: var(--white-color) !important;
    font-size: 29px !important;
    font-weight: 500 !important;
    border-radius: 10px;
}

.active-call-container .call-buttons-option-container button {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: unset;
    outline: unset;
    background-color: var(--common-btn-color);
}

/* ACTIVE CALL PAGE CSS :: ENDS */

/* INCOMING CALL WHILE AUDIO CALL IS GOING ON PAGE CSS :: STARTS */
.incoming-call-popup {
    position: absolute;
    height: 100%;
    right: 8%;
    top: 0;
    box-shadow: var(--modal-box-shadow);
    border-radius: 50px;
    background-color: var(--minimize-popup-background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.incoming-call-popup div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.incoming-call-popup .connecting-icon {
    background-color: var(--popup-icon-background);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.incoming-call-popup p {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    color: var(--white-color);
    padding-right: 30px;
}

.incoming-call-popup p span {
    display: block;
}

.incoming-call-popup p span:last-child {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.incoming-call-popup button {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
}

.incoming-call-popup .end-button {
    background-color: var(--call-end-color);
}

.incoming-call-popup .pause-button {
    background-color: var(--link-color);
}

.incoming-call-popup .accept-button svg {
    width: 22px;
}

.incoming-call-popup .end-button svg {
    width: 35px;
}

/* INCOMING CALL WHILE AUDIO CALL IS GOING ON PAGE CSS :: ENDS */

/* ACTIVE CALL TRANSFER PAGE CSS :: STARTS */
.active-call-transfer .dropdown-toggle::after {
    content: unset !important;
}

.active-call-transfer .dropdown-menu {
    top: -150px;
    transform: translate(0, 10px);
    padding: 15px 10px !important;
    background: var(--white-color);
    box-shadow: -20px 20px 35px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    width: max-content;
    right: -90px;
    border: unset;
}

.active-call-transfer .dropdown-menu:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    bottom: -15px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid var(--white-color);
    box-shadow: -20px 20px 35px rgba(0, 0, 0, 0.05);
    transition-duration: 0.3s;
    transition-property: transform;
}

.active-call-transfer .dropdown-menu li {
    padding: 5px 20px !important;
    margin-bottom: 10px;
    border-radius: 8px;
    color: var(--black-color);
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.active-call-transfer .dropdown-menu li:hover {
    background-color: var(--hover-stack-dark);
}

.active-call-transfer .dropdown-menu li svg {
    height: auto !important;
    width: 20px !important;
    margin-right: 10px;
}

.active-call-transfer .active-button svg path {
    fill: var(--primary-color);
}

/* ACTIVE CALL TRANSFER PAGE CSS :: ENDS */

/* BLIND TRANSFER BY NUMBER PAGE CSS :: STARTS */
.blind-transfer-call-modal {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 69px);
    background-color: rgba(0, 0, 0, 0.5);
    top: 69px;
}

.blind-transfer-call-modal .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: unset !important;
    margin-bottom: 0px !important;
    opacity: 1 !important;
}

.blind-transfer-call-modal .modal-content {
    padding: 0px !important;
    border-radius: 25px !important;
}

.blind-transfer-call-modal .modal-title {
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.blind-transfer-call-modal .modal-header {
    background: var(--main-gradient-color);
    border-radius: 25px 25px 0px 0px !important;
    color: var(--white-color) !important;
    display: block;
}

.blind-transfer-call-modal .modal-header input[type="text"] {
    width: 80%;
    height: 35px;
    background: transparent;
    outline: none;
    border: unset;
    text-align: center;
    font-size: 36px;
    font-weight: 300;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0px;
    width: 80%;
    color: var(--white-color);
}

.blind-transfer-call-modal .digit {
    cursor: pointer;
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    letter-spacing: 0em;
    background-color: var(--digit-background);
    margin: auto !important;
    color: var(--dark-grey);
    font-size: 35px;
    font-weight: 300;
    letter-spacing: 0em;
}

.blind-transfer-call-modal .sub {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.19em;
    color: var(--black-color);
    margin-top: -7px;
}

.blind-transfer-call-modal .last_digit {
    font-size: 55px;
    font-weight: 300;
}

.blind-transfer-call-modal .last_hash {
    font-size: 40px;
    font-weight: 300;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
}

.blind-transfer-call-modal .video-call-button {
    background: var(--secondary-color)
}

.blind-transfer-call-modal .call-button {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: var(--active-call-color);
}

.blind-transfer-call-modal .call-button svg {
    height: 50%;
    margin: 0 !important;
}

.active-call-transfer .blind-call-minimize {
    display: block;
    margin: auto;
    padding: 10px;
    background-color: var(--minimize-popup-background);
    box-shadow: var(--modal-box-shadow);
    border-radius: 50px;
    height: 100%;
}

.active-call-transfer .blind-call-minimize div {
    height: 100%;
}

.active-call-transfer .blind-call-minimize div .call-status {
    font-size: 19px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
}

.active-call-transfer .blind-call-minimize div .caller-name {
    font-size: 20px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
}

.active-call-transfer .blind-call-minimize div .connecting-icon {
    background-color: var(--popup-icon-background);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.active-call-transfer .blind-call-minimize div .end-button {
    background-color: var(--call-end-color);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
}

.active-call-transfer .blind-call-minimize div .end-button svg {
    width: 75%;
}

/* BLIND TRANSFER BY NUMBER PAGE CSS :: ENDS */

/* TRANSFERED CALL PAGE CSS :: STARTS */
.call-transfer-container .transfer-container-header {
    height: 69px;
    width: 100%;
    background: var(--main-gradient-color);
    padding: 20px;
}

.transfered-call-modal-popup {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 69px);
    background-color: rgba(0, 0, 0, 0.5);
    top: 69px;
}

.transfered-call-modal-popup .modal-header {
    background: var(--main-gradient-color);
    border-radius: 25px 25px 0px 0px !important;
    color: var(--white-color) !important;
    display: block;
}

.transfered-call-modal-popup .modal-title {
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.transfered-call-modal-popup .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: unset !important;
    margin-bottom: 0px !important;
    opacity: 1 !important;
}

.transfered-call-modal-popup .modal-body svg {
    display: block;
    margin: 20px auto;
}

.transfered-call-modal-popup .modal-body p {
    font-size: 27px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--dark-grey);
    margin: 30px auto;
}

.transfered-call-modal-popup .modal-body .number {
    margin: 30px auto;
    font-size: 25px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--dark-grey);
}

.transfered-call-modal-popup .modal-content {
    padding: 0px 0px !important;
}

/* TRANSFERED CALL PAGE CSS :: ENDS */

/* CONTAC SEARCH RESULTS DROPDOWN MENU :: STARTS */
.serach-by-name .modal-header input[type="text"] {
    text-align: left;
}

.contact-list-menu {
    width: 98%;
    left: 1%;
    right: 1%;
    top: 5px;
    background: var(--white-color);
    border: 1.39887px solid var(--common-btn-color) !important;
    box-shadow: -27.9773px 27.9773px 45px rgba(0, 0, 0, 0.1);
    border-radius: 30px !important;
    padding-right: 7px !important;
}

.contact-list-menu li:hover {
    background-color: var(--hover_states_light);
    border-radius: 8px;
}

.contact-list-menu span.caller-avtar {
    width: 65px;
    height: 65px;
    background-color: var(--primary-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
}

.recent-call-item .chat-list-profile span.chat-list-avtar {
    width: 65px;
    height: 65px;
    background-color: var(--primary-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
}

.contact-list-menu div p {
    font-size: 19px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
}

.contact-list-menu div span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.contact-list-menu .check-contact {
    position: absolute;
    right: 10px;
}

.contact-list-menu .check-contact .checkbox label {
    top: 0;
    left: 0;
}

/* CONTAC SEARCH RESULTS DROPDOWN MENU :: ENDS */

/* ALL CONTACTS PAGE CSS :: STARTS */
.all-contacts-container .nav-pills .nav-link {
    color: var(--dark-grey) !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0px 5px 15px;
    margin-bottom: 30px;
}

.all-contacts-container .nav-pills .nav-link svg {
    margin-right: 0px;
    height: 20px;
    display: block;
}

.all-contacts-container .nav-pills .nav-link:hover {
    border: unset !important;
}

.all-contacts-container .nav-pills .nav-link.active {
    background-color: var(--hover-stack-dark) !important;
}

.all-contacts-container .nav-pills .nav-link .contact-icon {
    position: absolute;
    right: 5px;
}

.all-contacts-container .right-side-content {
    background-color: var(--white-color);
    margin-top: 10px;
    height: 100vh;
    border-radius: 20px 0px 0px 0px;
    overflow-y: scroll;
}

.contact-container .call-header {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--white-color);
    position: sticky;
    top: 0;
    z-index: 99 !important;
    border-bottom: 1px solid var(--divider-border);
}

.contact-container .call-header h3 {
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
    margin-bottom: 0px;
}

.contact-container .call-header span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contact-container .call-header .search-icon {
    position: absolute;
    left: 10px;
}

.caller-details:hover .call-options {
    display: flex;
    gap: 10px;
}

.call-options {
    display: none;
}

.all-contacts-container .call-options span {
    width: 42px;
    height: 42px;
    display: block;
    background: var(--primary-color);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    --bs-gutter-x: 0rem;
}

.all-contacts-container .call-options span svg {
    width: 55%;
}

.all-contacts-container .contact-list {
    width: 95%;
}

.contact-list .caller-details {
    background-color: transparent;
    border: unset;
    justify-self: start;
    align-items: center;
    display: flex;
    padding: 10px 0px;
    margin: 10px;
}

.contact-list .sort-by-alphabet {
    padding: 10px 20px;
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.contact-list .divider {
    border-bottom: 1px solid var(--common-btn-color);
}

.contact-list .caller-details:hover {
    background-color: var(--hover_states_light);
    border-radius: 8px;
}

.contact-list .caller-details h6 {
    font-size: 21px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
}

.contact-list .caller-details p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
}

.contact-list span.person {
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-color);
    border-radius: 50%;
    color: var(--white-color);
    font-size: 31px;
    font-weight: 500;
    line-height: 47px;
    letter-spacing: 0em;
}

.contact-list span.person img {
    width: 100%;
}

/* ALL CONTACTS PAGE CSS :: ENDS */

/* SETTING - LOGOUT PAGE CSS :: STARTS */
.setting-conatiner .nav-pills .nav-link {
    color: var(--dark-grey) !important;
    font-size: 19px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0px 5px 15px;
    margin-bottom: 30px;
}

.setting-conatiner .nav-pills .nav-link svg {
    margin-right: 12px;
    width: 20px;
    height: auto;
    display: inline;
}

.setting-conatiner .nav-pills .nav-link:hover {
    border: unset !important;
}

.setting-conatiner .nav-pills .nav-link.active {
    background-color: var(--hover-stack-dark) !important;
}

.setting-conatiner .right-side-content {
    background-color: var(--white-color);
    margin-top: 10px;
    height: calc(100vh - 80px);
    border-radius: 20px 0px 0px 0px;
    overflow-y: scroll;
}

.logout-modal-popup {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
}

.logout-modal-popup .modal-content {
    padding: 0px !important;
}


.logout-modal-popup .modal-header {
    background: var(--main-gradient-color);
    border-radius: 25px 25px 0px 0px !important;
    color: var(--white-color) !important;
    display: block;
}

.logout-modal-popup .modal-title {
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.logout-modal-popup .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: unset !important;
    margin-bottom: 0px !important;
    opacity: 1 !important;
}

.logout-modal-popup .modal-body {
    padding: 25px;
}

.logout-modal-popup .modal-body svg {
    display: block;
    margin: 20px auto;
    width: 45px;
}

.logout-modal-popup .modal-body p {
    font-size: 27px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--dark-grey);
    margin: 30px auto;
}

.logout-modal-popup .modal-footer {
    margin: unset !important;
}

/* SETTING - LOGOUT PAGE CSS :: ENDS */

/* CONTACT DETAIL PAGE CSS :: STARTS */
.contact-detail-container .contact-list {
    padding: 20px;
}

.contact-detail-container .caller-details {
    background-color: var(--hover_states_light);
    border-radius: 8px;
    margin: 0px;
}

.contact-detail-container .call-option span {
    width: 40px;
    height: 40px;
    display: block;
    background: var(--primary-color);
    border-radius: 50%;
    margin: 0px 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    --bs-gutter-x: 0rem;
}

.contact-detail-container .call-option span svg {
    width: 22px;
}

.contact-detail-container .number-details label {
    color: var(--dark-grey);
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.contact-detail-container .number-details p {
    color: var(--black-color);
    font-size: 23px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
}

/* CONTACT DETAIL PAGE CSS :: ENDS */

/* SYNC CONATCT PAGE CSS :: STARTS */
.sync-contact-container .call-header {
    padding: 15px 20px;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    background-color: var(--white-color);
    position: sticky;
    top: 0;
    z-index: 99 !important;
    border-bottom: 1px solid var(--divider-border);
}

.sync-contact-container .call-header h3 {
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
    margin-bottom: 0px;
}

.sync-contact-container .call-header svg {
    margin-right: 20px;
}

.sync-contact-container .sync-details {
    background: var(--white-color);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    width: max-content;
    display: block;
    padding: 30px;
    margin: 50px auto 100px;
}

.sync-contact-container .sync-details img {
    width: 70%;
    margin: auto;
    display: block;
}

.sync-contact-container .sync-details h3 {
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
    margin: 35px 20px;
}

.sync-contact-container .sync-details .sync-with p {
    color: var(--black-color);
    font-size: 18px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}

.sync-contact-container .sync-details .sync-with span {
    color: var(--dark-grey);
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
}

.sync-contact-container .divider {
    opacity: 0.3;
}

/* TOGGLE BUTTON */
.sync-contact-container .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.sync-contact-container .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.sync-contact-container .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--cancel-btn-color);
    -webkit-transition: .4s;
    transition: .4s;
}

.sync-contact-container .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--white-color);
    -webkit-transition: .4s;
    transition: .4s;
}

.sync-contact-container input:checked+.slider {
    background: var(--secondary-color);
}

.sync-contact-container input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.sync-contact-container .slider.round {
    border-radius: 34px;
}

.sync-contact-container .slider.round:before {
    border-radius: 50%;
}

/* TOGGLE BUTTON */
/* SYNC CONATCT PAGE CSS :: ENDS */

/* FAVOURITE CONATCT PAGE CSS :: STARTS */
.favourite-container .call-header {
    padding: 15px 20px;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    background-color: var(--white-color);
    position: sticky;
    top: 0;
    z-index: 99 !important;
    border-bottom: 1px solid var(--divider-border);
}

.favourite-container .call-header h3 {
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
    margin-bottom: 0px;
}

.favourite-container .call-header span {
    color: var(--black-color);
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.select-box div {
    padding: 5px 15px 8px 10px;
    border-radius: 30px;
}

.favourite-container .call-header .select-box div:hover {
    background-color: var(--hover_states_light);
}

.favourite-container input[type="checkbox"] {
    position: absolute;
    right: 0px;
}

/* FAVOURITE CONATCT PAGE CSS :: ENDS */

/* ACTIVE ATTANDANT CALL TRANSFER PAGE CSS :: STARTS */
.active-call-attandant-transfer-container {
    background-color: var(--maximize-container-color);
    height: 100vh;
}

.active-call-attandant-transfer-container .active-call-attandant-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--main-gradient-color);
    padding: 20px;
    height: 80px;
}

.active-call-attandant-transfer-container .active-call-attandant-header h5 {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
}

.active-call-attandant-transfer-container .call-connecting-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 100px;
}

.active-call-attandant-transfer-container .call-connecting-info h3 {
    color: var(--black-color);
    font-size: 37px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0em;
    margin-bottom: 10px;
}

.active-call-attandant-transfer-container .call-connecting-info img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
}

.active-call-attandant-transfer-container .call-connecting-info p {
    color: var(--dark-grey);
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    margin-top: 10px;
    margin-bottom: 5px;
}

.active-call-attandant-transfer-container .call-connecting-info span {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    display: block;
    width: unset;
    text-align: center;
    display: inline !important;
}

.active-call-attandant-transfer-container .call-time {
    width: fit-content !important;
    background: var(--active-call-color);
    padding: 10px !important;
    color: var(--white-color) !important;
    font-size: 29px !important;
    font-weight: 500 !important;
    border-radius: 10px;
}

.active-call-attandant-transfer-container .call-buttons-option-container {
    position: absolute;
    bottom: 20px;
}

.active-call-attandant-transfer-container .call-buttons-option-container button {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: unset;
    outline: unset;
    background-color: var(--common-btn-color);
}

.active-call-attandant-transfer-container .call-buttons-option-container .dropdown-toggle::after {
    content: unset !important;
}

.active-call-attandant-transfer-container .call-connecting-info .end-button {
    background-color: var(--call-end-color);
}

.active-call-attandant-transfer-container .call-connecting-info .accept-button {
    background-color: var(--active-call-color);
}

.active-call-attandant-transfer-container .call-connecting-info button svg {
    width: 70%;
}

.active-call-attandant-transfer-container .dropdown-toggle::after {
    content: unset !important;
}

.active-call-attandant-transfer-container .dropdown-menu {
    top: -150px;
    transform: translate(0, 10px);
    padding: 15px 10px !important;
    background: var(--white-color);
    box-shadow: -20px 20px 35px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    width: max-content;
    right: -90px;
    border: unset;
}

.active-call-attandant-transfer-container .dropdown-menu:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    bottom: -15px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid var(--white-color);
    box-shadow: -20px 20px 35px rgba(0, 0, 0, 0.05);
    transition-duration: 0.3s;
    transition-property: transform;
}

.active-call-attandant-transfer-container .dropdown-menu li {
    padding: 5px 20px !important;
    margin-bottom: 10px;
    border-radius: 8px;
    color: var(--black-color);
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;

}

.active-call-attandant-transfer-container .dropdown-menu li:hover {
    background-color: var(--hover-stack-dark);
}

.active-call-attandant-transfer-container .dropdown-menu li svg {
    height: auto !important;
    width: 20px !important;
    margin-right: 10px;
}

/* ACTIVE ATTANDANT CALL TRANSFER PAGE CSS :: ENDS */

/* ATTANDANT TRANSFER MODAL POPUP CSS :: STARTS */
.attandant-transfer-modal {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 69px);
    background-color: rgba(0, 0, 0, 0.5);
    top: 69px;
}

.attandant-transfer-modal .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: unset !important;
    margin-bottom: 0px !important;
    opacity: 1 !important;
}

.attandant-transfer-modal .modal-content {
    padding: 0px !important;
    border-radius: 25px !important;
}

.attandant-transfer-modal .modal-title {
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.attandant-transfer-modal .modal-header {
    background: var(--main-gradient-color);
    border-radius: 25px 25px 0px 0px !important;
    color: var(--white-color) !important;
    display: block;
}

.attandant-transfer-modal .modal-header input[type="text"] {
    width: 80%;
    height: 35px;
    background: transparent;
    outline: none;
    border: unset;
    text-align: center;
    font-size: 36px;
    font-weight: 300;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0px;
    width: 80%;
    color: var(--white-color);
}

.attandant-transfer-modal .digit {
    cursor: pointer;
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    color: var(--dark-grey);
    letter-spacing: 0em;
    background-color: var(--digit-background);
    margin: auto !important;
    color: var(--dark-grey);
    font-size: 35px;
    font-weight: 300;
    letter-spacing: 0em;
}

.attandant-transfer-modal .sub {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.19em;
    color: var(--black-color);
    margin-top: -7px;
}

.attandant-transfer-modal .last_digit {
    font-size: 55px;
    font-weight: 300;
}

.attandant-transfer-modal .last_hash {
    font-size: 40px;
    font-weight: 300;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
}

.attandant-transfer-modal .video-call-button {
    background: var(--secondary-color)
}

.attandant-transfer-modal .call-button {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: var(--active-call-color);
}

.attandant-transfer-modal .call-button svg {
    height: 50%;
}

.active-call-attandant-transfer-container .attandant-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--main-gradient-color);
    height: 69px;
}

.active-call-attandant-transfer-container .attandant-minimize-call-popup {
    display: block;
    margin: auto;
    padding: 10px;
    background-color: var(--minimize-popup-background);
    box-shadow: var(--modal-box-shadow);
    border-radius: 50px;
    height: 100%;

}

.active-call-attandant-transfer-container .attandant-minimize-call-popup div {
    height: 100%;
}

.active-call-attandant-transfer-container .attandant-minimize-call-popup div .call-status {
    font-size: 19px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
}

.active-call-attandant-transfer-container .attandant-minimize-call-popup div .caller-name {
    font-size: 20px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
}

.active-call-attandant-transfer-container .attandant-minimize-call-popup div .connecting-icon {
    background-color: var(--popup-icon-background);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.active-call-attandant-transfer-container .attandant-minimize-call-popup div .end-button {
    background-color: var(--call-end-color);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
}

.active-call-attandant-transfer-container .attandant-minimize-call-popup div .end-button svg {
    width: 75%;
}

/* ATTANDANT TRANSFER MODAL POPUP CSS :: ENDS */

/* ATTANDANT TRANSFER CALL ON HOLD PAGE CSS :: STARTS */
.attandant-transfer-hold-container {
    background-color: var(--maximize-container-color);
}

.attandant-transfer-hold-container .attandant-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--main-gradient-color);
    padding: 20px !important;
    height: 69px;
}

.attandant-transfer-hold-container .attandant-header h5 {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
}

.attandant-transfer-hold-container .call-connecting-info {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 80px);
}

.attandant-transfer-hold-container .call-buttons-option-container {
    position: absolute !important;
    bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.attandant-transfer-hold-container .call-buttons-option-container button {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: unset;
    outline: unset;
    background-color: var(--common-btn-color);
}

.attandant-transfer-hold-container .call-connecting-info .end-button {
    background-color: var(--call-end-color);
}

.attandant-transfer-hold-container .call-connecting-info button svg {
    width: 70%;
}

.attandant-transfer-hold-container .call-info-box {
    padding: 50px 130px;
    border-radius: 25px;
    background-color: var(--primary-color);
}

.attandant-transfer-hold-container .call-info-box h3 {
    color: var(--white-color);
    font-size: 31px;
    font-weight: 500;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: center;
}

.attandant-transfer-hold-container .call-info-box p {
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--white-color);
}

.attandant-transfer-hold-container .call-info-box .person {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    margin-top: 20px;
    transition: .5s ease;
    backface-visibility: hidden;
}

.attandant-transfer-hold-container .hover-content .hover-img {
    transition: .5s ease;
    opacity: 1;
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.attandant-transfer-hold-container .hold-call .person img {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    opacity: 0.5;
}

.attandant-transfer-hold-container .call-info-box .person img {
    width: 100%;
}

.attandant-transfer-hold-container .call-info-box .call-status {
    background-color: var(--primary-color-darker);
    border-radius: 10px;
    top: 15px;
    right: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: var(--white-color);
    padding: 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.attandant-transfer-hold-container .call-info-box .speaker-icon {
    background-color: var(--primary-color-lighter);
    border-radius: 50%;
    top: 15px;
    right: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: var(--white-color);
    padding: 0px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
}

.attandant-transfer-hold-container .call-info-box .call-time {
    background-color: var(--primary-color-darker);
    border-radius: 10px;
    top: 15px;
    left: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: var(--white-color);
    padding: 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.attandant-transfer-hold-container .call-info-box .call-status svg {
    height: 13px;
    width: 13px;
}

/* ATTANDANT TRANSFER CALL ON HOLD PAGE CSS :: ENDS */

/* ONGOING CALL ON HOLD PAGE CSS :: STARTS */
.ongoing-call-hold-container {
    background-color: var(--maximize-container-color);
    height: 100vh;
}

.ongoing-call-hold-container .attandant-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--main-gradient-color);
    padding: 20px;
    height: 80px;
}

.ongoing-call-hold-container .attandant-header h5 {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
}

.ongoing-call-hold-container .call-connecting-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 80px);
}

.ongoing-call-hold-container .call-buttons-option-container {
    position: absolute;
    bottom: 20px;
}

.ongoing-call-hold-container .call-buttons-option-container button {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: unset;
    outline: unset;
    background-color: var(--common-btn-color);
}

.ongoing-call-hold-container .call-connecting-info .end-button {
    background-color: var(--call-end-color);
}

.ongoing-call-hold-container .call-connecting-info button svg {
    width: 70%;
}

.ongoing-call-hold-container .call-info-box {
    padding: 50px 130px;
    border-radius: 25px;
    background-color: var(--primary-color);
}

.ongoing-call-hold-container .call-info-box h3 {
    color: var(--white-color);
    font-size: 31px;
    font-weight: 500;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: center;
}

.ongoing-call-hold-container .call-info-box p {
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--white-color);
}

.ongoing-call-hold-container .call-info-box .person {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    margin-top: 20px;
    transition: .5s ease;
    backface-visibility: hidden;
}

.ongoing-call-hold-container .hold-call .hover-img {
    transition: .5s ease;
    opacity: 1;
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.attended-atx-onhold-user {
    transition: .5s ease;
    opacity: 1;
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.ongoing-call-hold-container .hold-call .person img {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    opacity: 0.5;
}

.ongoing-call-hold-container .call-info-box .person img {
    width: 100%;
}

.ongoing-call-hold-container .call-info-box .call-status {
    background-color: var(--primary-color-darker);
    border-radius: 10px;
    top: 15px;
    right: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: var(--white-color);
    padding: 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ongoing-call-hold-container .call-info-box .speaker-icon {
    background-color: var(--primary-color-lighter);
    border-radius: 50%;
    top: 15px;
    right: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: var(--white-color);
    padding: 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
}

.ongoing-call-hold-container .call-info-box .call-time {
    background-color: var(--primary-color-darker);
    border-radius: 10px;
    top: 15px;
    left: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: var(--white-color);
    padding: 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ongoing-call-hold-container .call-info-box .call-status svg {
    height: 13px;
    width: 13px;
}

/* ONGOING CALL ON HOLD PAGE CSS :: ENDS */

/* VIDEO CALL CONNECTING MODAL POPUP CSS :: STARTS */
.videocall-connecting-modal .modal-content {
    padding: 0px !important;
    border-radius: 25px !important;
}

.videocall-connecting-modal .modal-title {
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.videocall-connecting-modal .modal-header {
    background: var(--main-gradient-color);
    border-radius: 25px 25px 0px 0px !important;
    color: var(--white-color) !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.videocall-connecting-modal .modal-header div {
    display: flex;
    align-items: baseline;
}

.videocall-connecting-modal .modal-header p {
    font-size: 36px;
    font-weight: 300;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0px;
}

.videocall-connecting-modal .modal-content svg {
    margin-bottom: 0px !important;
}

.videocall-connecting-modal .modal-body {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 20px;
}

.videocall-connecting-modal .modal-body h3 {
    color: var(--black-color);
    font-size: 37px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0em;
    margin-bottom: 10px;
}

.videocall-connecting-modal .modal-body img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
}

.videocall-connecting-modal .modal-body p {
    color: var(--dark-grey);
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    margin-bottom: 0px;
}

.videocall-connecting-modal .modal-body span {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    display: block;
    width: 100%;
}

.videocall-connecting-modal .modal-body button {
    margin-top: 120px;
    background-color: var(--call-end-color);
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: unset;
    outline: unset;
    margin-bottom: 50px;
}

/* VIDEO CALL CONNECTING MODAL POPUP CSS :: ENDS */

/* VIDEO CALL MINIMIZE POPUP CSS :: STARTS */
.video-call-minmize-popup {
    position: absolute;
    height: 100%;
    right: 8%;
    top: 0;
    box-shadow: var(--modal-box-shadow);
    border-radius: 50px;
    background-color: var(--minimize-popup-background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.video-call-minmize-popup div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-call-minmize-popup .connecting-icon {
    background-color: var(--popup-icon-background);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.video-call-minmize-popup p {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    color: var(--white-color);
    padding-right: 30px;
}

.video-call-minmize-popup p span {
    display: block;
}

.video-call-minmize-popup p span:last-child {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.video-call-minmize-popup button {
    width: 45px;
    height: 45px;
    background-color: var(--call-end-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
}

.video-call-minmize-popup button svg {
    width: 30px;
}

/* VIDEO CALL MINIMIZE POPUP CSS :: ENDS  */

/* VIDEO CALL CONNECTING MAXIMIZE PAGE CSS :: STARTS */
.videocall-connecting-maximize-container {
    height: 100vh;
    background-color: var(--maximize-container-color);
}

.videocall-connecting-maximize-container .maximize-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--main-gradient-color);
    padding: 20px;
}

.videocall-connecting-maximize-container .maximize-container-header h5 {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
}

.videocall-connecting-maximize-container .call-connecting-info {
    display: flex;
    justify-self: center;
    align-items: center;
    flex-direction: column;
    margin: 100px;
}

.videocall-connecting-maximize-container .call-connecting-info h3 {
    color: var(--black-color);
    font-size: 37px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0em;
    margin-bottom: 10px;
}

.videocall-connecting-maximize-container .call-connecting-info img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
}

.videocall-connecting-maximize-container .call-connecting-info p {
    color: var(--dark-grey);
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    margin-top: 30px;
}

.videocall-connecting-maximize-container .call-connecting-info span {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    display: block;
    width: 100%;
    text-align: center;
}

.videocall-connecting-maximize-container .call-connecting-info button {
    margin-top: 120px;
    background-color: var(--call-end-color);
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: unset;
    outline: unset;
    position: absolute;
    bottom: 30px;
}

.videocall-connecting-maximize-container .call-connecting-info button svg {
    width: 70%;
}

/* VIDEO CALL CONNECTING MAXIMIZE PAGE CSS :: ENDS */


/* INCOMING VIDEO CALL PAGE CSS :: STARTS  */
.incoming-videocall-modal {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
    top: 0;
}

.incoming-videocall-modal .modal-content {
    padding: 0px !important;
    border-radius: 25px !important;
}

.incoming-videocall-modal .modal-title {
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.incoming-videocall-modal .modal-header {
    background: var(--main-gradient-color);
    border-radius: 25px 25px 0px 0px !important;
    color: var(--white-color) !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.incoming-videocall-modal .modal-header div {
    display: flex;
    align-items: baseline;
}

.incoming-videocall-modal .modal-header p {
    font-size: 36px;
    font-weight: 300;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0px;
}

.incoming-videocall-modal .modal-content svg {
    margin-bottom: 0px !important;
}

.incoming-videocall-modal .modal-body {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 20px;
}

.incoming-videocall-modal .modal-body h3 {
    color: var(--black-color);
    font-size: 37px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0em;
    margin-bottom: 10px;
}

.incoming-videocall-modal .modal-body img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
}

.incoming-videocall-modal .modal-body p {
    color: var(--dark-grey);
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    margin-bottom: 0px;
}

.incoming-videocall-modal .modal-body span {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    display: block;
    width: 100%;
}

.incoming-videocall-modal .modal-body button {
    margin-top: 120px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: unset;
    outline: unset;
    margin-bottom: 50px;
}

.incoming-videocall-modal .modal-body .call-end-btn {
    background-color: var(--call-end-color);
}

.incoming-videocall-modal .modal-body .accept-call-btn {
    background-color: var(--active-call-color);
}

/* INCOMING VIDEO CALL PAGE CSS :: ENDS  */

/* INCOMING VIDEO CALL MINIMIZE PAGE CSS :: STARTS */
.incoming-videocall-minimize-modal {
    position: absolute;
    height: 100%;
    right: 8%;
    top: 0;
    box-shadow: var(--modal-box-shadow);
    border-radius: 50px;
    background-color: var(--minimize-popup-background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.incoming-videocall-minimize-modal div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.incoming-videocall-minimize-modal .connecting-icon {
    background-color: var(--popup-icon-background);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.incoming-videocall-minimize-modal p {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    color: var(--white-color);
    padding-right: 30px;
}

.incoming-videocall-minimize-modal p span {
    display: block;
}

.incoming-videocall-minimize-modal p span:last-child {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.incoming-videocall-minimize-modal button {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
}

.incoming-videocall-minimize-modal .end-button {
    background-color: var(--call-end-color);
}

.incoming-videocall-minimize-modal .accept-button {
    background-color: var(--active-call-color);
}

.incoming-videocall-minimize-modal .accept-button svg {
    width: 22px;
}

.incoming-videocall-minimize-modal .end-button svg {
    width: 35px;
}

/* INCOMING VIDEO CALL MINIMIZE PAGE CSS :: ENDS */

/* INCOMING VIDEO CALL MAXIMIZE PAGE CSS :: STARTS */
.incoming-videocall-maximize-container {
    background-color: var(--maximize-container-color);
    height: 100vh;
}

.incoming-videocall-maximize-container .maximize-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--main-gradient-color);
    padding: 20px;
}

.incoming-videocall-maximize-container .maximize-container-header h5 {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
}

.incoming-videocall-maximize-container .call-connecting-info {
    display: flex;
    justify-self: center;
    align-items: center;
    flex-direction: column;
    margin: 100px;
}

.incoming-videocall-maximize-container .call-connecting-info h3 {
    color: var(--black-color);
    font-size: 37px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0em;
    margin-bottom: 10px;
}

.incoming-videocall-maximize-container .call-connecting-info img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
}

.incoming-videocall-maximize-container .call-connecting-info p {
    color: var(--dark-grey);
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    margin-top: 10px;
}

.incoming-videocall-maximize-container .call-connecting-info span {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    display: block;
    width: 100%;
    text-align: center;
}

.incoming-videocall-maximize-container .call-button-container {
    position: absolute;
    bottom: 100px;
}

.incoming-videocall-maximize-container .call-connecting-info button {
    margin-top: 120px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: unset;
    outline: unset;
}

.incoming-videocall-maximize-container .call-connecting-info .end-button {
    background-color: var(--call-end-color);
}

.incoming-videocall-maximize-container .call-connecting-info .accept-button {
    background-color: var(--active-call-color);
}

.incoming-videocall-maximize-container .call-connecting-info button svg {
    width: 70%;
}

/* INCOMING VIDEO CALL MAXIMIZE PAGE CSS :: ENDS */

/* ACTIVE VIDEO CALL POPUP PAGE CSS :: STARTS */
.video-call-popup {
    width: 100%;
    height: calc(100vh - 80px);
    background: linear-gradient(180deg, rgba(31, 31, 31, 0.52) 0%, rgba(31, 31, 31, 0.96) 100%);
    box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.15);
}

.video-call-popup .call-header {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.video-call-popup .remote-peer {
    height: 100%;
    width: 100%;
    top: 0px;
    border-radius: 30px;
    right: 0px;
    left: 0;
    bottom: 0;
}

.video-call-popup .local-peer {
    width: 400px;
    height: 230px;
    right: 30px;
    bottom: 100px;
    border-radius: 15px;
}

.video-call-popup .remote-peer video,
.video-call-popup .local-peer video {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.video-call-popup .local-peer video {
    border-radius: 15px;
}

.video-call-popup svg.switch-arrow {
    position: absolute;
    right: 10px;
    top: 10px;
}

.video-call-popup span.name {
    font-size: 26px;
    font-weight: 400;
    line-height: 39px;
    position: absolute;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white-color);
    top: 5px;
    left: 15px;
}

.video-call-popup .call-header p {
    color: var(--white-color);
    font-size: 26px;
    font-weight: 400;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
}

.video-call-popup .call-buttons {
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.video-call-popup .call-buttons button {
    height: 70px;
    width: 70px;
    background-color: #706C67;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: unset;
    outline: unset;
}

.video-call-popup .call-buttons .end-button {
    background-color: var(--call-end-color);
}

.video-call-popup .call-time {
    background-color: var(--active-call-color);
    color: var(--white-color);
    font-size: 29px;
    font-weight: 500;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: left;
    padding: 2px 12px;
    border-radius: 10px;
    bottom: 30px;
    left: 20px;
}

/* ACTIVE VIDEO CALL POPUP PAGE CSS :: ENDS */

/* ACTIVE VIDEO CALL MINIMIZE POPUP CSS :: STARTS */
.videocall-minimize {
    bottom: 35px;
    right: 10px;
    height: 230px;
    width: 400px;
    border-radius: 15px;
}

.videocall-minimize video {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}

.videocall-minimize svg.maximize-arrow.position-absolute {
    top: 10px;
    right: 10px;
}

.videocall-minimize span.name {
    color: var(--white-color);
    font-size: 23px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    position: absolute;
    top: 10px;
    left: 10px;
}

.videocall-minimize .call-buttons {
    width: 100%;
    bottom: 10px;
}

.videocall-minimize .call-buttons button {
    height: 35px;
    width: 35px;
    background-color: #706C67;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: unset;
    outline: unset;
}

.videocall-minimize .call-buttons button svg {
    width: 55%;
    height: 55%;
}

.localMinimizeVideo {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100px !important;
    height: 100px !important;
    /* border: 1px solid; */
}

.videocall-minimize .call-buttons .end-button {
    background-color: var(--call-end-color);
}

/* ACTIVE VIDEO CALL MINIMIZE POPUP CSS :: ENDS */

/* CONFERENCE CALL ON HOLD PAGE CSS :: STARTS */
.conference-call-onhold .call-connecting-info {
    justify-content: start;
}

.conference-call-onhold .conference-call-info .caller-info,
.active-call-attandent-info .conference-call-info .caller-info {
    color: var(--black-color);
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 50px;
}

.conference-call-onhold .conference-call-info .conference-time,
.active-call-attandent-info .conference-call-info .conference-time {
    color: var(--black-color);
    font-size: 29px;
    font-weight: 500;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 50px;
}

.call-info-box:hover .hover-content {
    display: block;
}

.conference-call-onhold .hover-content {
    display: none;
    background: linear-gradient(180deg, rgba(97, 75, 152, 0) 0%, #2D214A 100%);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 25px;
}

.conference-call-onhold .hover-content .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.conference-call-onhold .hover-content .buttons button {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: unset;
    outline: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--link-color);
}

.conference-call-onhold .hover-content .buttons .end-button {
    background-color: var(--call-end-color);
}

.conference-call-onhold .hover-content .buttons .pause-button svg {
    width: 45%;
}

.conference-call-onhold .hover-content .buttons button svg {
    width: 70%;
}

/* CONFERENCE CALL ON HOLD PAGE CSS :: ENDS */

/* ONGOING CALL HOLDHOVER PAGE CSS :: STARTS*/
.ongoing_call_holdhover_container .call-connecting-info {
    justify-content: start;
}

.ongoing_call_holdhover_container .conference-call-info .caller-info {
    color: var(--black-color);
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 50px;
}

.ongoing_call_holdhover_container .conference-call-info .conference-time {
    color: var(--black-color);
    font-size: 29px;
    font-weight: 500;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 50px;
}

.ongoing_call_holdhover_container .call-buttons-option-container button {
    margin-top: 50px !important;
}

.ongoing_call_holdhover_container .call-buttons-option-container button {
    margin-top: 50px !important;
}

.call-info-box:hover .hover-content {
    display: block;
}

.ongoing_call_holdhover_container .hover-content {
    display: none;
    background: linear-gradient(180deg, rgba(97, 75, 152, 0) 0%, #2D214A 100%);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 25px;
}

.ongoing_call_holdhover_container .hover-content .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.ongoing_call_holdhover_container .hover-content .buttons button {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: unset;
    outline: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
}

.ongoing_call_holdhover_container .hover-content .buttons .end-button {
    background-color: var(--call-end-color);
}

.ongoing_call_holdhover_container .hover-content .buttons .pause-button svg {
    width: 45%;
}

.ongoing_call_holdhover_container .hover-content .buttons button svg {
    width: 70%;
}

/* ONGOING CALL HOLDHOVER PAGE CSS :: ENDS* */

/* APPLICATION SETTING PAGE CSS :: STARTS */
.application-settings .setting-header h3 {
    padding: 20px;
    color: var(--black-color);
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
}

.application-settings .settings {
    margin: 30px 20px;
    width: 95%;
    background: var(--white-color);
    box-shadow: var(--modal-box-shadow);
    border-radius: 8px;
    margin-top: 30px;
    padding: 25px;
}

.application-settings .settings li {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--divider-border);
}

.application-settings .settings li p {
    font-size: 21px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--primary-color);
}

.application-settings .settings li span {
    color: var(--dark-grey);
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

}

/* TOGGLE BUTTON */
.application-settings .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.application-settings .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.application-settings .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--cancel-btn-color);
    -webkit-transition: .4s;
    transition: .4s;
}

.application-settings .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--white-color);
    -webkit-transition: .4s;
    transition: .4s;
}

.application-settings input:checked+.slider {
    background: var(--secondary-color);
}

.application-settings input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.application-settings .slider.round {
    border-radius: 34px;
}

.application-settings .slider.round:before {
    border-radius: 50%;
}

/* TOGGLE BUTTON */
/* APPLICATION SETTING PAGE CSS :: ENDS */

/* LANGUAGE SETTING PAGE CSS :: STARTS */
.language-settings .setting-header h3 {
    padding: 20px;
    color: var(--black-color);
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
}

.language-settings .settings {
    margin: 30px 20px;
    width: 85%;
    background: var(--white-color);
    box-shadow: var(--modal-box-shadow);
    border-radius: 8px;
    margin-top: 30px;
    padding: 25px;
}

.language-settings .settings li:not(:last-child) {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--divider-border);
}

.language-settings .settings li label {
    font-size: 21px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--primary-color);
    margin-left: 15px;
}

/* LANGUAGE SETTING PAGE CSS :: ENDS */

/* TEST CONNECTION SETTING PAGE CSS :: STARTS */
.test-connection-settings .setting-header h3 {
    padding: 20px;
    color: var(--black-color);
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
}

.test-connection-settings .settings {
    margin: 30px 20px;
    width: 95%;
    background: var(--white-color);
    box-shadow: var(--modal-box-shadow);
    border-radius: 8px;
    margin-top: 30px;
    padding: 25px;
}

.test-connection-settings .settings li:not(:last-child) {
    margin-bottom: 20px;
    height: 55px;
}

.test-connection-settings .settings li .flex-item-right {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--primary-color);
    width: 50%;
}

.test-connection-settings .settings li .flex-item-left {
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
    width: 50%;
}

#browser-features tr {
    height: 80px;
}

.settings-for-activecall {
    margin: 30px 20px;
    width: 95%;
    background: var(--white-color);
    box-shadow: var(--modal-box-shadow);
    border-radius: 8px;
    margin-top: 30px;
    padding: 50px;
}

.settings-for-activecall .complete-svg {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: #E8DFFF;
}

.settings-for-activecall p {
    color: var(--black-color);
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    margin: 15px;
}

.settings-for-activecall .progress {
    width: 210px;
    margin: 10px;
    border-radius: 10px;
}

.settings-for-activecall .progress .progress-bar {
    background-color: var(--active-call-color);
}

.settings-for-activecall .date-time p {
    color: var(--black-color);
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
}

/* TEST CONNECTION SETTING PAGE CSS :: ENDS */

/* DEBUGGING LOGS SETTING PAGE CSS :: STARTS */
.debugging-logs-settings .setting-header h3 {
    padding: 20px;
    color: var(--black-color);
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
}

.logs-confirmation,
.websocket-test {
    padding: 20px;
}

.debugging-logs-settings .logs-confirmation p {
    color: var(--black-color);
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;

}


.debugging-logs-settings .logs-confirmation button {
    height: 58px;
    width: 182px;
    border-radius: 50px;
    margin-right: 15px;
    border: unset;
    outline: unset;
    margin-top: 20px;
    margin-bottom: 50px;
}

.debugging-logs-settings .logs-confirmation .clear-logs {
    background-color: var(--primary-color);
    color: var(--white-color);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
}

.debugging-logs-settings .logs-confirmation .download-logs {
    background-color: var(--grey-color);
    color: var(--black-color);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    opacity: 0.5;
}

.websocket-test h3 {
    color: var(--primary-color);
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
}

.websocket-test .status-box {
    background: var(--white-color);
    border: 1.39887px solid #F1F1F1;
    border-radius: 15px;
    width: fit-content;
    padding: 15px 20px;
}


.websocket-test .status-box img,
.websocket-test .status-box svg {
    width: 131px;
    height: 131px;
    margin-right: 25px;
}

.websocket-test .status-box p {
    color: var(--black-color);
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 10px;
}

.websocket-test .status-box .connecting-info div {
    background: var(--hover-stack-dark);
    border-radius: 8px;
    padding: 10px 15px;
}

.websocket-test .status-box .connecting-info div p {
    color: var(--black-color);
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

.websocket-test .status-box .connecting-info div a {
    color: var(--black-color);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;
}

.websocket-test .fail-box .connecting-info div {
    background: var(--error-background-color);
    opacity: 0.9;
}

.websocket-test .fail-box p {
    color: var(--error-text);
}

/* DEBUGGING LOGS SETTING PAGE CSS :: ENDS */


/* RE_REGISTER SETTING PAGE CSS :: STARTS */
.register-settings .setting-header h3 {
    padding: 20px;
    color: var(--black-color);
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
}

.register-settings .setting-header h3 svg {
    margin-right: 15px;
}

.register-content {
    padding: 100px;
}

.register-content svg {
    margin-bottom: 20px;
    width: 130px;
    height: 130px;
}

.register-content p {
    color: var(--black-color);
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}


.register-content .progress {
    width: 210px;
    margin: 10px;
    border-radius: 10px;
}

.register-content .progress .progress-bar {
    background-color: var(--secondary-color);
}

.register-content .complete-progres .progress-bar {
    background-color: var(--active-call-color) !important;
}

/* RE_REGISTER SETTING PAGE CSS :: ENDS */

/* PRIVACY POLICY / ABOUT PAGE IFRAME CSS :: STARTS */
.iframeset {
    width: 100%;
    height: calc(100vh - 180px);
    margin-bottom: 100px;
}

.language-settings.right-side-content {
    height: 100%;
}

/* PRIVACY POLICY / ABOUT PAGE IFRAME CSS :: ENDS */

/* HOME_CHATS PAGE CSS :: STARTS */

.home-chats-containers .left-menu-content .recent-calls div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 5px;
    border-radius: 8px;
    position: relative;
}

.home-chats-containers .left-menu-content .recent-calls .recent-call-item:hover {
    background-color: var(--hover-stack-dark);
}


.home-chats-containers .left-menu-content .recent-calls .recent-call-info span {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
    display: block;
}

.home-chats-containers .left-menu-content .recent-calls .recent-call-info span .pin-chat {
    top: -2px;
    right: -25px;
}

.home-chats-containers .left-menu-content .recent-calls .recent-call-info img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
}

.home-chats-containers .left-menu-content .recent-calls .recent-call-info .caller-name {
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--dark-grey);
}

.home-chats-containers .left-menu-content .recent-calls .recent-call-info .green-dot {
    background-color: var(--active-color);
    height: 15px;
    width: 15px;
    border-radius: 50%;
    position: absolute;
    left: 48px;
    top: 2px;
    border: 2px solid var(--white-color);
}

.home-chats-containers .left-menu-content .recent-calls .recent-call-date {
    color: var(--dark-grey);
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: right;
    display: block;
}

.home-chats-containers .left-menu-content .recent-calls .recent-call-date .count {
    text-align: right;
    height: 20px;
    width: 20px;
    background: red;
    border-radius: 50%;
    float: right;
    color: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    margin-top: 4px;
}

.home-chats-containers .left-menu-content .recent-calls .recent-call-date .read-msg {
    text-align: right;
}

.home-chats-containers .left-menu-content .recent-calls .divider {
    border-bottom: 1px solid var(--secondary-color);
    opacity: 0.1;
    padding: 3px;
}

.home-chats-containers .right-side-content {
    background-color: var(--white-color);
    margin-top: 10px;
    border-radius: 20px 0px 0px 0px;
    height: 100vh;
    overflow-y: hidden;
}

.home-chats-containers .right-side-content .top-banner img {
    width: 100%;
    border-radius: 20px 0px 0px 0px;
}

.home-chats-containers .right-side-content .welcome-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
}

.home-chats-containers .right-side-content .welcome-text div h3 {
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
}

.home-chats-containers .right-side-content .welcome-text div p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    margin-bottom: 0px;
}

.home-chats-containers .right-side-content .welcome-text button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 70px;
    background-color: var(--primary-color);
    border-radius: var(--common-radius);
    border: none;
    outline: none;
    color: var(--white-color);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
}

.home-chats-containers .right-side-content .welcome-text button svg {
    height: 20px;
    margin-top: 2px;
}

.home-chats-containers .right-side-content .welcome-text button svg path {
    stroke: none;
    fill: var(--white-color);
}

.home-chats-containers .right-side-content .calls-history {
    width: 100%;
    border-top: 1px solid var(--divider-border);
}

/* SCROLL BAR CSS :: STARTS */
/* width */
.home-chats-containers .right-side-content .calls-history::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.home-chats-containers .right-side-content .calls-history::-webkit-scrollbar-track {
    background-color: var(--white-color);
    border-radius: 10px;
}

/* Handle */
.home-chats-containers .right-side-content .calls-history::-webkit-scrollbar-thumb {
    background: var(--scroll-color);
    border-radius: 10px;
}

/* Handle on hover */
.home-chats-containers .right-side-content .calls-history::-webkit-scrollbar-thumb:hover {
    background: var(--scroll-color);
}

/* SCROLL BAR CSS :: ENDS */
/* HOME CHATS PAGE CSS :: ENDS */

/* SEND DIRECT MESSAGE PAGE CSS :: STARTS */

.chat-container .left-menu-content .accordion li .accordion-body button {
    text-align: center;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    background-color: transparent;
}

.chat-container .left-menu-content .accordion li .accordion-body button.active {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.chat-container .left-menu-content .accordion li .accordion-body button.active svg path {
    fill: var(--white-color);
}

.chat-container .left-menu-content .accordion li .accordion-body button.active:first-child svg path {
    stroke: var(--primary-color);
}

.chat-container .right-side-content {
    background-color: var(--white-color);
    margin-top: 10px;
    overflow-y: auto;
    border-radius: 20px 0px 0px 0px;
    overflow-x: hidden;
    height: calc(100vh - 90px);
    transition: 0.5s;
    position: relative;
}

.start-new-chat-content {
    padding: 30px;
}

.start-new-chat-content img {
    width: auto;
    height: 300px;
}

.start-new-chat-content h3 {
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    color: var(--black-color);
    margin: 25px;
}

.start-new-chat-content .searchbar {
    width: 60%;
    position: relative;
}

.start-new-chat-content input[type="search"] {
    height: 53px;
    width: 100%;
    border: 1px solid var(--input-border-color);
    color: var(--black-color);
    padding: 5px 5px 5px 40px;
}

.start-new-chat-content input[type="search"]::placeholder {
    color: var(--dark-grey);
}

.start-new-chat-content .next-arrow {
    width: 45px;
    height: 45px;
    background-color: var(--primary-color);
    border-radius: 50%;
    right: 5px;
}

.start-new-chat-content .search-icon {
    left: 10px;
}

.start-new-chat-content .searchbar {
    width: 60%;
    position: relative;
}

.start-new-chat-content .search-tag-list {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.start-new-chat-content .search-tag-list .taglist {
    position: absolute;
    left: 35px;
    background-color: var(--white-color);
}

.start-new-chat-content .search-tag-list .taglist span {
    border-radius: 8px;
    padding: 2px 5px;
    background-color: var(--hover-stack-dark);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.start-new-chat-content .search-tag-list .taglist span svg {
    height: 13px;
    width: 13px;
}

.start-new-chat-content .next-arrow {
    width: 45px;
    height: 45px;
    background-color: var(--primary-color);
    border-radius: 50%;
    right: 5px;
}

.start-new-chat-content .search-icon {
    left: 15px;
    z-index: 9;
}

.start-new-chat-content .contact-list-menu {
    top: 55px;
    margin-bottom: 30px;
}

.start-new-chat-content .contact-list-menu ul {
    height: 300px;
    overflow-y: scroll;
}

.start-new-chat-content .contact-list-menu li:not(:last-child) {
    border-bottom: 1px solid var(--divider-border);
}

.start-new-chat-content .contact-list-menu .actions-btn {
    right: 0px;
    bottom: -70px;
    gap: 8px;
}

.start-new-chat-content .contact-list-menu .actions-btn button {
    height: 58px;
    width: 182px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    outline: none;
}

.start-new-chat-content .contact-list-menu .actions-btn .create-btn {
    background: var(--main-gradient-color);
    color: var(--white-color);
    border: none;
}

.start-new-chat-content .contact-list-menu .actions-btn .cancel-btn {
    background-color: transparent;
    border: 1px solid var(--grey-color);
    color: var(--grey-color);
}

/* SEND DIRECT MESSAGE PAGE CSS :: ENDS */

/* CREATE GROUP PAGE CSS :: STARTS */
.create-group-content .group-search {
    padding: 30px;
    width: 100%;
}

.create-group-content .header {
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
    padding: 15px 20px;
}

.create-group-content .divider {
    border: 1px solid var(--divider-border);
}

.create-group-content h3 {
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    color: var(--black-color);
    margin: 25px;
}

.create-group-content .searchbar {
    width: 60%;
    position: relative;
}

.create-group-content input[type="search"] {
    height: 53px;
    width: 100%;
    border: 1px solid var(--input-border-color);
    color: var(--black-color);
    padding: 5px 5px 5px 40px;
}

.create-group-content input[type="search"]::placeholder {
    color: var(--dark-grey);
}

.create-group-content .next-arrow {
    width: 45px;
    height: 45px;
    background-color: var(--primary-color);
    border-radius: 50%;
    right: 5px;
}

.create-group-content .search-icon {
    left: 10px;
}

/* CREATE GROUP PAGE CSS :: ENDS */

/* ADD GROUP MEMBER PAGE CSS :: STARTS */
.add-group-member-content {
    padding: 30px;
}

.add-group-member-content .group-img {
    color: transparent;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
    background-color: var(--cancel-btn-color);
    border-radius: 50%;
    height: 205px;
    width: 205px;
}

.add-group-member-content .group-img input {
    display: none;
}

.add-group-member-content .group-img img {
    position: absolute;
    object-fit: cover;
    z-index: 0;
}

.add-group-member-content .group-img .-label {
    cursor: pointer;
    height: 165px;
    width: 165px;
}

.add-group-member-content .group-img .camera {
    bottom: 0px;
    right: 25px;
    background-color: var(--primary-color);
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.add-group-member-content input[type="file"] {
    position: absolute;
    bottom: 0;
    z-index: 99;
    width: 100%;
    right: -55px;
}

.add-group-member-content h3 {
    color: var(--black-color);
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    margin: 15px 15px 5px 15px;
}

.add-group-member-content p {
    color: var(--dark-grey);
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0px;
    margin-bottom: 30px;
}

.add-group-member-content .searchbar {
    width: 60%;
    position: relative;
}

.add-group-member-content input[type="search"] {
    height: 53px;
    width: 100%;
    border: 1px solid var(--input-border-color);
    color: var(--black-color);
    padding: 5px 5px 5px 40px;
}

.add-group-member-content input[type="search"]::placeholder {
    color: var(--dark-grey);
}

.add-group-member-content .search-tag-list {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.add-group-member-content .search-tag-list .taglist {
    position: absolute;
    left: 35px;
    background-color: var(--white-color);
}

.add-group-member-content .search-tag-list .taglist span {
    border-radius: 8px;
    padding: 2px 5px;
    background-color: var(--hover-stack-dark);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.add-group-member-content .search-tag-list .taglist span svg {
    height: 13px;
    width: 13px;
}

.add-group-member-content .next-arrow {
    width: 45px;
    height: 45px;
    background-color: var(--primary-color);
    border-radius: 50%;
    right: 5px;
}

.add-group-member-content .search-icon {
    left: 15px;
    z-index: 9;
}

.add-group-member-content .contact-list-menu {
    top: 60px;
    margin-bottom: 30px;
    padding-right: 7px !important;
}

.add-group-member-content .contact-list-menu ul,
.start-new-chat-content .contact-list-menu ul {
    height: 270px;
    overflow-y: scroll;
}

.add-group-member-content .contact-list-menu span.caller-avtar,
.start-new-chat-content .contact-list-menu span.caller-avtar {
    height: 46px;
    width: 46px;
    font-size: 20px;
}

.add-group-member-content .contact-list-menu div p,
.start-new-chat-content .contact-list-menu div p {
    color: var(--black-color);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}

.add-group-member-content .contact-list-menu div span,
.start-new-chat-content .contact-list-menu div span {
    color: var(--black-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

}

.add-group-member-content .contact-list-menu span.caller-avtar img,
.start-new-chat-content .contact-list-menu span.caller-avtar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.add-group-member-content .contact-list-menu span.caller-avtar svg,
.start-new-chat-content .contact-list-menu span.caller-avtar svg {
    width: 65%;
    height: auto;
}

.add-group-member-content .contact-list-menu li:not(:last-child) {
    border-bottom: 1px solid var(--divider-border);
}


.add-group-member-content .contact-list-menu .actions-btn {
    right: 0px;
    bottom: -100px;
    gap: 8px;
}

.add-group-member-content .contact-list-menu .actions-btn button {
    height: 58px;
    width: 182px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    outline: none;
}

.add-group-member-content .contact-list-menu .actions-btn .create-btn {
    background: var(--main-gradient-color);
    color: var(--white-color);
    border: none;
}

.add-group-member-content .contact-list-menu .actions-btn .cancel-btn {
    background-color: transparent;
    border: 1px solid var(--grey-color);
    color: var(--grey-color);
}

/* ADD GROUP MEMBER PAGE CSS :: ENDS */

/* CHAT CONTENT CSS :: STARTS */
.home-chats-containers .one-to-one-chat-content,
.group-chat-content {
    height: calc(100vh - 90px);
    overflow: hidden;
}

.home-chats-containers .chat-header {
    padding: 15px 20px;
    width: 100%;
    position: sticky;
    background-color: var(--white-color);
    top: 0;
    z-index: 99 !important;
    border-bottom: 1px solid var(--divider-border);
}

.home-chats-containers .chat-header .name-info div {
    width: max-content;
}

.home-chats-containers .chat-header .search-area {
    width: 100%;
}

.home-chats-containers .chat-header input[type="search"] {
    transition-property: width;
    transition-duration: 2s;
    transition-timing-function: ease;
}

.home-chats-containers .chat-header input[type="search"]:focus,
.home-chats-containers .chat-header input[type="search"]:focus-within,
.home-chats-containers .chat-header .search-area:focus input[type="search"],
.home-chats-containers .chat-header .search-area:focus-within input[type="search"] {
    width: 530px;
}

.home-chats-containers .chat-header .search-area:focus .search-text-box,
.home-chats-containers .chat-header .search-area:focus-within .search-text-box {
    opacity: 1;
}

.home-chats-containers .chat-header .search-text-box {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dark-grey);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    position: absolute;
    right: 0;
    opacity: 0;
}

.home-chats-containers .chat-header .search-text-box .vr-border {
    width: 0px;
    height: 40px;
    border-left: 1.5px solid var(--divider-border);
    margin: 0px 10px;
}

.home-chats-containers .chat-header .search-text-box .arrow {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--common-btn-color);
    outline: none;
    border: none;
}

.home-chats-containers .search-icon {
    position: absolute;
    left: 10px;
}

.home-chats-containers .chat-header .dots {
    margin: auto 25px;
}

.home-chats-containers .chat-header .dropdown-toggle::after {
    content: unset !important;
}

.home-chats-containers .chat-header .clear-history-dropdown {
    padding: 15px !important;
    background: var(--white-color);
    box-shadow: -20px 20px 35px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border: unset !important;
    right: 25px;
    width: max-content;
}

.home-chats-containers .chat-header .clear-history-dropdown li {
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}


.home-chats-containers .chat-options li:hover {
    border-radius: 8px;
    background-color: var(--hover-stack-dark);
}

.home-chats-containers .sender-msg.active-chat {
    background-color: var(--hover_states_light) !important;
    padding: 10px 40px !important;
    border-radius: 0px 8px 8px 0px !important;
    width: 100% !important;
    margin-left: -40px;
}

.home-chats-containers .float-right.active-chat {
    background-color: var(--hover_states_light) !important;
    padding: 10px 100px !important;
    border-radius: 8px 0px 0px 8px !important;
    width: 100% !important;
    margin-right: -100px;
}

.msg-box::after {
    content: unset !important;
}

.home-chats-containers .chat-header .pic {
    background-color: var(--primary-color);
    width: 55px !important;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 15px;
}

.home-chats-containers .chat-header .pic span {
    color: var(--white-color);
    font-size: 22px !important;
    font-weight: 600;
}

.home-chats-containers .chat-header .pic img {
    width: 100%;
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
}

.home-chats-containers .chat-header p {
    color: var(--black-color);
    font-size: 27px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    width: max-content;
}

.home-chats-containers .chat-header span {
    color: var(--dark-grey);
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    display: flex;
}

.home-chats-containers .chat-area {
    padding: 20px 35px;
    overflow-y: scroll;
    height: calc(100vh - 270px);
}

.home-chats-containers .chat-area .date-time {
    color: var(--cancel-btn-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 20px;
}

.home-chats-containers .chat-area .sender-msg {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    margin-bottom: 30px;
    width: 100% !important;
    position: relative;
}

.home-chats-containers .chat-area .sender-msg .pic {
    width: 55px;
    height: 55px;
    background: var(--primary-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    font-size: 23px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0em;
    margin-right: 10px;
}

.home-chats-containers .chat-area .sender-msg .pic img {
    width: 100%;
    height: auto;
}

.home-chats-containers .chat-area .sender-msg p {
    color: var(--primary-color);
    font-size: 19px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}

.home-chats-containers .chat-area .sender-msg p .time {
    color: var(--cancel-btn-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    margin-left: 10px;
}


.home-chats-containers .chat-area .sender-msg .msg-box {
    padding: 10px 15px;
    background-color: var(--chat-bubble-background);
    border-radius: 5px 15px 15px 15px;
    margin-top: 5px;
    /* display: flex;
    justify-content: flex-start;
    align-items: center; */
    color: var(--black-color);
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
}

.group-chat-content .chat-area .float-right {
    width: 100%;
    float: right;
}

.group-chat-content .chat-area .reciver-msg {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    margin-bottom: 30px;
    float: right;
}

.group-chat-content .chat-area .reciver-msg .pic {
    width: 55px;
    height: 55px;
    background: var(--primary-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    font-size: 23px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0em;
    margin-left: 10px;
}

.group-chat-content .chat-area .reciver-msg .pic img {
    width: 100%;
    height: auto;
}

.group-chat-content .chat-area .reciver-msg p {
    text-align: right;
    margin-bottom: 0px;
}

.group-chat-content .chat-area .reciver-msg p svg {
    margin-right: 10px;
}

.group-chat-content .chat-area .reciver-msg p span {
    color: var(--cancel-btn-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    margin-right: 10px;
    text-align: right;
}


.group-chat-content .chat-area .reciver-msg .msg-box {
    padding: 10px 15px;
    background-color: var(--chat-bubble-background);
    border-radius: 5px 15px 15px 15px;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--black-color);
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    width: fit-content;
}

.home-chats-containers .chat-area .float-right {
    width: 100%;
    float: right;
    position: relative;
}

.home-chats-containers .chat-area .reciver-msg {
    display: flex;
    justify-content: end;
    align-items: flex-start;
    margin-bottom: 30px;
    float: right;
    width: 100% !important;
    position: relative;
}

.home-chats-containers .chat-area .reciver-msg .pic {
    width: 55px;
    height: 55px;
    background: var(--primary-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    font-size: 23px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0em;
    margin-left: 10px;
}

.home-chats-containers .chat-area .reciver-msg .pic img {
    width: 100%;
    height: 100%;
    /* height: auto; */
    object-fit: cover;
}

.home-chats-containers .chat-area .reciver-msg p {
    text-align: right;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.home-chats-containers .chat-area .reciver-msg p svg {
    margin-right: 10px;
}

.home-chats-containers .chat-area .reciver-msg p span {
    color: var(--cancel-btn-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    margin-right: 10px;
    text-align: right;
}

.home-chats-containers .green-dot,
.home-chats-containers .red-dot,
.home-chats-containers .orange-dot,
.home-chats-containers .yellow-dot,
.home-chats-containers .grey-dot {
    height: 10px;
    width: 10px !important;
    border-radius: 50%;
    border: none;
    margin-right: 8px;
}

.home-chats-containers .chat-area .reciver-msg .msg-box {
    padding: 10px 15px;
    background-color: var(--my-chat-color);
    border-radius: 15px 5px 15px 15px;
    margin-top: 5px;
    /* display: flex;
    justify-content: flex-start;
    align-items: center; */
    color: var(--black-color);
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    float: right;
    width: fit-content;
}


.home-chats-containers .chat-footer .typing {
    color: var(--cancel-btn-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
    padding: 20px 35px;
}

.home-chats-containers .chat-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: var(--white-color);
}

.home-chats-containers .enter-chat-area {
    padding: 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-chats-containers .enter-chat-area input[type="text"] {
    border: none;
    outline: none;
    color: var(--dark-grey);
    width: 100%;
    height: 100%;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--dark-grey);
}

.home-chats-containers .enter-chat-area input[type="text"]::placeholder {
    color: var(--dark-grey);
}

.home-chats-containers .dropdown-toggle::after {
    content: unset !important;
}

/* .home-chats-containers .chat-area .forward-msg {
    display: flex;
    justify-content: end;
    align-items: flex-start;
    margin-bottom: 30px;
    float: right;
} */
/* 
.home-chats-containers .chat-area .forward-msg .pic {
    width: 55px;
    height: 55px;
    background: var(--primary-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    font-size: 23px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0em;
    margin-left: 10px;
}

.home-chats-containers .chat-area .forward-msg .pic img {
    width: 100%;
    height: auto;
}

.home-chats-containers .chat-area .forward-msg p {
    text-align: right;
    margin-bottom: 0px;
}

.home-chats-containers .chat-area .forward-msg p svg {
    margin-right: 10px;
}

.home-chats-containers .chat-area .forward-msg p img {
    margin-right: 10px;
}

.home-chats-containers .chat-area .forward-msg p span {
    color: var(--cancel-btn-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    margin-right: 10px;
    text-align: right;
} */

.home-chats-containers .chat-area .forward-msg .msg-box {
    /* padding: 10px 15px;
    background-color: var(--forward-msg-background);
    border-radius: 15px 5px 5px 15px;
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: end;
    color: var(--black-color);
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    width: fit-content;
    float: right;
    clear: both;
    flex-direction: column; */
}

.home-chats-containers .chat-area .forwarded-label {
    display: block;
    width: 100%;
    color: var(--dark-grey);
    font-size: 11px;
    font-style: italic;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
}

.home-chats-containers .chat-area .reciver-msg .forwarded-label {
    text-align: end;
    margin-bottom: 5px;
}

.home-chats-containers .enter-chat-area span#attachmentOptionBtn {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-chats-containers .enter-chat-area .active-attachment {
    background-color: var(--hover-stack-dark);
}


.home-chats-containers .enter-chat-area .emojis {
    background-color: var(--hover-stack-dark);
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-chats-containers .enter-chat-area .send-btn {
    background-color: var(--primary-color);
    border: none;
    outline: none;
    color: var(--white-color);
    padding: 10px 20px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.home-chats-containers .chat-options {
    padding: 20px 0px !important;
    background: var(--white-color);
    box-shadow: -20px 20px 35px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border: unset !important;
    right: 80px;
    top: 30px
}

.home-chats-containers .float-right .chat-options {
    left: 80px;
    right: unset !important;
}

.home-chats-containers .chat-options li {
    color: var(--black-color);
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding: 3px 0px !important;
}

.home-chats-containers .chat-options li:not(:last-child) {
    margin-bottom: 20px;
}

.home-chats-containers .chat-options li span {
    width: 50px;
}

.home-chats-containers .chat-options li svg {
    margin: auto;
    display: block;
}

.sender-msg .onclick-checkbox {
    margin-top: -12px;
    position: absolute;
    left: -20px;
}

.reciver-msg .onclick-checkbox {
    margin-top: -12px;
    position: absolute;
    right: -30px;
}

.sender-msg .onclick-checkbox .checkbox label:after,
.reciver-msg .onclick-checkbox .checkbox label:after {
    opacity: 0;
}

/* FORWARD MSSAGE POPUP */
.forward-msg-modal .btn-close {
    background: unset !important;
    margin-bottom: 0px !important;
    opacity: 1 !important;
}

.forward-msg-modal .btn-close svg {
    width: 15px;
    display: block;
}

.forward-msg-modal .modal-content {
    padding: 0px !important;
    border-radius: 25px !important;
    text-align: left !important;
    height: 530px;
}

.forward-msg-modal .modal-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.forward-msg-modal .modal-content svg {
    margin: unset !important;
}

.forward-msg-modal .modal-header {
    background: var(--main-gradient-color);
    border-radius: 25px 25px 0px 0px !important;
    color: var(--white-color) !important;
    display: block;
}

.forward-msg-modal .modal-footer .send-btn {
    background: var(--main-gradient-color);
    border: none;
    outline: none;
    color: var(--white-color);
    padding: 10px 20px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.forward-msg-modal .modal-footer .send-btn svg {
    margin: auto 10px !important;
}

.forward-msg-modal .contact-list-menu {
    width: 100%;
    background: var(--white-color);
    border: unset !important;
    box-shadow: unset;
    border-radius: unset !important;
}

.forward-msg-modal .contact-list-menu ul {
    overflow: unset !important;
}


.forward-msg-modal .contact-list-menu ul li {
    padding: 10px 10px 15px 10px !important;
}

.forward-msg-modal .contact-list-menu ul li:not(:last-child) {
    border-bottom: 1px solid #f1f1f1;
}

.forward-msg-modal .contact-list-menu ul li .checkbox label {
    top: 0;
    left: 0;
}

.forward-msg-modal .checkbox {
    position: absolute;
    right: 10px;
}

.forward-msg-modal .contact-list-menu .searchbar {
    width: 100%;
    margin-bottom: 10px;
}

.forward-msg-modal .contact-list-menu .searchbar input[type="search"] {
    width: 100%;
    padding: 10px 20px !important;
}

.forward-msg-modal .contact-list-menu .searchbar .icon {
    width: 30px;
    height: 30px;
    background-color: var(--primary-color);
    border-radius: 50%;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forward-msg-modal .modal-body {
    max-height: 450px;
    overflow-y: scroll;
}

.forward-msg-modal .modal-footer {
    margin: unset !important;
    flex-wrap: nowrap !important;
    justify-content: space-between;
    align-items: center;
    flex-shrink: unset !important;
    border-top: 1px solid var(--divider-border) !important;
    margin-top: 10px;
}


.forward-msg-modal .modal-footer input[type="text"] {
    border: none;
    outline: none;
    width: 100%;
    color: var(--dark-grey);
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding: 2px 10px;
}

.forward-msg-modal .modal-footer .taglist span {
    border-radius: 8px;
    padding: 2px 3px;
    background-color: var(--hover-stack-dark);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.forward-msg-modal .modal-footer .taglist span svg {
    height: 13px;
    width: 13px;
}

#recent-chat-forward.blur {
    filter: blur(5px);
}

/* FORWARD MESSAGE POPUP */

/* FORWARD BY SEARCH DROPDOWN */
.forward-list {
    width: 100%;
    top: 50px;
    background: var(--white-color);
    border: 1px solid var(--common-btn-color) !important;
    box-shadow: var(--modal-box-shadow);
    border-radius: 20px !important;
    padding-right: 5px !important;
}

.forward-list ul {
    max-height: 250px;
    overflow-y: scroll;
}

.forward-list span.caller-avtar {
    width: 50px;
    height: 50px;
    color: var(--white-color);
    font-size: 21px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
}

.forward-list span.caller-avtar img {
    width: 100%;
    object-fit: cover;
}

.forward-list span.caller-avtar img {
    width: 65%;
    height: auto;
}

.forward-list div p {
    color: var(--black-color);
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.forward-list div span {
    color: var(--dark-grey);
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.forward-list div .checkbox {
    width: 20px;
    height: 20px;
}

.forward-list div .checkbox label:after {
    width: 9px;
    height: 4px;
    top: 6px;
}

/* FORWARD BY SEARCH DROPDOWN */

/* SCROLL BAR CSS FOR MODAL :: STARTS */
/* width */
.forward-msg-modal .modal-body::-webkit-scrollbar,
.forward-list::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.forward-msg-modal .modal-body::-webkit-scrollbar-track,
.forward-list::-webkit-scrollbar-track {
    background-color: var(--white-color);
    border-radius: 10px;
}

/* Handle */
.forward-msg-modal .modal-body::-webkit-scrollbar-thumb,
.forward-list::-webkit-scrollbar-thumb {
    background: var(--scroll-color);
    border-radius: 10px;
}

/* Handle on hover */
.forward-msg-modal .modal-body::-webkit-scrollbar-thumb:hover,
.forward-list::-webkit-scrollbar-thumb:hover {
    background: var(--scroll-color);
}

/* SCROLL BAR CSS FOR MODAL  :: ENDS */
/* MULTIPLE MESSAGE FORWARD PAGE CSS :: ENDS */

/* CHAT CONTENT CSS :: ENDS */

/* CHAT ATTACHMENTS CSS :: STARTS */
.attachment-options {
    padding: 20px 0px !important;
    background: var(--white-color);
    box-shadow: -20px 20px 35px rgba(0, 0, 0, 0.05);
    border-radius: 15px !important;
    border: unset !important;
    bottom: 50px;
    left: -55px;
}

.attachment-options::before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    bottom: -15px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid var(--white-color);
    box-shadow: -20px 20px 35px rgba(0, 0, 0, 0.05);
    transition-duration: 0.3s;
    transition-property: transform;
}

.attachment-options li {
    color: var(--black-color);
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding: 3px 0px !important;
}

.attachment-options li:not(:last-child) {
    margin-bottom: 20px;
}

.attachment-options li span {
    width: 50px;
}

.attachment-options li svg {
    margin: auto;
    display: block;
}

.home-chats-containers .reciver-msg .msg .image-conatiner img,
.home-chats-containers .sender-msg .msg .image-conatiner img {
    width: 250px;
    height: 205px;
}

.home-chats-containers .reciver-msg .msg .image-conatiner,
.home-chats-containers .reciver-msg .msg .video-container {
    background-color: var(--forward-msg-background);
    margin-top: 5px !important;
}

.home-chats-containers .sender-msg .msg .image-conatiner,
.home-chats-containers .sender-msg .msg .video-container {
    background-color: var(--chat-bubble-background);
    margin-top: 5px !important;
}

.home-chats-containers .sender-msg .msg .image-conatiner .forwarded-label,
.home-chats-containers .sender-msg .msg .video-container .forwarded-label {
    margin-bottom: 5px;
}

.home-chats-containers .msg .image-conatiner {
    max-width: 265px;
}

.home-chats-containers .msg .image-conatiner,
.home-chats-containers .msg .downloading-video {
    padding: 10px !important;
    display: flex;
    align-items: center;
    border-radius: 15px 5px 15px 15px;
    flex-direction: column;
}

.home-chats-containers .msg .video-container {
    padding: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 15px 5px 15px 15px;
    flex-direction: column;
    position: relative;
    margin-top: 5px !important;
    max-width: 330px;
    overflow: hidden;
}

.home-chats-containers .msg .image-conatiner .retry-icon {
    top: 20px !important;
}

.home-chats-containers .msg .video-container .retry-icon {
    top: 45px !important;
}

.home-chats-containers .msg .video-container .video-time,
.home-chats-containers .msg .uploading-image .image-size {
    top: 40px;
    left: 20px;
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    border-radius: 50px;
    color: var(--white-color);
}

.home-chats-containers .msg .video-container .photo-size {
    width: 100%;
    margin-top: 5px;
}

.home-chats-containers .msg .video-container .video-time svg {
    margin-right: 3px;
}

.home-chats-containers .msg .image-conatiner .size-mb {
    bottom: 50px !important;
}

.home-chats-containers .msg .image-conatiner .size-mb,
.home-chats-containers .msg .video-container .size-mb {
    bottom: 30px;
    position: absolute;
    background: var(--black-color);
    font-size: 19px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    border-radius: 50px;
    color: var(--white-color);
    padding: 7px 15px;
}

.home-chats-containers .msg .video-container .size-mb {
    bottom: 55px;
}

.home-chats-containers .msg .video-container .size-mb svg,
.home-chats-containers .msg .video-container .bottom-size svg {
    margin-right: 5px;
}

.home-chats-containers .msg .image-conatiner img,
.home-chats-containers .msg .video-container img {
    width: 100%;
    object-fit: cover;
    border-radius: 5px 15px 15px 15px;
}

.home-chats-containers .msg .image-conatiner .photo-size,
.home-chats-containers .msg .video-container .bottom-size,
.home-chats-containers .msg .video-container .video-size {
    width: 100%;
    color: var(--cancel-btn-color);
    font-size: 12px !important;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 10px;
    margin-top: 8px;
}

.home-chats-containers .msg .caption {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 8px;
}

.home-chats-containers .msg .caption p {
    color: var(--black-color) !important;
    font-size: 17px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    letter-spacing: 0em !important;
    white-space: break-spaces;
    text-align: left;
}

.home-chats-containers .msg .image-conatiner div svg {
    margin-right: 5px;
}

.home-chats-containers .msg .image-conatiner div p {
    justify-content: start;
}

.home-chats-containers .image-conatiner .onclick-checkbox {
    display: none;
}

.home-chats-containers .msg .image-conatiner .chat-options {
    width: fit-content;
    top: 0px;
    bottom: unset;
    left: -170px;
    border-radius: 15px 5px 15px 15px;
}

.home-chats-containers .document-box {
    background-color: var(--chat-bubble-background);
    border-radius: 15px 5px 15px 15px;
    padding: 10px 15px;
    margin-top: 5px !important;
    width: 300px;
}

.home-chats-containers .document-box .forwarded-label {
    margin-bottom: 5px;
}

.home-chats-containers .document-box .file-name {
    display: inline-block;
    margin-bottom: 10px;
}

.home-chats-containers .reciver-msg .document-box {
    background-color: var(--my-chat-color);
}

.home-chats-containers .reciver-msg .document-box .icon {
    background-color: var(--hover-stack-dark);
}

.home-chats-containers .document-box .icon {
    background-color: #E6E3EF;
    padding: 10px;
    margin-right: 15px;
    border-radius: 10px;
    display: inline-flex;
}

.home-chats-containers .document-box .icon svg,
.home-chats-containers .document-box .icon img {
    height: 35px;
    width: 30px;
}

.home-chats-containers .document-box div p,
.home-chats-containers .reciver-msg .document-box p {
    color: var(--black-color) !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left !important;
}

.home-chats-containers .document-box div span {
    color: var(--cancel-btn-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.home-chats-containers .download {
    width: 64px;
    height: 64px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.home-chats-containers .status {
    color: var(--white-color);
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: -20px;
    justify-content: center;
    display: flex;
}

.home-chats-containers .download:hover {
    cursor: pointer;
}

.home-chats-containers [class^="icon-"],
[class*=" icon-"] {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-chats-containers .circle {
    fill: transparent;
    stroke: var(--white-color);
    stroke-width: 15px;
    stroke-dasharray: 0 880;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    opacity: 0;
}

.home-chats-containers .circle2 {
    fill: transparent;
    stroke: var(--active-call-color);
    stroke-width: 15px;
}

.home-chats-containers .downloading-text,
.home-chats-containers .uploading-text {
    color: var(--primary-color) !important;
    font-size: 11px !important;
    font-weight: 400 !important;
    line-height: 17px !important;
    letter-spacing: 0em !important;
}

.home-chats-containers .video-container .video-control {
    position: absolute;
    background-color: var(--white-color);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 0px;
    outline: none;
    border: none;
    font-size: 25px;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 35%;
    bottom: 35%;
}

/* CHAT ATTACHMENTS CSS :: ENDS */

/* CHAT SETTING PAGE CSS :: STARTS */
.home-chats-containers .take-photo-content {
    padding: 20px 50px;
}

.home-chats-containers .take-photo-content p {
    width: 100%;
    color: var(--black-color);
    font-size: 23px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;

}

.home-chats-containers .take-photo-content .pic {
    width: 100%;
    height: 450px;
    border-radius: 50%;
    margin-bottom: 50px;
}

.home-chats-containers .take-photo-content .pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 42px;
}

.home-chats-containers .take-photo-content button {
    width: 100%;
    border-radius: 15px;
    height: 68px;
}

.home-chats-containers .take-photo-content button svg {
    margin-right: 10px;
}

.home-chats-containers .take-photo-content button.cancel-btn {
    background-color: var(--cancel-btn-color);
}

/* CHAT SETTING PAGW CSS :: ENDS */

/* PHOTO PREVIEW PAGE CSS :: STARTS */
.home-chats-containers .media-content {
    margin: 15px 25px;
    padding: 15px 25px;
    background-color: rgba(87, 161, 215, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    min-height: 630px;
}

.home-chats-containers .media-content .pic {
    width: 490px;
    height: 460px;
    margin-bottom: 30px;
}

.home-chats-containers .media-content .pic img {
    width: 100% !important;
    object-fit: cover;
    border-radius: 30px;
    height: 100%;
}

.home-chats-containers .media-content .input-field {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 15px;
}

.home-chats-containers .media-content .input-field input[type=text] {
    width: 100%;
    background-color: var(--forward-msg-background);
    border-radius: 20px;
    height: 68px;
    position: relative;
    padding: 20px 40px;
    color: var(--cancel-btn-color);
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    outline: none;
    border: none;
}

.home-chats-containers .media-content .input-field .emoji-icon {
    position: absolute;
    right: 40px;
    background-color: var(--hover-stack-dark);
    padding: 5px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

.home-chats-containers .preview-content {
    height: calc(100vh - 90px);
    position: relative;
}

.home-chats-containers .preview-area {
    position: absolute !important;
    width: 100%;
}

.home-chats-containers .preview-area .preview-pics div {
    height: 68px;
    width: 68px;
    border: 1.5px solid var(--primary-color);
    border-radius: 15px;
    margin: auto 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-chats-containers .preview-area .preview-pics div video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.home-chats-containers .preview-area .preview-pics div:last-child {
    border-color: var(--cancel-btn-color);
}

.home-chats-containers .preview-pics div img {
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.home-chats-containers .preview-area button {
    border: none;
    outline: none;
    color: var(--white-color);
    padding: 10px 20px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    background-color: var(--primary-color);
    position: absolute;
    right: 20px;
}

.home-chats-containers .preview-area button .counter {
    top: -10px;
    width: 27px;
    height: 27px;
    background-color: var(--secondary-color);
    border-radius: 50%;
    color: var(--white-color);
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    right: -5px;

}

.home-chats-containers .top-icons {
    width: 95%;
    top: 20px;
    position: initial;
}

.home-chats-containers .top-icons p {
    color: var(--black-color);
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;

}

/* PHOTO PREVIEW PAGE CSS :: ENDS */

/* VIDEO PREVIEW PAGE CSS :: STARTS*/
.home-chats-containers .video-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 410px;
}

.home-chats-containers .player {
    width: 100%;
    max-width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 59.41%, #000000 95.98%);
    position: relative;
    font-size: 0;
    overflow: hidden;
    border: none;
    outline: none;
    border-radius: 30px;
    height: 100%;
}

.home-chats-containers .player_video {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
}

.home-chats-containers .video-box .fa {
    color: var(--white-color);
    font-size: 16px;
}

.home-chats-containers .video-box .player_controls {
    display: flex;
    position: absolute;
    bottom: 20px;
    width: 100%;
    flex-wrap: wrap;
    padding: 0px 20px;
}

.home-chats-containers .video-box .player_button {
    background: none;
    border: 0;
    color: var(--white-color);
    text-align: center;
    outline: 0;
    padding: 0;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.home-chats-containers .video-box .toggle {
    background-color: var(--white-color);
    border-radius: 50%;
}

.home-chats-containers .video-box .toggle .fa {
    color: var(--primary-color);
}

.home-chats-containers .video-box .time {
    padding-left: 0.5em;
    margin-right: 1em;
    color: var(--white-color);
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.home-chats-containers .video-box .speaker {
    position: absolute;
    right: 20px;
}

.home-chats-containers .video-box .progress-range {
    flex: 10;
    position: relative;
    display: flex;
    flex-basis: 100%;
    height: 11px;
    background-color: var(--white-color);
    cursor: pointer;
    border-radius: 30px;
    margin-top: 10px;
}

.home-chats-containers .video-box .progress-bar {
    background: var(--active-color);
    width: 50%;
    height: 100%;
    border-radius: 1.5em;
    transition: all 250ms ease;
    border-radius: 30px;
}

.home-chats-containers .video-container {
    margin: 0px !important;
    position: relative;
}

.home-chats-containers .video-container video {
    width: 315px;
    height: 205px;
    object-fit: cover;
    border-radius: 15px;
    position: relative;
}


.home-chats-containers .preview-area .video-control {
    position: absolute;
    background-color: var(--white-color);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0px;
}

.home-chats-containers .preview-area .video-control .fa {
    color: var(--primary-color);
    font-size: 16px;
}

.video-control:not(.playing) .video-control-pause,
.video-control.playing .video-control-play {
    display: none;
}

/* VIDEO PREVIEW PAGE CSS :: ENDS */

/* DOCUMENT PREVIEW PAGE CSS :: STARTS */
.home-chats-containers .document-preview-box {
    height: 180px;
    width: 180px;
    border-radius: 30px;
    background-color: var(--hover-stack-dark);
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-chats-containers .document-preview-box svg {
    width: 85px;
    height: 115px;
}

.home-chats-containers .document-info p {
    color: var(--black-color);
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    margin-top: 20px;
    margin-bottom: 10px;
}

.home-chats-containers .document-info span {
    color: var(--dark-grey);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
}

.home-chats-containers .documents-preview {
    background-color: var(--hover-stack-dark);
}


.home-chats-containers .documents-preview img {
    height: 34px;
    width: auto !important;
    object-fit: unset !important;
    border-radius: unset !important;
}

/* DOCUMENT PREVIEW PAGE CSS :: ENDS */

/* CUSTOM AUDIO PLAYER IN CHAT :: STARTS */
.audio-container {
    background-color: transparent !important;
    padding: 0px !important;
    padding: 5px 10px !important;
    width: 350px;
}

.audio-container .audio-player {
    box-shadow: none;
    width: 100%;
}

.audio-player,
.audio-container {
    display: block;
    position: relative;
    /* height: 68px; */
    background: var(--white-color);
    mix-blend-mode: normal;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.35);
    border-radius: 15px 5px 15px 15px;
    overflow: hidden;
    margin-top: 5px;
}

.audio-player .controls .fa {
    background: var(--primary-color);
    padding: 7px;
    border-radius: 50%;
    color: var(--white-color);
    width: 27px;
    height: 27px;
    font-size: 14px;
    margin-right: 10px;
}

.audio-player .controls {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px 10px 0px;
}

.audio-player .controls>* {
    display: flex;
    justify-content: center;
    align-items: center;
}

.audio-player .controls .play-container .toggle-play:hover {
    cursor: pointer;
}

.audio-player .controls .middle-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.audio-player .controls .middle-container .timeline {
    background: #F0F0F0;
    width: 100%;
    height: 11px;
    position: relative;
    cursor: pointer;
    border-radius: 30px;
}

.audio-player .controls .middle-container .timeline .progress {
    background: var(--secondary-color);
    width: 0%;
    height: 100%;
    transition: 0.25s;
    border-radius: 30px;
}

.audio-player .controls .middle-container .time {
    display: flex;
    color: var(--dark-grey);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
    justify-content: space-between;
}

.audio-player .time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--dark-grey);
    font-size: 14px !important;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    width: 90%;
    margin-left: auto;
    padding: 0px 15px;
}

/* CUSTOM AUDIO PLAYER IN CHAT :: ENDS */

/* UPLOADING ICON / DOWNLOADING ICON SMALL*/

.download-sm {
    width: 27px !important;
    height: 27px !important;
    position: relative !important;
    margin: unset !important;
    margin-right: 10px !important;
}

.download-sm svg {
    margin-right: 0px !important;
    display: flex;
}

.download-sm .circle {
    stroke: var(--grey-color);
    stroke-width: 30px !important;
}

.download-sm .circle2 {
    stroke-width: 15px !important;
}

.download-sm .icon-box svg {
    height: 13px;
    width: auto;
}

.download-sm .icon-box svg path {
    stroke: var(--active-call-color);
}

/* UPLOADING ICON / DOWNLOADING ICON SMALL*/

/* PIN - UNPIN CHAT PAGE CSS :: STARTS */
.home-chats-containers .left-menu-content .recent-calls .recent-call-item:hover .pin-unpin {
    display: block !important;
}

.home-chats-containers .pin-unpin {
    display: none !important;
    position: absolute !important;
    right: 0;
    top: -15px;
}

.home-chats-containers .pin-unpin .dropdown-toggle::after {
    content: unset !important;
}

.home-chats-containers .pin-chat-dropdown {
    padding: 15px !important;
    background: var(--white-color);
    box-shadow: -20px 20px 35px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border: unset !important;
    right: 0px;
    top: 30px;
}

.home-chats-containers .pin-chat-dropdown li {
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.home-chats-containers .pin-chat-dropdown li svg {
    margin-right: 8px;
}

.home-chats-containers .chat-options li:hover {
    border-radius: 8px;
    background-color: var(--hover-stack-dark);
}

/* PIN - UNPIN CHAT PAGE CSS :: ENDS */

/* OTHER USER PROFILE PAGE CSS :: STARTS */
.home-chats-containers .user-profile-content {
    height: calc(100vh - 90px);
    width: 0;
    position: fixed;
    z-index: 999;
    top: 90px;
    right: 0;
    background: var(--white-color);
    border-left: 1px solid var(--divider-border);
    border-radius: 0px;
    overflow-x: hidden;
    transition: 0.5s;
}

.home-chats-containers #chatContent {
    transition: margin-right .5s;
}

.home-chats-containers .user-profile-content .profile-header {
    padding: 22px 20px;
}

.home-chats-containers .user-profile-content .closebtn {
    text-decoration: none;
}

.home-chats-containers .user-profile-content .profile-header h3 {
    color: var(--black-color);
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
}

.home-chats-containers .user-profile-content .profile-header svg {
    margin-right: 25px;
}

.home-chats-containers .user-profile-content .profile-info {
    padding: 20px;
}

.home-chats-containers .user-profile-content .profile-info img {
    height: 128px;
    width: 128px;
    border-radius: 50%;
}

.home-chats-containers .user-profile-content .profile-info .name {
    color: var(--black-color);
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    margin-top: 10px;
}

.home-chats-containers .user-profile-content .profile-info .number {
    color: var(--black-color);
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
}

.home-chats-containers .user-profile-content .shared-media-info {
    margin-top: 40px;
    border-top: 3px solid var(--divider-border);
    border-bottom: 3px solid var(--divider-border);
    padding: 20px 30px 40px 20px;
}

.home-chats-containers .user-profile-content .shared-media-info p {
    color: var(--dark-grey);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.home-chats-containers .user-profile-content .shared-media-info a {
    color: var(--dark-grey);
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;
}

.home-chats-containers .user-profile-content .shared-media-info .medias div {
    height: 75px;
    width: 75px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--hover-stack-dark);
}

.home-chats-containers .user-profile-content .shared-media-info .medias div .video-control {
    width: 32px;
    height: 32px;
    font-size: 15px;
}

.home-chats-containers .user-profile-content .shared-media-info .medias div img,
.home-chats-containers .user-profile-content .shared-media-info .medias div video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.home-chats-containers img.small-icons {
    width: 30px !important;
    height: 35px !important;
    object-fit: unset !important;
    border-radius: unset !important;
}

.home-chats-containers .user-profile-content .actions {
    padding: 30px 50px;
}

.home-chats-containers .user-profile-content .actions li {
    margin-bottom: 25px;
    color: var(--error-text);
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.home-chats-containers .user-profile-content .actions li svg {
    margin-right: 20px;
}


/* SHARED MEDIAS AND DOCUMENTS CSS :: STARTS */
.media-tabs {
    padding: 25px 0px;
}

.media-tabs .nav-pills li {
    width: 50%;
    border-bottom: 2px solid var(--divider-border);
    padding: 0px 10px 20px !important;
    text-align: center;
}

.media-tabs .nav-pills .nav-link {
    color: var(--dark-grey);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
}

.media-tabs .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: transparent !important;
    color: var(--primary-color);
}

.media-tabs li:has(> a.active) {
    border-color: var(--primary-color);
}

.media-tabs .document-previews,
.media-tabs .media-preivew {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 95%;
    margin: auto;
    padding: 8px;
}

.media-tabs .document-previews .preview,
.media-tabs .pic-preview,
.media-tabs .video-preview,
.media-tabs .audio-preview {
    background-color: var(--hover-stack-dark);
    width: 105px;
    height: 105px;
    border-radius: 15px;
    margin: 8px;
}

.media-tabs .pic-preview img,
.media-tabs .video-preview video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.media-tabs .document-previews .preview img {
    width: 45px;
    height: 50px;
}

.media-tabs .audio-preview button {
    border: none;
    outline: none;
    background-color: transparent;
}

.media-tabs .video-preview button {
    position: absolute;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    border: unset;
    outline: unset;
}

.media-tabs .video-preview button span {
    font-size: 22px;
    color: var(--primary-color);
}

/* SHARED MEDIAS AND DOCUMENTS CSS :: ENDS */
/* OTHER USER PROFILE PAGE CSS :: ENDS */

/* PREVIEW MEDIA PAGE CSS :: STARTS */
.media-preview-modal {
    top: 0px !important;
    overflow-y: hidden !important;
    z-index: 1075 !important;
}

.media-preview-modal .modal-dialog {
    max-width: 100vw !important;
    height: 100vh !important;
    overflow-y: scroll;
    top: 12px;
}

.media-preview-modal .modal-dialog .modal-content {
    border-radius: 0px 21px 0px 0px !important;
    height: calc(100vh - 80px) !important;
    padding: 0px !important;
    overflow-y: scroll;
}

.media-preview-modal .modal-dialog .modal-content .modal-body {
    padding: 0px !important;
}

.media-preview-modal .modal-dialog .modal-content .modal-header {
    border-bottom: unset !important;
    padding: 20px 40px;
}

.media-preview-modal .modal-dialog .modal-header .preview-slider-header img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-right: 20px;
}

.media-preview-modal .modal-dialog .modal-header .preview-slider-header p {
    color: var(--black-color);
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
}

.media-preview-modal .modal-dialog .modal-header .preview-slider-header p span {
    color: var(--dark-grey);
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    display: block;
}

.media-preview-modal .mySlides {
    display: none;
    width: 480px;
    height: 480px;
    margin: auto;
    margin-top: 40px;
    border-radius: 15px;
}

.media-preview-modal .mySlides img {
    border-radius: 15px;
}


.browse-preview .mySelectedMedia {
    display: none;
    width: 480px;
    height: 480px;
    margin: auto;
    margin-top: 40px
}

.media-preview-modal .video-slide,
.media-preview-modal .audio-slide {
    width: 70% !important;
}

.media-preview-modal .audio-slide .audio-preview-icon {
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--hover-stack-dark);
    border-radius: 38px;
    margin: auto;
}

.media-preview-modal .audio-slide .audio {
    height: 100%;
    margin-top: 110px;
}

.media-preview-modal .audio-slide .audio-player {
    background-color: transparent;
    box-shadow: unset;
    width: 100%;
    margin-top: 50px;
}

.media-preview-modal .audio-slide .audio-player .controls .middle-container .timeline {
    height: 21px;
}

.media-preview-modal .audio-slide .audio-player .time {
    width: 97%;
}

.media-preview-modal .video-slide .video-box,
.media-preview-modal .video-slide .video-box .player {
    height: 100% !important;
}

.media-preview-modal .mySlides img,
.media-preview-modal .mySlides .video-container video {
    width: 100%;
    height: 100%;
}

.media-preview-modal .prev,
.media-preview-modal .next {
    height: 35px;
    width: 35px;
    cursor: pointer;
    position: absolute;
    top: 40%;
    margin-top: -50px;
    color: var(--white-color);
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
    background-color: var(--grey-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.media-preview-modal .prev:hover,
.media-preview-modal .next:hover {
    color: var(--white-color);
}

.media-preview-modal .prev {
    left: 100px;
}

.media-preview-modal .next {
    right: 100px;
}

.media-preview-modal .preview-footer {
    border-top: 1.5px solid var(--divider-border);
    padding: 10px;
    margin-top: 50px;
    width: 100%;
}

.media-preview-modal .column {
    width: 105px;
    height: 105px;
    border-radius: 15px;
    margin: 10px;
    background-color: var(--hover-stack-dark);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.media-preview-modal .column img,
.media-preview-modal .column .video-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.media-preview-modal .column .document-icons {
    width: 50px !important;
    height: auto;
    border-radius: unset !important;
}

.media-preview-modal .column .video-container {
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.media-preview-modal .column .video-container .video-control {
    height: 40px;
    width: 40px;
    font-size: 18px;
}

.media-preview-modal caption p {
    color: var(--black-color);
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 0px;
}

/* PREVIEW MEDIA PAGE CSS :: ENDS */

/* BROWSER PREVIEW CSS :: STARTS */
.browse-preview {
    position: absolute;
    top: 90px;
    z-index: 99;
    background-color: var(--white-color);
    width: 100%;
    height: 100%;
    padding: 20px;
}

.browse-preview .carousel-item {
    float: unset !important;
    margin-right: unset !important;
}

.browse-preview .carousel-inner {
    width: 100%;
    min-height: 530px;
    border-radius: 8px;
    background-color: var(--hover_states_light);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}


.browse-preview .carousel-inner .carousel-item {
    width: 100%;
}

.browse-preview .carousel-inner .top-icons .file-name {
    color: var(--black-color);
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    width: 100%;
}

.browse-preview .carousel-inner .carousel-item img {
    width: 430px;
    height: 400px;
    border-radius: 30px;
    margin: auto;
    display: block;
}

.browse-preview .carousel-inner .carousel-item .video-box {
    width: 90%;
    margin: auto;
}

.browse-preview .carousel-inner .carousel-item .audio-preview-icon {
    width: 180px;
    height: 180px;
    background-color: var(--hover-stack-dark);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.browse-preview .carousel-inner .carousel-item .audio-preview-icon img {
    height: 115px;
    width: auto;
    border-radius: 0px;
}

.browse-preview .carousel-inner .carousel-item .audio-preview-text {
    color: var(--black-color);
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    margin: 0px !important;
    margin-top: 20px;
    text-align: center;

}

.browse-preview .carousel-inner .carousel-item .audio-preview-text span {
    color: var(--dark-grey);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    margin-top: 10px;
}

.browse-preview .carousel-inner .carousel-item .document-preview-icon {
    width: 180px;
    height: 180px;
    background-color: var(--hover-stack-dark);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.browse-preview .carousel-inner .carousel-item .document-preview-icon img {
    height: 115px;
    width: auto;
    border-radius: 0px;
}

.browse-preview .carousel-inner .carousel-item .document-preview-text {
    color: var(--black-color);
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    margin-top: 20px;
    text-align: center;

}

.browse-preview .carousel-inner .carousel-item .document-preview-text span {
    color: var(--dark-grey);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    margin-top: 10px;
}

.browse-preview .carousel-indicators {
    bottom: unset !important;
    margin-left: unset !important;
    margin-right: unset !important;
    margin-top: 20px;
}

.browse-preview .carousel-indicators [data-bs-target],
.browse-preview .carousel-indicators .preview {
    text-indent: unset !important;
    height: 68px !important;
    width: 68px !important;
    border: 2px solid var(--primary-color) !important;
    border-radius: 15px;
    background-color: var(--hover-stack-dark) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1 !important;
    margin: auto 5px;
}

.browse-preview .carousel-indicators [data-bs-target] img,
.browse-preview .carousel-indicators [data-bs-target] .video-container,
.browse-preview .carousel-indicators [data-bs-target] .video-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.browse-preview .carousel-indicators [data-bs-target] .video-container .video-control {
    height: 30px;
    width: 30px;
    font-size: 16px;
}

.browse-preview .carousel-indicators [data-bs-target] img.preview-icon {
    object-fit: unset !important;
    width: 25px !important;
    height: 34px !important;
    border-radius: 0px !important;
}

.browse-preview .carousel-indicators button {
    border: none;
    outline: none;
    color: var(--white-color);
    padding: 10px 20px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    background-color: var(--primary-color);
    position: absolute;
    right: 20px;
}

.browse-preview .carousel-indicators button .counter {
    top: -10px;
    width: 27px;
    height: 27px;
    background-color: var(--secondary-color);
    border-radius: 50%;
    color: var(--white-color);
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    right: -5px;
}

.home-chats-containers .browse-preview .input-field {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 15px;
}

.home-chats-containers .browse-preview .input-field input[type=text] {
    width: 100%;
    background-color: var(--forward-msg-background);
    border-radius: 20px;
    height: 68px;
    position: relative;
    padding: 20px 40px;
    color: var(--cancel-btn-color);
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    outline: none;
    border: none;
}

.home-chats-containers .browse-preview .input-field .emoji-icon {
    position: absolute;
    right: 40px;
    background-color: var(--hover-stack-dark);
    padding: 5px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

.home-chats-containers .browse-preview .add-btn {
    border-color: var(--cancel-btn-color);
    height: 68px;
    width: 68px;
    border: 1.5px solid var(--primary-color);
    border-radius: 15px;
    margin: auto 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* BROWSER PREVIEW CSS :: ENDS */

/* DTMF DAILPAD CSS :: STARTS */
.dailpad-dropdown.show {
    display: block;
}

.dailpad-dropdown {
    display: none;
    position: absolute !important;
    background: #FFFFFF;
    box-shadow: 0px 14.1115px 28.9286px rgba(0, 0, 0, 0.15);
    border-radius: 23.6394px;
    bottom: 90px;
    right: 15px;
    max-width: 300px;
    min-width: 270px !important;
}

.dailpad-dropdown .dail-header .btn-close {
    background: unset !important;
    margin-bottom: 0px !important;
    opacity: 1 !important;
    height: fit-content;
    width: fit-content;
    position: absolute;
    top: 10px;
    right: 10px;
}

.dailpad-dropdown .dail-header .btn-close svg {
    position: absolute;
    width: 15px;
    display: block;
    right: 12px;
}


.dailpad-dropdown .dail-header {
    background: var(--main-gradient-color);
    border-radius: 25px 25px 0px 0px !important;
    color: var(--white-color) !important;
    display: block;
    position: relative;
    padding-top: 60px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dailpad-dropdown .dail-header .contact-area {
    width: 100%;
}

.dailpad-dropdown .dail-header .contact-area svg {
    right: 18px;
    position: absolute;
}

.dailpad-dropdown .dail-header input[type="text"] {
    width: 80%;
    height: 35px;
    background: transparent;
    outline: none;
    border: unset;
    text-align: center;
    font-size: 36px;
    font-weight: 300;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0px;
    color: var(--white-color);
    margin: auto;
    display: block;
}

.dailpad-dropdown .keypad-div {
    padding: 20px;
}

.dailpad-dropdown .keypad-div .digit {
    cursor: pointer;
    height: 65px;
    width: 65px;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    color: var(--dark-grey);
    letter-spacing: 0em;
    background-color: var(--digit-background);
    margin: auto !important;
}

.dailpad-dropdown .keypad-div .sub {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.19em;
    margin-top: -8px;
    color: var(--black-color);
}

.dailpad-dropdown .keypad-div .last_digit {
    font-size: 55px;
    font-weight: 300;
}

.dailpad-dropdown .keypad-div .last_hash {
    font-size: 40px;
    font-weight: 300;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
}

/* DTMF DAILPAD CSS :: ENDS */

/* NEW CALL CONFERENCE DIV CSS :: STARTS */

.callConferenceSquare .call-info-box:hover .hover-content {
    display: block;
}

.callConferenceSquare .call-info-box .hover-content {
    display: none;
    background: linear-gradient(180deg, rgba(97, 75, 152, 0) 0%, #2D214A 100%);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 25px;
}

.callConferenceSquare .call-info-box .hover-content .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.callConferenceSquare .call-info-box .hover-content .buttons button {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: unset;
    outline: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--link-color);
}

.callConferenceSquare .call-info-box .hover-content .buttons .end-button {
    background-color: var(--call-end-color);
}

.callConferenceSquare .call-info-box .hover-content .buttons img {
    width: 25px !important;
}

/* NEW CALL CONFERENCE DIV CSS :: ENDS */

/* MESSGAE HOME PAGE CSS :: STARTS */
.home-message-containers .message-home-content {
    padding: 30px;
}

.home-message-containers .message-home-content img {
    width: auto;
    height: 300px;
}

.home-message-containers .left-menu-content .accordion li .accordion-body button {
    text-align: center;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    background-color: transparent;
}

.home-message-containers .left-menu-content .accordion li .accordion-body button.active {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.home-message-containers .left-menu-content .accordion li .accordion-body button.active svg path {
    fill: var(--white-color);
}

.home-message-containers .left-menu-content .accordion li .accordion-body button.active:first-child svg path {
    stroke: var(--primary-color);
}


.home-message-containers .left-menu-content .recent-calls div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 5px;
    border-radius: 8px;
    position: relative;
}

.home-message-containers .left-menu-content .recent-calls .recent-call-item:hover {
    background-color: var(--hover-stack-dark);
}


.home-message-containers .left-menu-content .recent-calls .recent-call-info span {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black-color);
    display: block;
}

.home-message-containers .left-menu-content .recent-calls .recent-call-info span .pin-chat {
    top: -2px;
    right: -25px;
}

.home-message-containers .left-menu-content .recent-calls .recent-call-info img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
}

.home-message-containers .left-menu-content .recent-calls .recent-call-info .caller-name {
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--dark-grey);
}

.home-message-containers .left-menu-content .recent-calls .recent-call-info .green-dot {
    background-color: var(--active-color);
    height: 15px;
    width: 15px;
    border-radius: 50%;
    position: absolute;
    left: 48px;
    top: 2px;
    border: 2px solid var(--white-color);
}

.home-message-containers .left-menu-content .recent-calls .recent-call-date {
    color: var(--dark-grey);
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: right;
    display: block;
}

.home-message-containers .left-menu-content .recent-calls .recent-call-date .count {
    text-align: right;
    height: 20px;
    width: 20px;
    background: red;
    border-radius: 50%;
    float: right;
    color: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    margin-top: 4px;
}

.home-message-containers .left-menu-content .recent-calls .recent-call-date .read-msg {
    text-align: right;
}

.home-message-containers .left-menu-content .recent-calls .divider {
    border-bottom: 1px solid var(--secondary-color);
    opacity: 0.1;
    padding: 3px;
}

.home-message-containers .right-side-content {
    background-color: var(--white-color);
    margin-top: 10px;
    overflow-y: auto;
    border-radius: 20px 0px 0px 0px;
    overflow-x: hidden;
    height: calc(100vh - 90px);
    transition: 0.5s;
    position: relative;
}

.home-message-containers h3 {
    font-size: 27px;
    font-weight: 500;
    line-height: 41px;
    letter-spacing: 0em;
    color: var(--black-color);
    margin: 25px;
}

.home-message-containers .searchbar {
    width: 60%;
    position: relative;
}

.home-message-containers input[type="search"] {
    height: 53px;
    width: 100%;
    border: 1px solid var(--input-border-color);
    color: var(--black-color);
    padding: 5px 5px 5px 40px;
}

.home-message-containers input[type="search"]::placeholder {
    color: var(--dark-grey);
}

.home-message-containers .next-arrow {
    width: 45px;
    height: 45px;
    background-color: var(--primary-color);
    border-radius: 50%;
    right: 5px;
}

.home-message-containers .search-icon {
    left: 10px;
}

.home-message-containers .searchbar {
    width: 60%;
    position: relative;
}

.home-message-containers .search-tag-list {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.home-message-containers .search-tag-list .taglist {
    position: absolute;
    left: 35px;
    background-color: var(--white-color);
}

.home-message-containers .search-tag-list .taglist span {
    border-radius: 8px;
    padding: 2px 5px;
    background-color: var(--hover-stack-dark);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.home-message-containers .search-tag-list .taglist span svg {
    height: 13px;
    width: 13px;
}

.home-message-containers .next-arrow {
    width: 45px;
    height: 45px;
    background-color: var(--primary-color);
    border-radius: 50%;
    right: 5px;
}

.home-message-containers .search-icon {
    left: 15px;
    z-index: 9;
}

.home-message-containers .contact-list-menu {
    top: 55px;
    margin-bottom: 30px;
}

.home-message-containers .contact-list-menu ul {
    height: 300px;
    overflow-y: scroll;
}

.home-message-containers .contact-list-menu li:not(:last-child) {
    border-bottom: 1px solid var(--divider-border);
}

.home-message-containers .contact-list-menu .actions-btn {
    right: 0px;
    bottom: -70px;
    gap: 8px;
}

.home-message-containers .contact-list-menu .actions-btn button {
    height: 58px;
    width: 182px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    outline: none;
}

.home-message-containers .contact-list-menu .actions-btn .create-btn {
    background: var(--main-gradient-color);
    color: var(--white-color);
    border: none;
}

.home-message-containers .contact-list-menu .actions-btn .cancel-btn {
    background-color: transparent;
    border: 1px solid var(--grey-color);
    color: var(--grey-color);
}

.home-message-containers .contact-list-menu ul {
    height: 270px;
    overflow-y: scroll;
}

.home-message-containers .contact-list-menu span.caller-avtar {
    height: 46px;
    width: 46px;
    font-size: 20px;
}

.home-message-containers .contact-list-menu div p {
    color: var(--black-color);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}

.home-message-containers .contact-list-menu div span {
    color: var(--black-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

}

.home-message-containers .contact-list-menu span.caller-avtar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.home-message-containers .contact-list-menu span.caller-avtar svg {
    width: 65%;
    height: auto;
}

/* MESSAGE HOME PAGE CSS :: ENDS */

/* MESSAGE BODY CSS :: STARTS */
.home-message-containers .message-body {
    height: calc(100vh - 90px);
    overflow: hidden;
}

.home-message-containers .chat-header {
    padding: 15px 20px;
    width: 100%;
    position: sticky;
    background-color: var(--white-color);
    top: 0;
    z-index: 99 !important;
    border-bottom: 1px solid var(--divider-border);
}

.home-message-containers .chat-header .dots {
    margin: auto 25px;
}

.home-message-containers .chat-header .dropdown-toggle::after {
    content: unset !important;
}

.home-message-containers .chat-header .clear-history-dropdown {
    padding: 15px !important;
    background: var(--white-color);
    box-shadow: -20px 20px 35px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border: unset !important;
    right: 25px;
    width: max-content;
}

.home-message-containers .chat-header .clear-history-dropdown li {
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}


.home-message-containers .chat-options li:hover {
    border-radius: 8px;
    background-color: var(--hover-stack-dark);
}

.home-message-containers .sender-msg.active-chat {
    background-color: var(--hover_states_light) !important;
    padding: 10px 40px !important;
    border-radius: 0px 8px 8px 0px !important;
    width: 100% !important;
    margin-left: -40px;
}

.home-message-containers .float-right.active-chat {
    background-color: var(--hover_states_light) !important;
    padding: 10px 100px !important;
    border-radius: 8px 0px 0px 8px !important;
    width: 100% !important;
    margin-right: -100px;
}

.home-message-containers .chat-options {
    padding: 20px 0px !important;
    background: var(--white-color);
    box-shadow: -20px 20px 35px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border: unset !important;
    right: 80px;
    top: 30px
}

.home-message-containers .float-right .chat-options {
    left: 80px;
    right: unset !important;
}

.home-message-containers .chat-options li {
    color: var(--black-color);
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding: 3px 0px !important;
}

.home-message-containers .chat-options li:not(:last-child) {
    margin-bottom: 20px;
}

.home-message-containers .chat-options li span {
    width: 50px;
}

.home-message-containers .chat-options li svg {
    margin: auto;
    display: block;
}

.home-message-containers .sender-msg .onclick-checkbox {
    margin-top: -14px;
    position: absolute;
    left: -25px;
}

.home-message-containers .reciver-msg .onclick-checkbox {
    margin-top: -14px;
    position: absolute;
    right: -38px;
}

.home-message-containers .sender-msg .onclick-checkbox .checkbox label:after,
.home-message-containers .reciver-msg .onclick-checkbox .checkbox label:after {
    opacity: 0;
}

.home-message-containers .float-right.active-chat {
    background-color: var(--hover_states_light) !important;
    padding: 10px 100px !important;
    border-radius: 8px 0px 0px 8px !important;
    width: 100% !important;
    margin-right: -100px;
}

.msg-box::after {
    content: unset !important;
}

.home-message-containers .chat-header .pic {
    background-color: var(--primary-color);
    width: 55px !important;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 15px;
}

.home-message-containers .chat-header .pic span {
    color: var(--white-color);
    font-size: 22px !important;
    font-weight: 600;
}

.home-message-containers .chat-header .pic img {
    width: 100%;
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
}

.home-message-containers .chat-header p {
    color: var(--black-color);
    font-size: 27px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    width: max-content;
}

.home-message-containers .chat-header span {
    color: var(--dark-grey);
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    display: flex;
}

.home-message-containers .chat-area {
    padding: 20px 35px;
    overflow-y: scroll;
    height: calc(100vh - 270px);
}

.home-message-containers .chat-area .date-time {
    color: var(--cancel-btn-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 20px;
}

.home-message-containers .chat-area .sender-msg {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    margin-bottom: 30px;
    width: 100% !important;
    position: relative;
}

.home-message-containers .chat-area .sender-msg .pic {
    width: 55px;
    height: 55px;
    background: var(--primary-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    font-size: 23px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0em;
    margin-right: 10px;
}

.home-message-containers .chat-area .sender-msg .pic img {
    width: 100%;
    height: auto;
}

.home-message-containers .chat-area .sender-msg p {
    color: var(--primary-color);
    font-size: 19px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}

.home-message-containers .chat-area .sender-msg p .time {
    color: var(--cancel-btn-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    margin-left: 10px;
}


.home-message-containers .chat-area .sender-msg .msg-box {
    padding: 10px 15px;
    background-color: var(--chat-bubble-background);
    border-radius: 5px 15px 15px 15px;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--black-color);
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
}

.home-message-containers .chat-area .float-right {
    width: 100%;
    float: right;
}

.home-message-containers .chat-area .reciver-msg {
    display: flex;
    justify-content: end;
    align-items: flex-start;
    margin-bottom: 30px;
    float: right;
    width: 100% !important;
}

.home-message-containers .chat-area .reciver-msg .pic {
    width: 55px;
    height: 55px;
    background: var(--primary-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    font-size: 23px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0em;
    margin-left: 10px;
    position: relative;
}

.home-message-containers .chat-area .reciver-msg .pic img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.home-message-containers .chat-area .reciver-msg p {
    text-align: right;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.home-message-containers .chat-area .reciver-msg p span {
    color: var(--cancel-btn-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    margin-right: 10px;
    text-align: right;
}

.home-message-containers .chat-area .reciver-msg .msg-box {
    padding: 10px 15px;
    background-color: var(--my-chat-color);
    border-radius: 15px 5px 15px 15px;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--black-color);
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    float: right;
    width: fit-content;
}

.home-message-containers .chat-area .msg {
    position: relative;
}

.home-message-containers .chat-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: var(--white-color);
}

.home-message-containers .chat-footer .note {
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
    padding: 20px 35px;
}

.home-message-containers .enter-chat-area {
    padding: 20px 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.home-message-containers .enter-chat-area textarea {
    border: none;
    outline: none;
    color: var(--dark-grey);
    width: 100%;
    height: 100%;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--dark-grey);
    resize: none;
    overflow: hidden;
    direction: rtl;
    max-height: 75px;
    padding-right: 20px;
    line-break: anywhere;
}

.home-message-containers .enter-chat-area .send-btn {
    background-color: var(--primary-color);
    border: none;
    outline: none;
    color: var(--white-color);
    padding: 10px 20px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.home-message-containers .sender-msg .onclick-checkbox .checkbox label:after,
.home-message-containers .reciver-msg .onclick-checkbox .checkbox label:after {
    opacity: 1;
    border-color: var(--white-color);
}

/* MESSAGE BODY CSS :: ENDS */

/* MMS MEDIA SENDING SECTION CSS :: STARTS */
.attachment-media-img {
    width: 118px;
    height: 118px;
    border-radius: 5px;
    position: relative;
    border: 1px solid var(--grey-color);
}

.attachment-media-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.delete-attachment {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    right: -10px;
    top: -10px;
    border: none;
    outline: none;
    background-color: var(--primary-color);
}

.delete-attachment svg {
    width: 13px;
    height: 13px;
}

.attachment-media-video {
    width: 180px;
    height: 117px;
    border-radius: 2.86467px 8.594px 8.594px 8.594px;
    position: relative;
}

.attachment-media-video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.attachment-media-video .video-control {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: none;
    outline: none;
    color: var(--primary-color);
}

.attachment-media-video .delete-attachment {
    top: -12px;
    right: -5px;
}

.attachment-media-document {
    width: 263px;
    background-color: var(--my-chat-color);
    border-radius: 5px 15px 15px 15px;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.attachment-media-document .icon {
    padding: 12px 15px;
    background-color: var(--hover-stack-dark);
    border-radius: 10px;
    margin-right: 10px;
}

.attachment-media-document p {
    color: var(--black-color);
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
}

.attachment-media-document span {
    color: var(--cancel-btn-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

}

/* MMS MEDIA SENDING SECTION CSS :: ENDS */

/* MEDIA QUERY :: STARTS */
/* LARGE SCREEN(DESKTOP :: MAXIMUM WIDTH 1400PX) */
@media only screen and (max-width: 1440px) {

    .dailpad-dropdown .dail-header input[type="text"] {
        font-size: 30px;
    }

    .dailpad-dropdown .keypad-div .digit {
        height: 55px;
        width: 55px;
        font-size: 20px;
    }

    .dailpad-dropdown .keypad-div .digit .sub {
        font-size: 11px;
    }

    .dailpad-dropdown {
        bottom: 100px;
        right: 180px;
    }

    /* COMMON CSS :: STARTS */
    .logo {
        margin-bottom: 40px;
    }

    .back {
        margin-bottom: 30px;
    }

    .logo img {
        width: 150px;
        height: auto;
    }

    .heading {
        font-size: 30px;
    }

    .input-group input {
        font-size: 16px;
    }

    .sub-heading {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
    }

    .input-group {
        height: 45px;
    }

    .input-group-text {
        padding: 5px 15px;
    }

    input[type=search] {
        height: 40px;
        font-size: 15px;
    }

    .error-text {
        font-size: 14px;
    }

    .back-arrow {
        width: 18px;
        height: auto;
    }

    .common-btn {
        height: 45px;
        padding: unset;
        font-size: 16px;
        line-height: 20px;
    }

    .outline-btn {
        font-size: 16px;
    }

    /* COMMON CSS :: ENDS */

    /* CHECKBOX CSS :: STARTS */
    .checkbox {
        width: 18px;
        height: 18px;
    }

    .checkbox label {
        align-items: center;
    }

    .checkbox label:after {
        width: 8px;
        height: 5px;
        top: 5px;
    }

    /* CHECKBOX CSS :: ENDS */

    /* MODAL CSS :: STARTS */
    .modal-content p {
        font-size: 20px;
        line-height: 30px;
    }

    .modal-footer button {
        font-size: 16px;
    }

    /* MODAL CSS :: ENDS */

    /* HEADER CSS :: STARTS */
    header .nav-item {
        margin: 0px 15px;
    }

    header .navbar-nav .nav-item a svg,
    header ul li a svg {
        height: 25px;
    }

    header .profile-info img {
        width: 48px;
        height: 48px;
    }

    header .profile-info p {
        font-size: 16px;
    }

    header .profile-info p span {
        font-size: 14px;
    }

    /* HEADER CSS :: ENDS */

    /* LEFT MENU CSS :: STARTS */
    .left-menu-content ul li a {
        font-size: 18px;
    }

    .left-menu-content ul li a svg {
        width: 22px;
    }

    .left-menu-content ul li .accordion-body button {
        font-size: 16px !important;
    }



    /* LEFT MENU CSS :: ENDS */

    /* COMMON CSS FOR CALL HISTORY :: STARTS */

    .call-history-container .right-side-content .call-header h3 {
        font-size: 22px;
    }

    .calls-history .right-side-content .call-header .clear-history-dropdown li {
        font-size: 15px;
    }

    .calls-history .call-type svg {
        width: 18px;
    }

    .calls-history .card .card-header span.person {
        height: 55px;
        width: 55px;
    }

    .calls-history .caller-details h6 {
        font-size: 18px;
    }

    .calls-history .caller-details p,
    .calls-history .caller-details .call-type,
    .calls-history .opened-calls-detail-box .call-type {
        font-size: 15px;
    }

    /* COMMON CSS FOR CALL HISTORY :: ENDS */

    /* LOGIN PAGE CSS :: STARTS */
    .login-content,
    .reset-password-content {
        padding: 20px 0px 0px;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .login-content .forget-password {
        font-size: 14px;
        line-height: 20px;
        margin-top: 0px;
        margin-bottom: 20px;
    }

    .login-content .login-left-section {
        height: 100%;
    }

    .login-content .login-right-section {
        height: calc(100vh - 20px);
        padding: 60px 75px;
    }

    .login-content .login-right-section h3:first-child {
        font-size: 25px;
        line-height: 40px;
    }

    .login-content .login-right-section h3:nth-child(2) {
        font-size: 30px;
        line-height: 40px;
    }

    .login-content .version-number {
        right: 45px;
        font-size: 13px;
    }

    .login-content .signup-button {
        position: unset;
    }

    /* LOGIN PAGE CSS :: ENDS */

    /* LOGIN WITH OTP PAGE CSS :: STARTS */
    .login-with-otp-content {
        padding: 20px 0px 0px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .login-with-otp-content .login-left-section .dropdown__options {
        top: 35px;
        width: 105px;
    }

    .login-with-otp-content .login-right-section {
        height: calc(100vh - 20px);
        padding: 60px 75px;
    }

    .login-with-otp-content .login-right-section h3:first-child {
        font-size: 25px;
        line-height: 40px;
    }

    .login-with-otp-content .login-right-section h3:nth-child(2) {
        font-size: 30px;
        line-height: 40px;
    }

    .login-with-otp-content .login-right-section .sub-text {
        font-size: 14px;
        line-height: 25px;
        width: 100%;
        margin-top: 10px;
    }

    .login-with-otp-content .version-number {
        right: 45px;
        font-size: 13px;
    }

    /* LOGIN WITH OTP PAGE CSS :: ENDS */

    /* OTP VERIFICATION CSS :: STARTS */
    .otp-verification-content {
        padding: 20px 0px 0px;
    }

    .otp-verification-content .otp-box input {
        height: 50px;
        font-size: 25px;
    }

    .otp-verification-content .time {
        font-size: 16px;
    }

    .otp-verification-content .resend-otp {
        font-size: 14px;
        line-height: 20px;
    }

    .otp-verification-content .login-right-section {
        padding: 60px 75px;
        height: calc(100vh - 20px);
    }

    .otp-verification-content .login-right-section h3:first-child {
        font-size: 25px;
        line-height: 40px;
    }

    .otp-verification-content .login-right-section h3:nth-child(2) {
        font-size: 30px;
        line-height: 40px;
    }

    .otp-verification-content .login-right-section .sub-text {
        font-size: 14px;
        line-height: 25px;
        width: 100%;
        margin-top: 10px;
    }

    .otp-verification-content .version-number {
        right: 60px;
        font-size: 13px;
    }

    /* OTP VERIFICATION CSS :: ENDS */

    /* LOGIN WITH QR PAGE CSS :: STARTS */
    .login-with-qr-content {
        padding: 20px 0px 0px;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .login-with-qr-content .login-left-section {
        height: calc(100vh - 20px);
    }

    .qr-code-content .login-right-section {
        padding: 60px 75px;
        height: calc(100vh - 20px);
    }

    .login-with-qr-content .otp-box input {
        height: 50px;
        font-size: 25px;
    }

    .login-with-qr-content .resend-otp {
        font-size: 14px;
        line-height: 20px;
    }

    .login-with-qr-content .login-right-section {
        padding: 60px 75px;
        height: calc(100vh - 20px);
    }

    .login-with-qr-content .login-right-section h3:first-child {
        font-size: 25px;
        line-height: 40px;
    }

    .login-with-qr-content .login-right-section h3:nth-child(2) {
        font-size: 30px;
        line-height: 40px;
    }

    .login-with-qr-content .login-right-section .sub-text {
        font-size: 14px;
        line-height: 25px;
        width: 100%;
        margin-top: 10px;
    }

    .login-with-qr-content .version-number {
        right: 45px;
        font-size: 13px;
    }

    /* LOGIN WITH QR PAGE CSS :: ENDS */

    /* QR CODE CONTENT PAGE CSS :: STARTS */
    .qr-code-content {
        padding: 20px 0px 0px;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .qr-code-content .login-left-section {
        margin-top: 60px;
    }

    .qr-code-content .login-left-section .error {
        margin-bottom: 30px;
    }

    .qr-code-content .right-side-logo {
        margin-top: 0px;
    }

    .qr-code-content .right-side-logo img {
        width: 240px;
        height: auto;
    }

    .qr-code-content .vectors {
        width: 80%;
    }

    .qr-code-content .version-number {
        font-size: 15px;
        margin-top: 5px;
    }

    /* QR CODE CONTENT PAGE CSS :: ENDS */

    /* CHECK MAIL PAGE CSS :: STARTS */
    .check-mail-content .login-right-section {
        padding: 55px 110px;
        height: calc(100vh - 20px);
    }

    .check-mail-content .right-side-logo {
        margin-top: 100px;
    }

    .check-mail-content .right-side-logo img {
        width: 240px;
        height: auto;
    }

    .check-mail-content .vectors {
        width: 100%;
    }

    .check-mail-content .version-number {
        font-size: 15px;
        margin-top: 5px;
    }

    /* CHECK MAIL PAGE CSS :: ENDS */

    /* SIP CONFIGURATION PAGE CSS :: STARTS */
    .sip-configuration-content .login-left-section .switch {
        height: 30px;
    }

    .sip-configuration-content .login-left-section .slider:before {
        height: 25px;
        width: 25px;
        bottom: 3px;
    }

    .sip-configuration-content .login-left-section .toggle-box span:first-child,
    .sip-configuration-content .login-left-section .radio-box span:first-child {
        font-size: 15px;
    }

    .sip-configuration-img img {
        margin-top: 150px;
    }

    .sip-configuration-footer div p {
        font-size: 15px;
    }

    /* SIP CONFIGURATION PAGE CSS :: ENDS */

    /* SPLASH SCREEN PAGE CSS :: STARTS */
    .splash-screen {
        padding: 0px;
    }

    .splash-screen .loader {
        bottom: 150px;
    }

    /* SPALSH SCREEN PAGE CSS :: ENDS */

    /* SSO LOGIN CSS :: STARTS */
    .sso-login-content .version-number {
        font-size: 13px;
    }

    /* SSO LOGIN CSS :: ENDS */

    /* SSO LOGIN WITH CSS :: STARTS */
    .sso-login-content .login-with p:first-child {
        font-size: 16px;
    }

    /* SSO LOGIN PAGE CSS :: ENDS */

    /* SSO LOGIN WITH PAGE CSS :: STARTS */
    .login-with-other-plattforms .login-with .media-icons {
        margin: 0px;
    }

    .login-with .media-icons img {
        height: 45px;
        width: auto;
    }

    .login-with-other-plattforms .login-with .media-icons p {
        font-size: 14px;
    }

    .login-with-other-plattforms .login-with img {
        margin-top: 25px;
        width: 35px;
    }

    /* SSO LOGIN WITH PAGE CSS :: ENDS */

    /* CALL HISTORY PAGE CSS :: STARTS */
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .caller-name {
        font-size: 18px;
        line-height: 22px;
    }

    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info span {
        font-size: 14px;
    }

    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info img {
        width: 55px;
        height: 55px;
        border-radius: 50%;
    }

    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .green-dot,
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .red-dot,
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .orange-dot,
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .yellow-dot,
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .grey-dot {
        left: 42px;
    }

    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-date .count {
        font-size: 13px;
        height: 18px;
        width: 18px;
    }

    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-date {
        font-size: 13px;
    }

    .call-history-container .right-side-content .call-header .clear-history-dropdown li {
        font-size: 15px;
    }

    .call-history-container .right-side-content .call-header .clear-history-dropdown li svg {
        height: 18px;
    }

    /* CALL HISTORY PAGE CSS :: ENDS */

    /* CALL CONNECTING MINIMIZE PAGE CSS :: STARTS */
    .call-connecting-minimize-container .right-side-content .welcome-text div h3 {
        font-size: 22px;
        line-height: 28px;
    }

    .call-connecting-minimize-container .right-side-content .welcome-text div p {
        font-size: 17px;
    }

    .call-connecting-minimize-container .minmize-call p {
        font-size: 18px;
    }

    /* CALL CONNECTING MINIMIZE PAGE CSS :: ENDS */

    /*BLIND TRANSFER BY NUMBER PAGE CSS :: STARTS */
    .blind-transfer-call-modal .modal-title {
        font-size: 18px;
    }

    .blind-transfer-call-modal .modal-header input[type="text"] {
        font-size: 28px;
    }

    /* .blind-transfer-call-modal .digit {
        height: 60px;
        width: 60px;
        font-size: 24px;
    } */

    /* .blind-transfer-call-modal .sub {
        font-size: 10px;
        margin-top: -7px;
    }

    .blind-transfer-call-modal .call-button {
        height: 60px;
        width: 60px;
    } */

    .blind-transfer-call-modal .call-button svg {
        width: 45%;
    }

    .blind-transfer-call-modal .last_digit {
        font-size: 50px;
    }

    .blind-transfer-call-modal .last_hash {
        font-size: 30px;
    }

    .blind-transfer-call-modal .btn-close svg {
        width: 15px;
        display: block;
    }

    .active-call-transfer .blind-call-minimize div .caller-name {
        font-size: 18px;
    }

    /* .active-call-transfer .blind-call-minimize div .connecting-icon,
    .active-call-transfer .blind-call-minimize div .end-button {
        height: 50px;
        width: 50px;
    } */

    .active-call-transfer .blind-call-minimize div .call-status {
        font-size: 17px;
    }

    /*BLIND TRANSFER BY NUMBER PAGE CSS :: ENDS */

    /* CALL CONNECTING MODAL POPUP CSS :: STARTS */
    .call-connecting-modal .modal-title {
        font-size: 18px;
    }

    .call-connecting-modal .modal-body h3 {
        font-size: 30px;
    }

    .call-connecting-modal .modal-body img {
        height: 120px;
        width: 120px;
    }

    .call-connecting-modal .modal-body p {
        font-size: 22px;
    }

    .call-connecting-modal .modal-body span {
        font-size: 18px;
    }

    .call-connecting-modal .modal-body button {
        margin-top: 60px;
        height: 75px;
        width: 75px;
        margin-bottom: 30px;
    }

    .call-connecting-modal .modal-body button svg {
        width: 60%;
    }

    /* CALL CONNECTING MODAL POPUP CSS :: ENDS */

    /* CALL CONNECTING MAXIMIZE PAGE CSS :: STARTS */
    .call-connecting-maximize-container .maximize-container-header h5 {
        font-size: 20px;
    }

    .call-connecting-maximize-container .call-connecting-info {
        margin: 60px;
    }

    .call-connecting-maximize-container .call-connecting-info h3 {
        font-size: 30px;
    }

    .call-connecting-maximize-container .call-connecting-info img {
        height: 130px;
        width: 130px;
    }

    .call-connecting-maximize-container .call-connecting-info p {
        font-size: 22px;
    }

    .call-connecting-maximize-container .call-connecting-info span {
        font-size: 16px
    }

    .call-connecting-maximize-container .call-connecting-info button {
        margin-top: 90px;
        height: 75px;
        width: 75px;
        outline: unset;
    }

    /* CALL CONNECTING MAXIMIZE PAGE CSS :: ENDS */

    /* INCOMING CALL MODAL POPUP CSS :: STARTS */
    .incoming-call-modal .modal-title {
        font-size: 18px;
    }

    .incoming-call-modal .modal-body h3 {
        font-size: 30px;
    }

    .incoming-call-modal .modal-body img {
        height: 120px;
        width: 120px;
    }

    .incoming-call-modal .modal-body p {
        font-size: 22px;
    }

    .incoming-call-modal .modal-body span {
        font-size: 18px;
    }

    .incoming-call-modal .modal-body button {
        margin-top: 60px;
        height: 75px;
        width: 75px;
        margin-bottom: 30px;
    }

    .incoming-call-modal .modal-body .call-end-btn svg {
        width: 60%;
    }

    .incoming-call-modal .modal-body .accept-call-btn svg {
        height: 50%;
    }

    /* INCOMING CALL MODAL POPUP CSS :: ENDS */

    /* INCOMING CALL MAXIMIZE PAGE CSS :: STARTS */
    .incoming-call-maximize-container .maximize-container-header h5 {
        font-size: 20px;
    }

    .incoming-call-maximize-container .call-connecting-info {
        margin: 60px;
    }

    .incoming-call-maximize-container .call-connecting-info h3 {
        font-size: 30px;
    }

    .incoming-call-maximize-container .call-connecting-info img {
        height: 130px;
        width: 130px;
    }

    .incoming-call-maximize-container .call-connecting-info p {
        font-size: 22px;
    }

    .incoming-call-maximize-container .call-connecting-info span {
        font-size: 16px
    }

    .incoming-call-maximize-container .call-connecting-info .call-button-container {
        outline: unset;
    }

    .incoming-call-maximize-container .call-connecting-info .call-button-container button {
        height: 75px;
        width: 75px;
    }

    .incoming-call-maximize-container .call-connecting-info .call-button-container button:last-child svg {
        height: 45%;
    }

    /* INCOMING CALL MAXIMIZE PAGE CSS :: ENDS */

    /* ACTIVE CALL PAGE CSS :: STARTS */
    .active-call-container .call-buttons-option-container {
        bottom: 20px;
    }

    .active-call-container .call-time {
        font-size: 15px !important;
        padding: 5px 10px !important;
    }

    .active-call-container .call-buttons-option-container button {
        height: 65px;
        width: 65px;
    }

    .active-call-container .call-buttons-option-container button svg {
        height: 25px;
        width: 25px;
    }

    .active-call-container .call-buttons-option-container .end-button svg {
        width: 70%;
    }

    .active-call-container .call-buttons-option-container button:nth-child(7) svg {
        height: 45%;
        width: 60%;
    }

    .active-call-container .call-buttons-option-container button:nth-child(9) svg {
        height: 100%;
        width: 60%;
    }

    /* ACTIVE CALL PAGE CSS :: ENDS */

    /* MAKE A CALL PAGE CSS :: STARTS */
    .dialpad-modal .modal-title {
        font-size: 18px;
    }

    .dialpad-modal .modal-header input[type="text"] {
        font-size: 28px;
    }

    /* .dialpad-modal .digit {
        height: 60px;
        width: 60px;
        font-size: 24px;
    }

    .dialpad-modal .sub {
        font-size: 10px;
    } */

    /* .dialpad-modal .call-button,
    .dialpad-modal .video-call-button,
    .dialpad-modal .back-button {
        height: 60px;
        width: 60px;
    }

    .dialpad-modal .call-button svg,
    .dialpad-modal .video-call-button svg,
    .dialpad-modal .back-button svg {
        width: 45%;
    }

    .dialpad-modal .add-to-contact {
        font-size: 15px;
    } */

    /* .dialpad-modal .last_digit {
        font-size: 50px;
    }

    .dialpad-modal .last_hash {
        font-size: 30px;
    }

    .dialpad-modal .btn-close {
        top: 10px;
    }

    .dialpad-modal .btn-close svg {
        width: 15px;
        display: block;

    } */

    /* MAKE A CALL PAGE CSS :: ENDS */

    /* TRANSFERED CALL PAGE CSS :: STARTS */
    .transfered-call-modal-popup .modal-body svg {
        width: 45px;
    }

    .transfered-call-modal-popup .modal-body p {
        font-size: 20px;
        line-height: 30px;
        margin: 30px auto 10px auto;
    }

    .transfered-call-modal-popup .modal-body .number {
        margin: 10px auto 30px auto;
        margin-top: 15px;
        font-size: 20px;
    }

    /* TRANSFERED CALL PAGE CSS :: ENDS */

    /* CONTAC SEARCH RESULTS DROPDOWN MENU :: STARTS */
    .contact-list-menu span.caller-avtar {
        font-size: 22px !important;
        height: 50px !important;
        width: 50px !important;
    }

    .contact-list-menu span.caller-avtar svg {
        height: 70%;
        width: auto;
    }

    .contact-list-menu div p {
        font-size: 16px;
    }

    .contact-list-menu div span {
        font-size: 13px;
    }

    /* CONTAC SEARCH RESULTS DROPDOWN MENU :: ENDS */

    /* SETTING - LOGOUT PAGE CSS :: STARTS */
    .setting-conatiner .nav-pills .nav-link {
        font-size: 16px;
        margin-bottom: 15px !important;
    }

    .logout-modal-popup .modal-body svg {
        width: 45px;
    }

    .logout-modal-popup .modal-body p {
        font-size: 20px;
        line-height: 30px;
        margin: 30px auto 10px auto;
    }

    .logout-modal-popup .modal-body .number {
        margin: 10px auto 30px auto;
        margin-top: 15px;
        font-size: 20px;
    }

    /* SETTING - LOGOUT PAGE CSS :: ENDS */

    /* ALL CONTACTS PAGE CSS :: STARTS */
    .contact-container .call-header h3 {
        font-size: 22px;
    }

    .contact-list .caller-details h6 {
        font-size: 18px;
    }

    .contact-list .caller-details p {
        font-size: 15px;
    }

    .contact-list span.person {
        height: 55px;
        width: 55px;
    }

    .all-contacts-container .right-side-content .call-options span {
        height: 35px;
        width: 35px;
    }

    /* ALL CONTACTS PAGE CSS :: ENDS */

    /* CONTACT DETAIL PAGE CSS :: STARTS */
    .contact-detail-container .call-option span {
        width: 35px;
        height: 35px;
        display: block;
        background: var(--link-color);
        border-radius: 50%;
        margin: 0px 10px;
        justify-content: center;
        align-items: center;
        display: flex;
        --bs-gutter-x: 0rem;
    }

    .contact-detail-container .call-option span svg {
        width: 18px;
    }

    .contact-detail-container .number-details label {
        font-size: 15px;
    }

    .contact-detail-container .number-details p {
        font-size: 18px;
    }

    /* CONTACT DETAIL PAGE CSS :: ENDS */

    /* SYNC CONTACTS PAGE CSS :: STARTS */
    .sync-contact-container .sync-details h3 {
        font-size: 22px;
    }

    .sync-contact-container .switch {
        height: 30px;
    }

    .sync-contact-container .slider:before {
        height: 25px;
        width: 25px;
        bottom: 3px;
    }

    .sync-contact-container .sync-details .sync-with p {
        font-size: 17px;
    }

    .sync-contact-container .sync-details .sync-with span {
        font-size: 13px;
    }

    /* SYNC CONTACTS PAGE CSS :: ENDS  */

    /* CALL ATTANDANT PAGE PAGE CSS :: STARTS */
    .active-call-attandant-transfer-container .active-call-attandant-header h5 {
        font-size: 20px;
    }

    .active-call-attandant-transfer-container .call-connecting-info {
        margin: 15px;
        justify-content: start !important
    }

    .active-call-attandant-transfer-container .call-connecting-info h3 {
        font-size: 30px;
    }

    .active-call-attandant-transfer-container .call-time {
        font-size: 15px !important;
        padding: 5px 10px !important;
    }

    .active-call-attandant-transfer-container .call-connecting-info img {
        height: 130px;
        width: 130px;
    }

    .active-call-attandant-transfer-container .call-connecting-info p {
        font-size: 22px;
    }

    .active-call-attandant-transfer-container .call-connecting-info span {
        font-size: 16px
    }

    .active-call-attandant-transfer-container .call-buttons-option-container {
        bottom: 20px;
    }

    .active-call-attandant-transfer-container .call-buttons-option-container button {
        height: 65px;
        width: 65px;
    }

    .active-call-attandant-transfer-container .call-connecting-info button svg {
        height: 25px;
        width: 25px;
    }

    .active-call-attandant-transfer-container .call-buttons-option-container .end-button svg {
        width: 70%;
    }

    /* CALL ATTANDANT PAGE PAGE CSS :: ENDS */

    /*BLIND TRANSFER BY NUMBER PAGE CSS :: STARTS */
    .attandant-transfer-modal .modal-title {
        font-size: 18px;
    }

    .attandant-transfer-modal .modal-header input[type="text"] {
        font-size: 28px;
    }

    /* .attandant-transfer-modal .digit {
        height: 60px;
        width: 60px;
        font-size: 24px;
    }

    .attandant-transfer-modal .sub {
        font-size: 10px;
        margin-top: -7px
    }

    .attandant-transfer-modal .call-button {
        height: 60px;
        width: 60px;
    }

    .attandant-transfer-modal .call-button svg {
        width: 45%;
    }

    .attandant-transfer-modal .last_digit {
        font-size: 50px;
    }

    .attandant-transfer-modal .last_hash {
        font-size: 30px;
    }

    .attandant-transfer-modal .btn-close svg {
        width: 15px;
        display: block;
    } */

    .active-call-attandant-transfer-container .attandant-minimize-call-popup div .caller-name {
        font-size: 18px;
    }

    .active-call-attandant-transfer-container .attandant-minimize-call-popup div .connecting-icon,
    .active-call-attandant-transfer-container .attandant-minimize-call-popup div .end-button {
        height: 50px;
        width: 50px;
    }

    .active-call-attandant-transfer-container .attandant-minimize-call-popup div .call-status {
        font-size: 17px;
    }

    /* BLIND TRANSFER BY NUMBER PAGE CSS :: ENDS */

    /* ATTANDANT TRANSFER CALL ON HOLD PAGE PAGE CSS :: STARTS */
    .attandant-transfer-hold-container .attandant-header h5 {
        font-size: 20px;
    }

    .attandant-transfer-hold-container .call-buttons-option-container button {
        height: 65px;
        width: 65px;
    }

    .attandant-transfer-hold-container .call-connecting-info button svg {
        height: 25px;
        width: 25px;
    }

    .attandant-transfer-hold-container .call-buttons-option-container .end-button svg {
        width: 70%;
    }

    .attandant-transfer-hold-container .call-info-box {
        padding: 6px 150px;
    }

    .attandant-transfer-hold-container .call-info-box h3 {
        font-size: 25px;
    }

    .attandant-transfer-hold-container .call-info-box p {
        font-size: 18px;
    }

    .attandant-transfer-hold-container .call-info-box .person {
        height: 115px;
        width: 115px;
    }

    .attandant-transfer-hold-container .call-info-box .call-status {
        font-size: 13px
    }

    .attandant-transfer-hold-container .call-info-box .call-time {
        font-size: 13px
    }

    .attandant-transfer-hold-container .call-info-box .call-status svg {
        height: 11px;
        width: 11px;
    }

    /* ATTANDANT TRANSFER CALL ON HOLD PAGE PAGE CSS :: ENDS */

    /* ONGOING CALL ON HOLD PAGE PAGE CSS :: STARTS */
    .ongoing-call-hold-container .attandant-header h5 {
        font-size: 20px;
    }

    .ongoing-call-hold-container .call-buttons-option-container button {
        height: 65px;
        width: 65px;
    }

    .ongoing-call-hold-container .call-connecting-info button svg {
        height: 25px;
        width: 25px;
    }

    .ongoing-call-hold-container .call-connecting-info button:nth-child(7) svg {
        height: 100%;
        width: 60%;
    }

    .ongoing-call-hold-container .call-buttons-option-container .end-button svg {
        width: 70%;
    }

    .ongoing-call-hold-container .call-info-box {
        padding: 50px 150px;
    }

    .ongoing-call-hold-container .call-info-box h3 {
        font-size: 25px;
    }

    .ongoing-call-hold-container .call-info-box p {
        font-size: 18px;
    }

    .ongoing-call-hold-container .call-info-box .person {
        height: 115px;
        width: 115px;
    }

    .ongoing-call-hold-container .call-info-box .call-status {
        font-size: 13px
    }

    .ongoing-call-hold-container .call-info-box .call-time {
        font-size: 13px
    }

    .ongoing-call-hold-container .call-info-box .call-status svg {
        height: 11px;
        width: 11px;
    }

    /* ONGOING CALL ON HOLD PAGE PAGE CSS :: ENDS */

    /*NO CALL HISTORY FOUND PAGES  CSS :: STARTS */
    .call-history-container .no-calls p {
        font-size: 22px;
    }

    .no-missed-calls p {
        font-size: 22px;
    }

    /*NO CALL HISTORY FOUND PAGES  CSS :: ENDS */

    /* VIDEO CALL CONNECTING MODAL POPUP CSS :: STARTS */
    .videocall-connecting-modal .modal-title {
        font-size: 18px;
    }

    .videocall-connecting-modal .modal-body h3 {
        font-size: 30px;
    }

    .videocall-connecting-modal .modal-body img {
        height: 120px;
        width: 120px;
    }

    .videocall-connecting-modal .modal-body p {
        font-size: 22px;
    }

    .videocall-connecting-modal .modal-body span {
        font-size: 18px;
    }

    .videocall-connecting-modal .modal-body button {
        margin-top: 60px;
        height: 75px;
        width: 75px;
        margin-bottom: 30px;
    }

    .videocall-connecting-modal .modal-body button svg {
        width: 60%;
    }

    /* VIDEO CALL CONNECTING MODAL POPUP CSS :: ENDS */

    /* CALL CONNECTING MINIMIZE PAGE CSS :: STARTS */
    .video-call-minmize-popup p {
        font-size: 18px;
    }

    /* CALL CONNECTING MINIMIZE PAGE CSS :: ENDS */

    /* VIDEO CALL CONNECTING MAXIMIZE PAGE CSS :: STARTS */
    .videocall-connecting-maximize-container .maximize-container-header h5 {
        font-size: 20px;
    }

    .videocall-connecting-maximize-container .call-connecting-info {
        margin: 60px;
    }

    .videocall-connecting-maximize-container .call-connecting-info h3 {
        font-size: 30px;
    }

    .videocall-connecting-maximize-container .call-connecting-info img {
        height: 130px;
        width: 130px;
    }

    .videocall-connecting-maximize-container .call-connecting-info p {
        font-size: 22px;
    }

    .videocall-connecting-maximize-container .call-connecting-info span {
        font-size: 16px
    }

    .videocall-connecting-maximize-container .call-connecting-info button {
        margin-top: 90px;
        height: 75px;
        width: 75px;
        outline: unset;
    }

    /* VIDEO CALL CONNECTING MAXIMIZE PAGE CSS :: ENDS */

    /* INCOMING VIDEO CALL MODAL POPUP CSS :: STARTS */
    .incoming-videocall-modal .modal-title {
        font-size: 18px;
    }

    .incoming-videocall-modal .modal-body h3 {
        font-size: 30px;
    }

    .incoming-videocall-modal .modal-body img {
        height: 120px;
        width: 120px;
    }

    .incoming-videocall-modal .modal-body p {
        font-size: 22px;
    }

    .incoming-videocall-modal .modal-body span {
        font-size: 18px;
    }

    .incoming-videocall-modal .modal-body button {
        margin-top: 60px;
        height: 75px;
        width: 75px;
        margin-bottom: 30px;
    }

    .incoming-videocall-modal .modal-body .call-end-btn svg {
        width: 60%;
    }

    .incoming-videocall-modal .modal-body .accept-call-btn svg {
        height: 50%;
    }

    /* INCOMING VIDEO CALL MODAL POPUP CSS :: ENDS */

    /* INCOMING VIDEO CALL MAXIMIZE PAGE CSS :: STARTS */
    .incoming-videocall-maximize-container .maximize-container-header h5 {
        font-size: 20px;
    }

    .incoming-videocall-maximize-container .call-connecting-info {
        margin: 60px;
    }

    .incoming-videocall-maximize-container .call-connecting-info h3 {
        font-size: 30px;
    }

    .incoming-videocall-maximize-container .call-connecting-info img {
        height: 130px;
        width: 130px;
    }

    .incoming-videocall-maximize-container .call-connecting-info p {
        font-size: 22px;
    }

    .incoming-videocall-maximize-container .call-connecting-info span {
        font-size: 16px
    }

    .incoming-videocall-maximize-container .call-connecting-info .call-button-container {
        outline: unset;
        position: unset;
    }

    .incoming-videocall-maximize-container .call-connecting-info .call-button-container button {
        height: 75px;
        width: 75px;
    }

    .incoming-videocall-maximize-container .call-connecting-info .call-button-container button:last-child svg {
        height: 45%;
    }

    /* INCOMING VIDEO CALL MAXIMIZE PAGE CSS :: ENDS */

    /* ACTIVE VIDEO CALL PAGE CSS :: STARTS */
    .video-call-popup .local-peer {
        width: 330px;
        height: 200px;
        bottom: 80px;
    }

    .video-call-popup .call-buttons button {
        height: 55px;
        width: 55px;
    }

    .video-call-popup .call-time {
        font-size: 18px;
        line-height: 30px;
        padding: 0px 12px;
        bottom: 10px;
    }

    .video-call-popup .call-header p {
        font-size: 22px;
    }

    .video-call-popup span.name {
        font-size: 18px;
    }

    /* ACTIVE VIDEO CALL PAGE CSS :: ENDS */

    /* ACTIVE VIDEO CALL MINIMIZE POPUP CSS :: STARTS */
    .videocall-minimize span.name {
        font-size: 14px;
        top: 5px;
    }

    /* ACTIVE VIDEO CALL MINIMIZE POPUP CSS :: ENDS */

    /* CONFERENCE CALL ON HOLD PAGE CSS :: STARTS*/
    .conference-call-onhold .call-connecting-info {
        height: calc(100vh - 69px);
    }

    .conference-call-onhold .conference-call-info .caller-info,
    .active-call-attandent-info .conference-call-info .caller-info {
        font-size: 24px;
        margin-top: 10px;
    }

    .conference-call-onhold .conference-call-info .conference-time,
    .active-call-attandent-info .conference-call-info .conference-time {
        font-size: 22px;
        margin-bottom: 10px;
    }


    /* CONFERENCE CALL ON HOLD PAGE CSS :: ENDS*/

    /* ONGOING CALL HOLDHOVER PAGE CSS :: STARTS*/

    .ongoing_call_holdhover_container .conference-call-info .caller-info {
        font-size: 24px;
        margin-top: 10px;
    }

    .ongoing_call_holdhover_container .conference-call-info .conference-time {
        font-size: 22px;
        margin-bottom: 10px;
    }

    .ongoing_call_holdhover_container .call-buttons-option-container button {
        margin-top: 25px !important;
    }

    /* ONGOING CALL HOLDHOVER PAGE CSS :: ENDS*/

    /* APPLICATION SETTING PAGE CSS :: STARTS */
    .application-settings .settings {
        padding: 20px;
    }

    .application-settings .setting-header h3 {
        font-size: 22px;
    }

    .application-settings .settings li p {
        font-size: 18px;
    }

    .application-settings .settings li span {
        font-size: 13px;
    }

    .application-settings .switch {
        height: 30px;
    }

    .application-settings .switch .slider:before {
        height: 25px;
        width: 25px;
        bottom: 3px;
    }

    /* APPLICATION SETTING PAGE CSS :: ENDS */

    /* LANGUAGE SETTING PAGE CSS :: STARTS */
    .language-settings .settings {
        padding: 20px;
    }

    .language-settings .setting-header h3 {
        font-size: 22px;
    }

    .language-settings .settings li p {
        font-size: 18px;
    }

    /* LANGUAGE SETTING PAGE CSS :: ENDS */

    /* TEST CONNECTION SETTING PAGE CSS :: STARTS */
    .test-connection-settings .settings {
        padding: 20px;
    }

    .test-connection-settings .setting-header h3 {
        font-size: 22px;
    }

    .settings-for-activecall .complete-svg {
        width: 110px;
        height: 110px;
    }

    .settings-for-activecall .date-time p {
        font-size: 15px;
    }

    /* TEST CONNECTION SETTING PAGE CSS :: ENDS */

    /* DEBUGGING LOGS PAGE CSS :: STARTS */
    .debugging-logs-settings .setting-header h3,
    .websocket-test h3 {
        font-size: 22px;
    }

    .debugging-logs-settings .logs-confirmation button {
        height: 45px;
        font-size: 17px !important;
    }

    .websocket-test .status-box svg {
        height: 110px;
        width: 110px;
    }

    .websocket-test .status-box p {
        font-size: 17px;
    }

    /* DEBUGGING LOGS PAGE CSS :: ENDS */

    /* HOME CHAT PAGE CSS :: STARTS */

    .home-chats-containers .right-side-content .welcome-text div h3 {
        font-size: 22px;
        line-height: 28px;
    }

    .home-chats-containers .right-side-content .welcome-text div p {
        font-size: 17px;
    }

    .home-chats-containers .left-menu-content .recent-calls .recent-call-info .caller-name {
        font-size: 18px;
        line-height: 22px;
    }

    .home-chats-containers .left-menu-content .recent-calls .recent-call-info span {
        font-size: 14px;
    }

    .home-chats-containers .left-menu-content .recent-calls .recent-call-info img {
        width: 55px;
        height: 55px;
        border-radius: 50%;
    }

    .home-chats-containers .left-menu-content .recent-calls .recent-call-info .green-dot {
        left: 42px;
    }

    .home-chats-containers .left-menu-content .recent-calls .recent-call-date .count {
        font-size: 13px;
        height: 18px;
        width: 18px;
    }

    .home-chats-containers .left-menu-content .recent-calls .recent-call-date {
        font-size: 13px;
    }

    .home-chats-containers .right-side-content .call-header .clear-history-dropdown li {
        font-size: 15px;
    }

    .home-chats-containers .right-side-content .call-header .clear-history-dropdown li svg {
        height: 18px;
    }

    /* HOME CHAT PAGE CSS :: ENDS */

    /* SEND DIRECT MESSAGE PAGE CSS :: STARTS */
    .start-new-chat-content input[type="search"] {
        height: 45px;
    }

    .start-new-chat-content .next-arrow {
        width: 40px;
        height: 40px;
    }

    .start-new-chat-content h3 {
        font-size: 22px;
    }

    /* SEND DIRECT MESSAGE PAGE CSS :: ENDS */

    /* CREATE MESSAGE PAGE CSS :: STARTS */
    .create-group-content .header {
        font-size: 22px;
    }

    .create-group-content input[type="search"] {
        height: 45px;
    }

    .create-group-content .next-arrow {
        width: 40px;
        height: 40px;
    }

    .create-group-content h3 {
        font-size: 22px;
    }

    /* CREATE MESSAGE PAGE CSS :: ENDS */

    /* ADD MEMBER PAGE CSS :: STARTS */
    .add-group-member-content .group-img {
        height: 170px;
        width: 170px;
    }

    .add-group-member-content h3 {
        font-size: 22px;
    }

    .add-group-member-content input[type="search"] {
        height: 45px;
    }

    .add-group-member-content .next-arrow {
        width: 40px;
        height: 40px;
    }

    /* ADD MEMBER PAGE CSS :: ENDS */

    /* CHAT CONTENT CSS CSS :: STARTS */
    .home-chats-containers .chat-header .pic,
    .home-chats-containers .chat-area .sender-msg .pic,
    .home-chats-containers .chat-area .reciver-msg .pic,
    .home-chats-containers .chat-area .forward-msg .pic {
        height: 45px;
        width: 45px !important;
    }

    .home-chats-containers .chat-header .pic svg,
    .home-chats-containers .chat-area .sender-msg .pic svg,
    .home-chats-containers .chat-area .reciver-msg .pic svg,
    .home-chats-containers .chat-area .forward-msg .pic svg {
        width: 60%;
    }

    .home-chats-containers .chat-header .pic img,
    .home-chats-containers .chat-area .sender-msg .pic img,
    .home-chats-containers .chat-area .reciver-msg .pic img,
    .home-chats-containers .chat-area .forward-msg .pic img {
        width: 100%;
        height: 100%;
    }

    .home-chats-containers .chat-header p {
        font-size: 22px;
    }

    .home-chats-containers .chat-header span,
    .home-chats-containers .chat-area .sender-msg .time,
    .home-chats-containers .chat-area .reciver-msg .time,
    .home-chats-containers .chat-area .forward-msg .time {
        font-size: 13px !important;
    }

    .home-chats-containers .chat-area .date-time,
    .home-chats-containers .chat-area .typing {
        font-size: 12px;
    }

    .home-chats-containers .chat-area .sender-msg p,
    .home-chats-containers .chat-area .reciver-msg p,
    .home-chats-containers .chat-area .forward-msg p,
    .home-chats-containers .enter-chat-area input[type="text"],
    .home-chats-containers .document-box div p,
    .home-chats-containers .msg .video-container .size-mb,
    .home-chats-containers .msg .image-conatiner .size-mb,
    .home-chats-containers .reciver-msg .document-box {
        font-size: 15px !important;
    }

    .home-chats-containers .msg .caption {
        font-size: 14px;
    }

    .home-chats-containers .chat-area .sender-msg .msg-box,
    .home-chats-containers .chat-area .reciver-msg .msg-box,
    .home-chats-containers .chat-area .forward-msg .msg-box {
        font-size: 15px;
    }

    .home-chats-containers .enter-chat-area .send-btn {
        font-size: 16px;
        padding: 8px 15px;
    }

    .home-chats-containers .chat-header .clear-history-dropdown li,
    .home-chats-containers .pin-chat-dropdown li {
        font-size: 15px;
    }

    .home-chats-containers .chat-options li:not(:last-child) {
        margin-bottom: 15px;
    }

    .home-chats-containers .chat-options li svg,
    .home-chats-containers .chat-header .clear-history-dropdown li svg,
    .home-chats-containers .pin-chat-dropdown li svg {
        height: 20px;
        width: auto;
        margin-right: 8px;
    }

    .forward-msg-modal .modal-title {
        font-size: 18px;
    }

    .forward-msg-modal .modal-footer .send-btn {
        font-size: 15px;
        padding: 8px 15px;
    }

    /* CHAT CONTENT CSS :: ENDS */

    /* CHAT ATTACHMENT OPTIONS PAGE :: CSS */
    .attachment-options li {
        font-size: 15px;
    }

    .attachment-options li svg {
        height: 18px;
        width: auto;
    }

    /* CHAT SETTING PAGE CSS :: STARTS */
    .home-chats-containers .take-photo-content p {
        font-size: 20px;
    }

    .home-chats-containers .take-photo-content .pic {
        margin-bottom: 35px;
    }

    .home-chats-containers .take-photo-content button {
        height: 50px;
    }

    /* CHAT SETTING PAGE CSS :: ENDS */

    /* PHOTO PREVIEW PAGE CSS :: STARTS */
    .home-chats-containers .preview-area {
        margin-bottom: 100px;
    }

    .home-chats-containers .photo-content .pic {
        height: 400px;
        width: 400px;
    }

    .home-chats-containers .photo-content .input-field input[type=text] {
        height: 50px;
    }

    .home-chats-containers .preview-area button {
        font-size: 16px;
        padding: 8px 15px;
    }

    .home-chats-containers .preview-area button .counter {
        height: 24px;
        width: 24px;
        font-size: 15px;
        line-height: 25px;
    }

    /* PHOTO PREVIEW PAGE CSS :: ENDS */

    /* VIDEO PREVIEW PAGE CSS :: STARTS */
    .home-chats-containers .player {
        height: unset;
    }

    .home-chats-containers .video-box .player_button {
        height: 25px;
        width: 25px;
    }

    .home-chats-containers .video-box .fa,
    .home-chats-containers .video-box .time {
        font-size: 13px;
    }

    .home-chats-containers .preview-area {
        position: unset !important;
    }

    .home-chats-containers .preview-content {
        height: unset !important;
    }

    /* VIDEO PREVIEW PAGE CSS :: ENDS */

    /* UPLOADING/DOWNLOADING SMALL ICON CSS :: STARTS */
    .download-sm {
        width: 22px !important;
        height: 22px !important;
    }

    /* UPLOADING/DOWNLOADING SMALL ICON CSS :: ENDS */

    /* OTHER USER PROFILE PAGE CSS :: STARTS */
    .home-chats-containers .user-profile-content .profile-header h3 {
        font-size: 22px;
    }

    .home-chats-containers .user-profile-content .profile-info img {
        width: 110px;
        height: 110px;
    }

    .home-chats-containers .user-profile-content .profile-info .name {
        font-size: 20px;
    }

    .home-chats-containers .user-profile-content .profile-info .number {
        font-size: 16px;
    }

    .home-chats-containers .user-profile-content .actions li {
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 20px;
    }

    .home-chats-containers .user-profile-content .shared-media-info {
        margin-top: 20px;
    }

    .home-chats-containers .user-profile-content .shared-media-info .medias div {
        height: 70px;
        width: 70px;
    }

    /* OTHER USER PROFILE PAGE CSS :: ENDS */

    /* MEDIA PREVIEW PAGE CSS :: STARTS */
    .media-tabs .document-previews,
    .media-tabs .media-preivew {
        width: 100%;
    }

    .media-preview-modal .mySlides {
        width: 340px;
        height: 340px;
        margin-top: 0px;
    }

    .media-preview-modal .audio-slide .audio {
        margin-top: 50px;
    }

    /* MEDIA PREVIEW PAGE CSS :: EDNS */
    .dailpad-dropdown .dail-header input[type="text"] {
        font-size: 30px;
    }

    .dailpad-dropdown .keypad-div .digit {
        height: 60px;
        width: 60px;
    }

    /* MESSAGE HOME PAGE CSS :: STARTS */
    .home-message-containers .message-home-content input[type="search"] {
        height: 45px;
    }

    .home-message-containers .message-home-content .next-arrow {
        width: 40px;
        height: 40px;
    }

    .home-message-containers .message-home-content h3 {
        font-size: 22px;
    }

    .home-chats-containers .right-side-content .welcome-text div h3 {
        font-size: 22px;
        line-height: 28px;
    }

    .home-chats-containers .right-side-content .welcome-text div p {
        font-size: 17px;
    }

    .home-message-containers .left-menu-content .recent-calls .recent-call-info .caller-name {
        font-size: 18px;
        line-height: 22px;
    }

    .home-message-containers .left-menu-content .recent-calls .recent-call-info span {
        font-size: 14px;
    }

    .home-message-containers .left-menu-content .recent-calls .recent-call-info img {
        width: 55px;
        height: 55px;
        border-radius: 50%;
    }

    .home-message-containers .left-menu-content .recent-calls .recent-call-info .green-dot {
        left: 42px;
    }

    .home-message-containers .left-menu-content .recent-calls .recent-call-date .count,
    .left-menu-content .accordion li a .count {
        font-size: 13px;
        height: 18px;
        width: 18px;
    }

    .home-message-containers .left-menu-content .recent-calls .recent-call-date {
        font-size: 13px;
    }

    .home-message-containers .right-side-content .call-header .clear-history-dropdown li {
        font-size: 15px;
    }

    .home-message-containers .right-side-content .call-header .clear-history-dropdown li svg {
        height: 18px;
    }

    /* MESSAGE HOME PAGE CSS :: ENDS */

    /* MESSAGE BODY CSS :: STARTS */
    .home-message-containers .chat-header .pic {

        height: 45px;
        width: 45px !important;
    }

    .home-message-containers .chat-header .pic svg,
    .home-message-containers .chat-area .sender-msg .pic svg,
    .home-message-containers .chat-area .reciver-msg .pic svg {
        width: 60%;
    }

    .home-message-containers .chat-header .pic img,
    .home-message-containers .chat-area .sender-msg .pic img,
    .home-message-containers .chat-area .reciver-msg .pic img,
    .home-message-containers .chat-area .forward-msg .pic img {
        width: 100%;
    }

    .home-message-containers .chat-header span,
    .home-message-containers .chat-area .sender-msg .time,
    .home-message-containers .chat-area .reciver-msg .time,
    .home-message-containers .chat-area .forward-msg .time {
        font-size: 13px !important;
    }

    .home-message-containers .chat-area .date-time,
    .home-message-containers .chat-footer .note {
        font-size: 12px;
    }

    .home-message-containers .chat-area .sender-msg p,
    .home-message-containers .chat-area .reciver-msg p,
    .home-message-containers .chat-area .forward-msg p,
    .home-message-containers .enter-chat-area textarea {
        font-size: 15px !important;
    }

    .home-message-containers .chat-area .sender-msg .msg-box,
    .home-message-containers .chat-area .reciver-msg .msg-box,
    .home-message-containers .chat-area .forward-msg .msg-box {
        font-size: 15px;
    }

    .home-message-containers .enter-chat-area .send-btn {
        font-size: 16px;
        padding: 8px 15px;
    }

    .home-message-containers .chat-header .clear-history-dropdown li {
        font-size: 15px;
    }

    .home-message-containers .chat-options li:not(:last-child) {
        margin-bottom: 15px;
    }

    .home-message-containers .chat-options li svg,
    .home-message-containers .chat-header .clear-history-dropdown li svg,
    .home-message-containers .pin-chat-dropdown li svg {
        height: 20px;
        width: auto;
        margin-right: 8px;
    }

    .home-message-containers .chat-header p {
        font-size: 22px;
    }

    .forward-msg-modal .modal-title {
        font-size: 18px;
    }

    .forward-msg-modal .modal-footer .send-btn {
        font-size: 14px;
        padding: 5px 15px;
    }

    .forward-msg-modal .modal-footer .taglist span {
        font-size: 12px;
    }

    /* MESSAGE BODY CSS :: ENDS */
}

/* PORTRAIT AND LANDSCAPE */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {

    /* HEADER */
    header .navbar {
        padding-right: 0px;
    }

    header .nav-item {
        margin: 0px 4px;
    }

    /* HEADER */

    /* LEFT MENU CSS :: STARTS */
    .left-menu-content ul li .accordion-body button {
        font-size: 14px;
    }

    .left-menu-content {
        height: calc(100vh - 80px);
    }

    /* LEFT MENU CSS :: ENDS */

    /* LEFT SIDE CURVES DESIGN POSITION CSS :: STARTS*/
    .check-mail-content .login-right-section,
    .login-with-otp-content .login-right-section,
    .login-with-qr-content .login-right-section,
    .qr-code-content .login-right-section,
    .otp-verification-content .login-right-section,
    .login-content .login-right-section {
        background-position: 100% 20%;
        ;
    }

    /* LEFT SIDE CURVES DESIGN POSITION CSS :: ENDS */


    /* LOGIN PAGE CSS :: STARTS */
    .login-content,
    .login-with-qr-content {
        overflow: hidden !important;
    }

    .login-content .signup-button {
        position: absolute;
    }

    /* LOGIN PAGE CSS :: ENDS */

    /* LOGIN WITH OTP PAGE CSS :: STARTS */
    .login-with-otp-content {
        overflow: hidden;
    }

    /* LOGIN WITH OTP PAGE CSS :: ENDS */

    /* CALL HISTORY COMMON CSS :: STARTS */
    .calls-history .caller-details h6 {
        font-size: 15px;
    }

    .calls-history .card .card-header span.person {
        height: 45px;
        width: 45px;
        font-size: 20px !important;
    }

    .calls-history .card .card-header span.person svg {
        height: 30px;
    }

    .calls-history .card .card-header span.person img {
        width: 100%;
    }

    .calls-history .caller-details p,
    .calls-history .caller-details .call-type,
    .calls-history .opened-calls-detail-box .call-type {
        font-size: 14px;
    }

    /* CALL HISTORY COMMON CSS :: ENDS */

    /* CALL CONNECTING MINIMIZE PAGE CSS :: STARTS */
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info img {
        width: 50px;
        height: 50px;
    }

    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .green-dot,
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .red-dot,
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .orange-dot,
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .yellow-dot,
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .grey-dot {
        left: 38px;
        top: 10px;
    }

    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .caller-name {
        font-size: 13px;
    }

    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info span,
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-date {
        font-size: 12px;
    }

    .call-connecting-minimize-container .minmize-call p {
        font-size: 15px;
    }

    .call-connecting-minimize-container .minmize-call p span:last-child {
        font-size: 13px;
    }

    /* CALL CONNECTING MINIMIZE PAGE CSS :: ENDS */

    /* CALL CONNECTING MAXIMIZE PAGE CSS :: STARTS */
    .call-connecting-maximize-container .call-connecting-info button {
        position: absolute;
    }

    .call-connecting-maximize-container .call-connecting-info button svg {
        width: 70%;
    }

    /* CALL CONNECTING MAXIMIZE PAGE CSS :: ENDS */

    /* INCOMING CALL MINIMIZE PAGE CSS :: STARTS */
    .incoming-call-minimize-modal button {
        margin: 5px !important;
    }

    .incoming-call-minimize-modal p {
        font-size: 15px;
        padding-right: 0px;
    }

    .incoming-call-minimize-modal p span:last-child {
        font-size: 13px;
    }

    /* INCOMING CALL MINIMIZE PAGE CSS :: ENDS */

    /* INCOMING CALL MAXIMIZE PAGE CSS :: STARTS */
    .incoming-call-maximize-container .call-connecting-info .call-button-container {
        position: absolute;
    }

    .incoming-call-maximize-container .call-connecting-info button svg {
        width: 70%;
    }

    /* INCOMING CALL MAXIMIZE PAGE CSS :: ENDS */

    /* ACTIVE CALL PAGE CSS :: STARTS */
    .active-call-container .call-buttons-option-container {
        position: absolute;
    }

    /* ACTIVE CALL PAGE CSS :: ENDS */

    /* INCOMING CALL WHILE AUDIO CALL IS GOING ON PAGE CSS :: STARTS */
    .incoming-call-popup p {
        font-size: 15px;
    }

    .incoming-call-popup p span:last-child {
        font-size: 13px;
    }

    /* INCOMING CALL WHILE AUDIO CALL IS GOING ON PAGE CSS :: ENDS */

    /* ACTIVE CALL TRANSFER PAGE CSS :: STARTS */
    .active-call-transfer .dropdown-menu {
        right: -110px !important;
    }

    /* ACTIVE CALL TRANSFER PAGE CSS :: ENDS */

    /* ALL CONTATCS PAGE CSS :: STARTS */
    .left-menu-content .accordion {
        padding: 20px 5px !important;
    }

    .all-contacts-container .nav-pills .nav-link {
        font-size: 16px;
    }

    .all-contacts-container .contact-list span.person {
        height: 50px;
        width: 50px;
        font-size: 26px;
    }

    .all-contacts-container .contact-list span.person img {
        width: 100%;
    }

    .all-contacts-container .contact-list span.person svg {
        height: 65%;
        width: auto;
    }

    .all-contacts-container .right-side-content .call-options span svg {
        width: 55% !important;
        height: auto;
    }

    /* ALL CONTATCS PAGE CSS :: ENDS */

    /* ATTANDANT CALL TRANSFER ON HOLD PAGE CSS :: STARTS */
    .attandant-transfer-hold-container .call-info-box {
        padding: 50px 150px !important;
    }

    /* ATTANDANT CALL TRANSFER ON HOLD PAGE CSS :: ENDS */

    /* ONGOING CALL TRANSFER ON HOLD PAGE CSS :: STARTS */
    .ongoing-call-hold-container .call-info-box {
        padding: 50px 150px !important;
    }

    /* ONGOING CALL TRANSFER ON HOLD PAGE CSS :: ENDS */

    /* ACTIVE CALL ATTANDANT PAGE CSS :: STARTS */
    .active-call-attandant-header .dropdown-menu {
        right: -110px !important;
    }

    /* ACTIVE CALL ATTANDANT PAGE CSS :: ENDS */

    /* ATTANDANT TRANSFER CALL ON HOLD PAGE CSS :: STARTS */
    .attandant-transfer-hold-container .call-buttons-option-container {
        position: absolute;
    }

    /* ATTANDANT TRANSFER CALL ON HOLD PAGE CSS :: ENDS */

    /* INCOMING VIDEO CALL MINIMIZE PAGE CSS :: STARTS */
    .incoming-call-minimize-modal button {
        margin: 5px !important;
    }

    .incoming-call-minimize-modal p {
        font-size: 15px;
        padding-right: 0px;
    }

    .incoming-call-minimize-modal p span:last-child {
        font-size: 13px;
    }

    /* INCOMING VIDEO CALL MINIMIZE PAGE CSS :: ENDS */

    /* ACTIVE VIDEO CALL PAGE CSS :: STARTS */
    .video-call-popup .call-time {
        bottom: 20px;
    }

    /* ACTIVE VIDEO CALL PAGE CSS :: ENDS */

    /* ONGOING CALL HOLDHOVER PAGE CSS :: STARTS*/
    .ongoing_call_holdhover_container .call-connecting-info {
        justify-content: center;
        margin-top: 0px;
    }

    .conference-call-info {
        margin-bottom: 50px;
    }

    /* ONGOING CALL HOLDHOVER PAGE CSS :: ENDS*/

    /* CHAT CONTENT :: STARTS */
    .home-chats-containers .chat-header p {
        width: unset !important;
    }

    .home-chats-containers .chat-header {
        width: 100%;
    }

    .home-chats-containers .chat-footer {
        position: absolute;
    }

    .home-chats-containers .chat-header input[type=search] {
        width: 250px !important;
    }

    /* CHAT CONTENT :: ENDS */

    /* PREVIEW VIDEO CSS :: STARTS */
    .home-chats-containers .preview-area {
        position: absolute !important;
        margin-bottom: 0px;
    }

    .home-chats-containers .preview-content {
        height: calc(100vh - 90px) !important;
    }

    /* PREVIEW VIDEO CSS :: ENDS */

    .media-preview-modal .mySlides {
        width: 380px;
        height: 380px;
    }

}

/* IPAD */
@media only screen and (min-width: 768px) and (max-width:1024px) {

    /* HEADER CSS :: STARTS */
    .navbar .container-fluid {
        justify-content: flex-start !important;
    }

    header .navbar {
        height: unset;
    }

    button.navbar-toggler {
        position: absolute;
        right: 20px;
        top: 10px
    }

    button.navbar-toggler:focus {
        outline: unset;
        border: unset;
        box-shadow: unset;
    }

    /* HEADER CSS :: ENDS */

    /* LEFT MENU CSS :: STARTS */
    .left-menu-content ul li .accordion-body button {
        font-size: 14px !important;
    }

    /* LEFT MENU CSS :: ENDS */

    .left-menu-content {
        height: calc(100vh - 80px);
    }

    .right-side-content {
        height: 100%;
    }

    .login-content .login-left-section {
        margin: 30px;
    }

    .login-content .login-right-section .laptop-img {
        width: 100%;
    }

    .login-content .login-right-section {
        width: 100%;
    }

    /* LOGIN PAGE CSS :: ENDS  */

    /* LOGIN WITH OTP PAGE CSS :: STARTS */

    .login-with-otp-content .login-left-section {
        margin: 30px;
        height: 100%;
    }

    .login-with-otp-content .login-right-section {
        width: 100%;
        height: calc(100vh - 20px);
    }


    .login-with-otp-content .login-right-section .laptop-img,
    .otp-verification-content .login-right-section .laptop-img,
    .login-with-qr-content .login-right-section .right-vector img,
    .qr-code-content .right-vector img,
    .check-mail-content .vectors img,
    .login-with-qr-content .login-right-section .laptop-img {
        width: 100%;
    }

    /* LOGIN WITH OTP PAGE CSS :: ENDS  */

    /* OTP VERIFICATION PAGE CSS :: STARTS */
    .otp-verification-content .login-left-section {
        margin: 30px;
    }

    .otp-verification-content .signup-button button {
        position: unset;
    }

    .otp-verification-content .login-left-section {
        height: 50vh;
    }

    .otp-verification-content .login-right-section {
        width: 100%;
    }

    .otp-verification-content .login-right-section .laptop-img {
        width: 100%;
    }

    /* OTP VERIFICATION PAGE CSS :: ENDS  */

    /* LOGIN WITH QR PAGE CSS :: STARTS */

    .login-with-qr-content .login-left-section {
        margin: 30px;
    }

    .login-with-qr-content .signup-button button {
        position: unset;
    }

    .login-with-qr-content .login-left-section {
        height: 100%;
    }

    .login-with-qr-content .login-right-section {
        width: 100%;
    }

    .login-with-qr-content .login-right-section .laptop-img {
        width: 100%;
    }

    /* LOGIN WITH QR PAGE CSS :: ENDS  */

    /* QR CODE PAGE CSS :: STARTS */

    .qr-code-content .login-left-section {
        margin: 50px;
    }

    .qr-code-content .vectors {
        width: 100%;
    }

    /* QR CODE PAGE CSS :: ENDS */

    /* CHECK MAIL PAGE CSS :: STARTS */
    .check-mail-content .login-left-section {
        margin: 50px;
    }

    /* CHECK MAIL PAGE CSS :: ENDS */

    /* SIP CONFIGURATION PAGE CSS :: STARTS */


    .sip-configuration-container::before {
        width: 55%;
    }

    .sip-configuration-container {
        padding: 10px 30px !important;
    }

    .sip-configuration-container .logo {
        margin-bottom: 25px;
    }

    .sip-configuration-content .login-left-section .toggle-box {
        margin: 15px auto;
    }

    .sip-configuration-content .login-left-section .gradient-btn {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .sip-configuration-container .back {
        margin-bottom: 15px;
    }

    .sip-configuration-img img {
        margin-top: 180px;
    }

    .sip-configuration-content .login-left-section .radio-box {
        width: 660px;
    }

    .sip-configuration-content .login-left-section .radio-box span:first-child,
    .sip-configuration-content .login-left-section .toggle-box span:first-child {
        width: 200px;
    }

    .sip-configuration-footer {
        padding: 0px;
    }

    /* SIP CONFIGURATION PAGE CSS :: ENDS */


    /* SPLASH SCREEN PAGE CSS :: STARTS */
    .splash-screen .loader {
        bottom: 300px;
    }

    /* SPALSH SCREEN PAGE CSS :: ENDS */


    /* SSO LOGIN PAGE CSS :: STARTS */
    .sso-login .login-left-section {
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 50px;
        padding: 20PX;
    }

    .sso-login-content .login-right-section {
        padding: 90px 30px;
        margin-top: 50px;
    }

    .sso-login .version-number {
        font-size: 20px;
    }

    /* SSO LOGIN PAGE CSS :: ENDS */

    /* GET STARTED PAGE CSS :: STARTS */
    .calls-history,
    .call-history-container .right-side-content .call-header {
        width: 100%;
    }

    .calls-history .caller-details h6 {
        font-size: 14px;
    }

    .calls-history .call-duration p {
        margin-right: 0px !important;
    }

    .dashboard-container .right-side-content {
        padding: 0px;
    }

    /* GET STARTED PAGE CSS :: ENDS */

    /* CALL HISTORY PAGE CSS :: STARTS */
    input[type=search] {
        width: 250px;
    }

    .calls-history .card .card-header span.person {
        height: 40px;
        width: 40px;
    }

    .calls-history .card .card-header span.person img {
        width: 100%;
    }

    .calls-history .card .card-header span.mx-3.caller-name {
        width: 185px;
    }

    /* CALL HISTORY PAGE CSS :: ENDS */

    /* CALL CONNECTING MINIMIZE PAGE CSS :: STARTS */
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info img {
        width: 50px;
        height: 50px;
    }

    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .green-dot,
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .red-dot,
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .orange-dot,
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .yellow-dot,
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .grey-dot {
        left: 40px;
        top: 5px;
    }

    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info .caller-name {
        font-size: 13px;
    }

    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-date {
        width: 60px;
    }

    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-info span,
    .call-connecting-minimize-container .left-menu-content .recent-calls .recent-call-date {
        font-size: 12px;
    }

    .call-connecting-minimize-container .right-side-content .welcome-text button {
        padding: 5px 50px;
    }

    .call-connecting-minimize-container .right-side-content .welcome-text div p {
        font-size: 15px;
    }

    .call-connecting-minimize-container .right-side-content .calls-history {
        max-height: 800px;
    }

    /* CALL CONNECTING MINIMIZE PAGE CSS :: ENDS */

    /* CALL CONNECTING MAXIMIZE PAGE CSS :: STARTS */
    .call-connecting-maximize-container .call-connecting-info button svg {
        width: 70%;
    }

    /* CALL CONNECTING MAXIMIZE PAGE CSS :: ENDS */

    /* INCOMING CALL MAXIMIZE PAGE CSS :: STARTS */
    .incoming-call-maximize-container .call-connecting-info .call-button-container {
        position: absolute;
    }

    .incoming-call-maximize-container .call-connecting-info button svg {
        width: 70%;
    }

    /* INCOMING CALL MAXIMIZE PAGE CSS :: END */

    /* ACTIVE CALL PAGE CSS :: STARTS */
    .active-call-container .call-buttons-option-container {
        position: absolute;
    }

    .active-call-container .call-buttons-option-container button {
        height: 60px;
        width: 60px;
        margin: 8px !important;
    }

    /* ACTIVE CALL PAGE CSS :: END */

    /* INCOMING CALL MAXIMIZE PAGE CSS :: STARTS */
    .incoming-call-minimize-modal p {
        padding-right: 0px;
    }

    .incoming-call-maximize-container .call-connecting-info h3 {
        font-size: 25px;
    }

    .incoming-call-maximize-container .call-connecting-info img {
        width: 110px;
        height: 110px;
    }

    .active-call-transfer .dropdown-menu {
        right: -110px;
    }

    /* INCOMING CALL MAXIMIZE PAGE CSS :: ENDS */

    /* ALL CONTATCS PAGE CSS :: STARTS */
    .left-menu-content .accordion {
        padding: 20px 5px !important;
    }

    .all-contacts-container .nav-pills .nav-link {
        font-size: 16px;
    }

    .all-contacts-container .right-side-content .call-header {
        width: 100%;
    }

    .all-contacts-container .right-side-content .call-options span svg {
        width: 70%;
    }

    .contact-container .call-header h3 {
        font-size: 20px;
    }

    /* ALL CONTATCS PAGE CSS :: ENDS */

    /* SYNC CONTACT PAGE CSS :: STARTS */
    .sync-contact-container .call-header h3 {
        font-size: 20px;
    }

    /* SYNC CONTACT PAGE CSS :: ENDS */

    /* FAVOURITE CONTACT PAGE CSS :: STARTS */
    .favourite-container .call-header h3 {
        font-size: 20px;
    }

    .favourite-container .call-header span {
        font-size: 15px
    }

    /* FAVOURITE CONTACT PAGE CSS :: ENDS */

    /* ACTIVE CALL ATTANDANT PAGE CSS :: STARTS */
    .active-call-attandant-transfer-container .call-connecting-info img {
        width: 110px;
        height: 110px;
    }

    .active-call-attandant-transfer-container .call-buttons-option-container button {
        height: 60px;
        width: 60px;
        margin: 8px !important;
    }

    .active-call-attandant-transfer-container .call-connecting-info h3 {
        font-size: 25px;
    }

    .active-call-attandant-header .dropdown-menu {
        right: -110px;
    }

    /* ACTIVE CALL ATTANDANT PAGE CSS :: ENDS */

    /* ATTANDANT TRANSFER CALL ON HOLD PAGE CSS :: STARTS */
    .attandant-transfer-hold-container .call-connecting-info img {
        width: 110px;
        height: 110px;
    }

    .attandant-transfer-hold-container .call-buttons-option-container button {
        height: 60px;
        width: 60px;
        margin: 8px !important;
    }

    /* ATTANDANT TRANSFER CALL ON HOLD PAGE CSS :: ENDS */

    /* ONGOING CALL ON HOLD PAGE CSS :: STARTS */
    .ongoing-call-hold-container .call-connecting-info img {
        width: 110px;
        height: 110px;
    }

    .ongoing-call-hold-container .call-buttons-option-container button {
        height: 60px;
        width: 60px;
        margin: 8px !important;
    }

    /* ONGOING CALL ON HOLD PAGE CSS :: ENDS */

    /* ATTANDANT CALL TRANSFER ON HOLD PAGE CSS :: STARTS */
    .attandant-transfer-hold-container .call-info-box {
        padding: 30px 80px;
    }

    .attandant-transfer-hold-container .call-buttons-option-container {
        position: absolute;
    }

    /* ATTANDANT CALL TRANSFER ON HOLD PAGE CSS :: ENDS */

    /* ONGOING CALL TRANSFER ON HOLD PAGE CSS :: STARTS */
    .ongoing-call-hold-container .call-info-box {
        padding: 30px 80px;
    }

    .ongoing-call-hold-container .call-buttons-option-container {
        position: absolute;
    }

    /* ONGOING CALL TRANSFER ON HOLD PAGE CSS :: ENDS */


    /* VIDEO CALL CONNECTING MAXIMIZE PAGE CSS :: STARTS */
    .videocall-connecting-maximize-container .call-connecting-info button svg {
        width: 70%;
    }

    /* VIDEO CALL CONNECTING MAXIMIZE PAGE CSS :: ENDS */

    /* INCOMING VIDEO CALL MAXIMIZE PAGE CSS :: STARTS */
    .incoming-videocall-maximize-container .call-connecting-info h3 {
        font-size: 25px;
    }

    .incoming-videocall-maximize-container .call-connecting-info img {
        width: 110px;
        height: 110px;
    }

    .incoming-videocall-maximize-container .call-connecting-info .call-button-container {
        position: absolute;
    }

    .incoming-videocall-maximize-container .call-connecting-info button svg {
        width: 70%;
    }

    /* INCOMING VIDEO CALL MAXIMIZE PAGE CSS :: END */

    /* ACTIVE VIDEO CALL PAGE CSS :: STARTS */
    .video-call-popup .local-peer {
        width: 255px;
        height: 200px;
    }

    .video-call-popup span.name {
        top: 0px;
    }

    .video-call-popup svg.switch-arrow {
        top: 10px;
        right: 10px;
    }

    .video-call-popup .call-time {
        bottom: 20px;
    }

    /* ACTIVE VIDEO CALL PAGE CSS :: ENDS */

    /* ONGOING CALL HOLDHOVER PAGE CSS :: STARTS*/
    .ongoing_call_holdhover_container .call-connecting-info {
        justify-content: center;
        margin-top: 0px;
    }

    .conference-call-info {
        margin-bottom: 50px;
    }

    /* ONGOING CALL HOLDHOVER PAGE CSS :: ENDS*/

    /* TEST CONNECTION SETTING PAGE CSS :: STARTS*/
    .test-connection-settings .settings li:not(:last-child) {
        margin-bottom: 10px;
    }

    .test-connection-settings .settings li p {
        font-size: 13px !important;
    }

    /* TEST CONNECTION SETTING PAGE CSS :: ENDS*/

    /* HOME CHAT PAGE CSS :: STARTS */
    .home-chats-containers .left-menu-content .recent-calls .recent-call-info img {
        width: 50px;
        height: 50px;
    }

    .home-chats-containers .left-menu-content .recent-calls .recent-call-info .green-dot {
        left: 38px;
        top: 10px;
    }

    .home-chats-containers .left-menu-content .recent-calls .recent-call-info .caller-name {
        font-size: 13px;
    }

    .home-chats-containers .left-menu-content .recent-calls .recent-call-date {
        width: 60px;
    }

    .home-chats-containers .left-menu-content .recent-calls .recent-call-info span,
    .home-chats-containers .left-menu-content .recent-calls .recent-call-date {
        font-size: 12px;
    }

    .home-chats-containers .right-side-content .welcome-text button {
        padding: 5px 50px;
    }

    .home-chats-containers .right-side-content .welcome-text div p {
        font-size: 15px;
    }

    .home-chats-containers .right-side-content .calls-history {
        max-height: 800px;
    }

    .home-chats-containers .card .card-header span.person {
        height: 40px;
        width: 40px;
    }

    .home-chats-containers .card .card-header span.person img {
        width: 100%;
    }

    .home-chats-containers .card .card-header span.mx-3.caller-name {
        width: 185px;
    }

    /* HOME CHAT PAGE CSS :: ENDS */

    /* SEND DIRECT MESSAGE PAGE CSS :: STARTS */
    .start-new-chat-content .searchbar {
        width: 100%;
    }

    .home-chats-containers .chat-header input[type="search"]:focus,
    .home-chats-containers .chat-header input[type="search"]:focus-within,
    .home-chats-containers .chat-header .search-area:focus input[type="search"],
    .home-chats-containers .chat-header .search-area:focus-within input[type="search"] {
        width: 350px;
    }

    /* SEND DIRECT MESSAGE PAGE CSS :: ENDS */

    /* CREATE GROUP PAGE CSS :: STARTS */
    .create-group-content .searchbar {
        width: 100%;
    }

    /* CREATE GROUP PAGE CSS :: ENDS */

    /* ADD MEMBER PAGE CSS :: STARTS */
    .add-group-member-content .searchbar {
        width: 100%;
    }

    /* ADD MEMBER PAGE CSS :: ENDS */

    /* GROUP CHAT PAGE CSS :: STARTS */
    .home-chats-containers .chat-header {
        width: 100%;
    }

    .home-chats-containers .chat-footer {
        position: absolute;
    }

    .home-chats-containers .chat-header p {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    /* GROUP CHAT PAGE CSS :: ENDS */

    /* CHAT MOREOPTIONS / ONE TO ONE CHAT PAGE CSS :: STARTS */
    .home-chats-containers .chat-header {
        width: 100%;
    }

    .home-chats-containers .chat-footer {
        position: absolute;
    }

    /* .home-chats-containers .chat-header p,
    .home-chats-containers .chat-header span {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    } */

    .home-chats-containers .chat-options {
        right: 10px;
    }

    /* CHAT MORE OPTIONS / ONE TO ONE CHAT PAGE CSS :: ENDS */

    /* PREVIEW PHOTO PAGE CSS :: STARTS */
    .home-chats-containers .photo-content .pic {
        height: 350px;
        width: 350px;
    }

    .home-chats-containers .photo-content .preview-area button {
        position: relative;
        right: 0px;
    }

    /* PREVIEW PHOTO PAGE CSS :: ENDS */

    /* GLOBAL SIZE CSS FOR DIAL PAD :: STRATS */
    .modal-dialog {
        max-width: 500px !important;
    }

    /* GLOBAL SIZE CSS FOR DIAL PAD :: ENDS */

    /* MESSAGE BODY CSS :: STARTS */
    .home-message-containers .left-menu-content .recent-calls .recent-call-info img {
        width: 50px;
        height: 50px;
    }

    .home-message-containers .left-menu-content .recent-calls .recent-call-info .green-dot {
        left: 38px;
        top: 10px;
    }

    .home-message-containers .left-menu-content .recent-calls .recent-call-info .caller-name {
        font-size: 13px;
    }

    .home-message-containers .left-menu-content .recent-calls .recent-call-date {
        width: 60px;
    }

    .home-message-containers .left-menu-content .recent-calls .recent-call-info span,
    .home-message-containers .left-menu-content .recent-calls .recent-call-date {
        font-size: 12px;
    }

    /* MESSAGE BODY CSS :: ENDS */
}

/* SMALL DEVICE (MOBILE) */
@media only screen and (max-width: 600px) {

    /* COMMON CSS :: STARTS */

    .sub-heading {
        margin-bottom: 40px;
    }

    .common-btn {
        width: 100%;
    }

    /* COMMON CSS :: ENDS */

    /* HEADER CSS :: STARTS */
    header .navbar {
        padding: 15px 5px;
        height: unset;
    }

    header .navbar .navbar-brand svg {
        height: 45px;
    }

    header .navbar .navbar-brand .logo-header {
        height: 45px;
    }

    header .profile-info img {
        height: 40px;
        width: 40px;
    }

    .profile a#navbarDropdown {
        margin: 0px;
        padding: 0px;
    }

    header .nav-item {
        margin: 0px;
    }

    .d-flex .nav-item .nav-link {
        padding-left: 0px !important;
    }

    header .active-dot {
        left: 28px;
        top: 3px;
    }

    /* HEADER CSS :: ENDS  */


    /* SSO LOGIN PAGE CSS :: STARTS */
    .sso-login .login-left-section {
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 50px;
    }

    .sso-login .login-left-section .common-btn {
        margin-top: 0px !important;
    }

    .sso-login .right-side-logo img {
        width: 100%;
    }

    .sso-login .version-number {
        font-size: 16px;
    }

    /* CALL HISTORY COMMON CSS :: STARTS */
    .calls-history {
        display: block;
        position: relative;
        width: 100%;
    }

    .calls-history .card-header .call {
        flex: 0;
    }

    .calls-history .card .card-header span.person img,
    .conference-call span.person,
    .conference-call span.person-second img {
        width: 100%;
    }

    .calls-history .caller-details h6 {
        font-size: 14px;
    }

    .calls-history .caller-details p,
    .calls-history .caller-details .call-type,
    .calls-history .opened-calls-detail-box .call-type {
        font-size: 12px;
        margin-right: 0px;
    }

    .calls-history .card .card-header span.person,
    .conference-call span.person,
    .conference-call span.person-second {
        height: 45px;
        width: 45px;
        font-size: 22px;
    }

    .calls-history .card .card-header span.person svg {
        width: 50%;
    }

    /* CALL HISTORY COMMON CSS :: ENDS */

    /* LOGIN PAGE CSS :: STARTS  */
    .login-content .login-left-section {
        margin: 20px;
    }

    .login-content .login-right-section {
        display: none;
        margin-top: 50px;
        width: 100%;
        padding: 75px 50px;
    }

    .login-content .login-right-section .laptop-img {
        top: 150px;
        width: 380px;
    }

    .login-content .login-right-section .mobile-img {
        left: 25px;
        height: 250px;
    }

    /* LOGIN PAGE CSS :: ENDS  */

    /* LOGIN WITH OTP PAGE CSS :: STARTS  */
    .login-with-otp-content .login-left-section {
        margin: 20px;
    }

    .login-with-otp-content .login-right-section {
        display: none;
        margin-top: 50px;
        width: 100%;
        padding: 75px 50px;
    }

    .login-with-otp-content .login-right-section .laptop-img {
        top: 150px;
        width: 380px;
    }

    .login-with-otp-content .login-right-section .mobile-img {
        left: 25px;
        height: 250px;
    }

    /* LOGIN WITH OTP PAGE CSS :: ENDS  */

    /* OTP VERIFICATION PAGE CSS :: STARTS  */
    .otp-verification-content .login-left-section {
        margin: 20px;
    }

    .otp-verification-content .login-right-section {
        display: none;
        margin-top: 50px;
        width: 100%;
        padding: 75px 50px;
    }

    .otp-verification-content .login-right-section .laptop-img {
        top: 355px;
        width: 380px;
        right: 0;
    }

    .otp-verification-content .login-right-section .mobile-img {
        left: 25px;
        height: 250px;
    }

    /* OTP VERIFICATION PAGE CSS :: ENDS  */

    /* LOGIN WITH QR PAGE CSS :: STARTS  */
    .login-with-qr-content .login-left-section {
        margin: 20px;
    }

    .login-with-qr-content .login-right-section {
        display: none;
        margin-top: 50px;
        width: 100%;
        padding: 75px 50px;
    }

    .login-with-qr-content .login-right-section .laptop-img {
        top: 355px;
        width: 380px;
        right: 0;
    }

    .login-with-qr-content .login-right-section .mobile-img {
        left: 25px;
        height: 250px;
    }

    /* LOGIN WITH QR PAGE CSS :: ENDS  */

    /* QR CODE PAGE CSS :: STARTS */
    .qr-code-content .login-left-section {
        margin: 0px;
        height: 100vh;
    }

    .qr-code-content .login-left-section .qr-box::after {
        left: -5px;
    }

    .qr-code-content .login-left-section .qr-box::before {
        height: 104%;
    }

    .qr-code-content .login-left-section .qr-box::after {
        width: 104%;
    }

    .qr-code-content .login-left-section .error {
        margin-bottom: 100px;
    }

    .qr-code-content .login-right-section {
        display: none;
        padding: 90px 30px;
    }

    .qr-code-content .vectors {
        width: 100%;
    }

    /* QR CODE PAGE CSS :: ENDS */

    /* CHECK MAIL PAGE CSS :: STARTS */
    .check-mail-content .login-left-section {
        margin: 0px;
        height: 100vh;
    }

    .check-mail-content .login-right-section {
        display: none;
        padding: 90px 30px;
    }

    .check-mail-content .vectors {
        width: 100%;
    }

    /* CHECK MAIL PAGE CSS :: ENDS */

    /* SIP CONFIGURATION PAGE CSS :: STARTS */
    .sip-configuration-content .login-left-section {
        position: unset;
    }

    .sip-configuration-container {
        padding: 30px !important;
    }

    .sip-configuration-content {
        height: unset;
    }

    .sip-configuration-content .login-left-section .radio-box {
        display: block;
    }

    .sip-configuration-content .login-left-section .radio-box span:first-child {
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }

    .sip-configuration-content .login-left-section .radio-box p {
        display: inline-block;
        width: 49%;
        margin-bottom: 15px !important;
    }

    .sip-configuration-img {
        display: none;
    }

    .sip-configuration-footer {
        padding: 10px 30px;
    }

    /* SIP CONFIGURATION PAGE CSS :: ENDS */

    /* SSO LOGIN PAGE CSS :: STARTS */
    .sso-login-content .login-left-section {
        margin-left: 0px;
        margin-top: 0px;
    }

    .sso-login-content .login-right-section {
        padding: 90px 30px;
        margin-top: 50px;
    }

    .sso-login-content .right-side-logo img {
        width: 100%;
    }

    /* SSO LOGIN PAGE CSS :: ENDS */

    /* ALL CALLS PAGE CSS :: STARTS */
    .call-history-container .right-side-content .call-header {
        display: block;
        width: 100%;
    }

    .call-history-container .right-side-content .call-header h3 {
        margin-bottom: 20px;
        font-size: 18px;
    }

    /* ALL CALLS PAGES CSS :: ENDS */

    /* CALL CONNCTING MINIMIZE PAGE CSS :: STARTS */
    .call-connecting-minimize-container .right-side-content .welcome-text {
        flex-direction: column;
    }

    .call-connecting-minimize-container .right-side-content .welcome-text button {
        margin-top: 30px;
    }

    .call-connecting-minimize-container .right-side-content .welcome-text div h3,
    .call-connecting-minimize-container .right-side-content .welcome-text div p {
        text-align: center;
    }

    /* CALL CONNCTING MINIMIZE PAGE CSS :: ENDS */

    /* CALL CONNECTING MAXIMIZE PAGE CSS :: STARTS */
    .call-connecting-maximize-container .call-connecting-info h3 {
        font-size: 25px;
    }

    .call-connecting-maximize-container .call-connecting-info img {
        width: 120px;
        height: 120px;
    }

    .call-connecting-maximize-container .call-connecting-info p {
        font-size: 20px;
    }

    .call-connecting-maximize-container .call-connecting-info span {
        font-size: 15px;
    }

    .call-connecting-maximize-container .call-connecting-info button {
        position: absolute;
        bottom: 50px;
    }

    .call-connecting-maximize-container .call-connecting-info button svg {
        width: 70%;
    }

    /* CALL CONNECTING MAXIMIZE PAGE CSS :: ENDS */

    /* INCOMING CALL MODAL POPUP CSS :: STATRS */
    .incoming-call-modal {
        z-index: 1;
    }

    .incoming-call-modal .modal-body button {
        margin: 60px 25px !important;
    }

    /* INCOMING CALL MODAL POPUP CSS :: ENDS */

    /* INCOMING CALL MAXIMIZE PAGE CSS :: STARTS */
    .incoming-call-maximize-container .call-connecting-info h3 {
        font-size: 25px;
    }

    .incoming-call-maximize-container .call-connecting-info img {
        width: 120px;
        height: 120px;
    }

    .incoming-call-maximize-container .call-connecting-info p {
        font-size: 20px;
    }

    .incoming-call-maximize-container .call-connecting-info span {
        font-size: 15px;
    }

    .incoming-call-maximize-container .call-connecting-info .call-button-container {
        position: absolute;
        bottom: 50px;
    }

    .incoming-call-maximize-container .call-connecting-info button svg {
        width: 70%;
    }

    /* INCOMING CALL MAXIMIZE PAGE CSS :: ENDS */

    /* ACTIVE CALL PAGE CSS :: STARTS */
    .active-call-container .call-buttons-option-container {
        position: unset;
    }

    /* ACTIVE CALL PAGE CSS :: ENDS */

    /*BLIND TRANSFER BY NUMBER PAGE CSS :: STARTS */
    .active-call-transfer .blind-call-minimize div .caller-name {
        font-size: 13px;
    }

    .active-call-transfer .blind-call-minimize div .connecting-icon,
    .active-call-transfer .blind-call-minimize div .end-button {
        height: 45px;
        width: 45px;
    }

    .active-call-transfer .blind-call-minimize div .call-status {
        font-size: 12px;
    }

    /*BLIND TRANSFER BY NUMBER PAGE CSS :: ENDS */

    /* TRANSFERED CALL PAGE CSS :: STARTS */
    .transfered-call-modal-popup .modal-body p {
        font-size: 20px;
        line-height: 30px;
        margin: 30px auto 10px auto;
    }

    .transfered-call-modal-popup .modal-body .number {
        margin: 10px auto 30px auto;
        margin-top: 15px;
        font-size: 18px;
    }

    /* TRANSFERED CALL PAGE CSS :: ENDS */

    /* SETTING - LOGOUT PAGE CSS :: STARTS */
    .logout-modal-popup .modal-body p {
        font-size: 20px;
        line-height: 30px;
        margin: 30px auto 10px auto;
    }

    .logout-modal-popup .modal-body .number {
        margin: 10px auto 30px auto;
        margin-top: 15px;
        font-size: 18px;
    }

    /* SETTING - LOGOUT PAGE CSS :: ENDS */

    /* ALL CONTACTS PAGE CSS :: STARTS */
    .left-menu-content .accordion {
        padding: 20px 5px !important;
    }

    .all-contacts-container .nav-pills .nav-link {
        font-size: 16px;
    }

    .all-contacts-container .contact-list {
        width: 100%;
    }

    .contact-container .call-header {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        text-align: left;
        justify-content: flex-start;
    }

    .contact-container .call-header h3 {
        margin-bottom: 18px;
        font-size: 20px;
    }

    .caller-details:hover .call-options {
        justify-content: space-evenly !important;
        margin-top: 15px;
    }

    .contact-list .caller-details .call {
        flex: 0;
    }

    /* ALL CONTACTS PAGE CSS :: ENDS */

    /* CONTACT DETAIL PAGE CSS :: STARTS */
    .contact-detail-container .call-header {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .contact-detail-container .contact-list {
        padding: 20px 10px;
    }

    .contact-detail-container .call-option span {
        margin: 0px 5px;
    }

    /* CONTACT DETAIL PAGE CSS :: ENDS */

    /* SYNC CONTACT PAGE CSS :: STARTS */
    .sync-contact-container .call-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: start;
    }

    .sync-contact-container .call-header h3 {
        margin-bottom: 0px;
        font-size: 18px;
    }

    .sync-contact-container .sync-details {
        padding: 15px;
    }

    .sync-contact-container .sync-details img {
        width: 60%;
    }

    .sync-contact-container .sync-details h3 {
        font-size: 18px;
    }

    .sync-contact-container .sync-details .sync-with p {
        font-size: 15px;
    }

    .sync-contact-container .sync-details .sync-with span {
        font-size: 12px;
    }

    /* SYNCS CONTACT PAGE CSS :: ENDS */

    /* FAVOURITE CONTACT PAGE CSS :: STARTS */
    .favourite-container .call-header {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: start;
        padding: 0px;
        margin-bottom: 20px;
    }

    .favourite-container .call-header h3 {
        margin-bottom: 10px;
        font-size: 18px;
    }

    /* FAVOURITE CONTACT PAGE CSS :: ENDS */

    /* ACTIVE CALL ATTANDANT TRANSFER PAGE CSS :: STARTS */
    .active-call-attandant-transfer-container .call-connecting-info h3 {
        font-size: 25px;
    }

    .active-call-attandant-transfer-container .call-connecting-info img {
        width: 120px;
        height: 120px;
    }

    .active-call-attandant-transfer-container .call-connecting-info p {
        font-size: 20px;
    }

    .active-call-attandant-transfer-container .call-buttons-option-container {
        position: unset;
    }

    /* ACTIVE CALL ATTANDANT TRANSFER PAGE CSS :: ENDS */

    /* VIDEO CALL CONNECTING MAXIMIZE PAGE CSS :: STARTS */
    .videocall-connecting-maximize-container .call-connecting-info button svg {
        width: 70%;
    }

    /* VIDEO CALL CONNECTING MAXIMIZE PAGE CSS :: ENDS */

    /* INCOMING VIDEO CALL MAXIMIZE PAGE CSS :: STARTS */
    .incoming-videocall-maximize-container .call-connecting-info h3 {
        font-size: 25px;
    }

    .incoming-videocall-maximize-container .call-connecting-info img {
        width: 120px;
        height: 120px;
    }

    .incoming-videocall-maximize-container .call-connecting-info p {
        font-size: 20px;
    }

    .incoming-videocall-maximize-container .call-connecting-info span {
        font-size: 15px;
    }

    .incoming-videocall-maximize-container .call-connecting-info .call-button-container {
        position: absolute;
        bottom: 50px;
    }

    .incoming-videocall-maximize-container .call-connecting-info button svg {
        width: 70%;
    }

    /* INCOMING VIDEO CALL MAXIMIZE PAGE CSS :: ENDS */
}

@media only screen and (min-width: 741px) and (max-width:1008px) {

    .login-content,
    .reset-password-content,
    .login-with-otp-content,
    .login-with-qr-content {
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .login-content .login-right-section .laptop-img,
    .login-with-otp-content .login-right-section .laptop-img,
    .otp-verification-content .login-right-section .laptop-img {
        top: unset;
        bottom: 50px;
    }
}


.call-conference-detail {
    font-size: 24px;
    margin-top: 10px;
    color: var(--black-color);
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
}

.hide-timer {
    display: none !important;
}

.SuccessfulBtn {
    background: rgba(38, 171, 35, 0.4);
    opacity: 0.9;
    border: 0.8px solid var(--active-call-color);
    padding: 3px 10px;
    width: fit-content !important;
    margin: auto;
    border-radius: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: var(--black-color);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
}

.SuccessfulBtn-text {
    color: var(--black-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.disable-click {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
}

.icon {
    width: 20px;
    height: 20px;
    color: var(--primary-color)
}

.error_msg {
    color: red;
    font-weight: 500;
    padding-top: 3px
}

.language_label::before {
    top: 6px !important;
    left: 0 !important;
}

.language_label::after {
    content: "\2713" !important;
    top: 6px !important;
    left: 6px !important;
    background: transparent !important;
}

@media only screen and (max-width: 767px) {
    .video-call-popup {
        height: calc(100vh - 159px) !important;
    }
}

.appVersion {
    position: absolute;
    z-index: 9999999;
    bottom: 10px;
    right: 10px;
    color: var(--primary-color);
}