:root {
    --primary-color: #614B98;
    /* --primary-color: #4f6eb4; */
    --auth-button-color:#4f6eb4;
    --secondary-color: #57A1D7;
    /* --main-gradient-color: linear-gradient(223.54deg, #614B98 -21.27%, #6533E2 6.23%, #7349DE 35.08%, #1F97EF 99.36%, #57A1D7 147.44%); */
    --main-gradient-color: linear-gradient(223.54deg, #614B98 -21.27%, #6533E2 0%, #7349DE 7%, #4f6eb4 99.36%, #57A1D7 147.44%);
    --button-gradient: linear-gradient(270deg, #6533E2 0.26%, #7349DE 30.28%, #1F97EF 97.16%);
    --light-background: #F0F3FB;
    --hover_states_light: #F7FAFD;
    --minimize-popup-background: #3970C8;
    --navbar-hover-color: #6B5DDD;
    --reverse-gradient-color: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
    --hover-stack-dark: #DBE8F7;
    --my-chat-color: #EEF6FB;
    --chat-bubble-background: #EEF6FB;
    --popup-icon-background: #4278D6;
    --primary-color-darker: #574489;
    --primary-color-lighter: #715DA2;
    --link-color: #006BE0;
    --active-navbar: #5834D3;
}